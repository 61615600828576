
// Vue reactivity
import { ref, defineComponent, computed, onMounted, watch, reactive, } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, checkbox, trashOutline,
         thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline,
         chevronBack, chevronForward, repeat, search, pencil, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonProgressBar, IonAvatar,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption, IonCheckbox, IonSpinner,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonRippleEffect,
        IonNote, IonTextarea, IonFab, IonFabButton, IonBadge, IonInfiniteScroll, IonInfiniteScrollContent, IonModal,
        IonicSlides, modalController } from '@ionic/vue';
import ABProgramSelectModal from '@/components/achievejupas/ABProgramSelectModal.vue';
import ListProfessionModal from '@/components/secondary/ab3/ListProfessionModal.vue'; // for AB3

// Swiper
import 'swiper/swiper.min.css';
import 'swiper/modules/effect-cards/effect-cards.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import '@ionic/vue/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { EffectCards, Navigation, } from 'swiper';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { utilsGPT } from '@/composables/utilsGPT';
import { useStore } from '@/store';

// types
import { Card, Discipline, UserClaim, UserDiscipline } from '@/types';

export default defineComponent({
  name: 'ABDisciplineSelectModal',
  props: ["prefilledDisciplines", "targetProfessions", "oldUserDisciplines", "fromAB3Modal", "readOnly", "isListSelect"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonAvatar, IonProgressBar,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption, IonCheckbox, IonSpinner,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonRippleEffect,
                IonNote, IonTextarea, IonBadge, IonFab, IonFabButton, IonInfiniteScroll, IonInfiniteScrollContent, IonModal,
                Swiper, SwiperSlide, },
  setup(props, { emit }) {
    // methods or filters
    const { t } = useI18n();
    const store = useStore();
    const { closeModal, openModal, getProxyImgLink,
            processUserItems, isItemSelected, isItemDisliked, isMobileWeb, infiniteScrollLoadData, presentAlert,
            onThumbsUpItem, onThumbsDownItem, onClickMoreBtn, onClickPrevBtn, animateToFirstCard,
            recordCurrSlideReaction, resetActiveSlide, syncChosenItems, resetFilters, focusKeywordSearchbar, } = utils();

    const searchKeyword = ref("");
    const isSearching = ref(false);
    const delayLoading = ref(true);
    const selectedFilterGroup = ref('disciplineGroups');
    const selectedOption = ref<any>("all");
    
    const user = computed(() => store.state.user);

    // AB3
    const responseCards = computed<Card[]>(() => store.state.allCards);
    const disciplineGroups = computed(() => store.state.allDisciplineGroups);
    const allDisciplines = ref<Discipline[]>(store.getters.shuffledDisciplines);
    const chosenDisciplines = ref<Discipline[]>(props.prefilledDisciplines || []);
    const userDisciplines = ref<UserDiscipline[]>(props.oldUserDisciplines || []);
    const tmpNewUserDisciplines = ref<UserDiscipline[]>([]); // for storing slideChange user disciplines
    const numOfVisibleItems = ref(20);
    const loadData = (ev: any) => { infiniteScrollLoadData(ev, numOfVisibleItems, allDisciplines.value) }

    /**
     * GPT (strength-based recommendations)
     */
    const { tagObjOther, parseMsg, fetchGPTResponse, parseGPTResponse, whatsAppSendGPTResults, upsertUserItems, initGPTObj, } = utilsGPT();
    const step1Questions = computed(() => store.getters.getStep1Questions(false, true, ['Subject']));
    const gpt = reactive({
      userInputText: "", // user input prompt
      tagId: "",
      tagText: "",
      selectedFilterGroup: 'Subject', // AB3 focuses on Subject first
      currStep: 1,
      waitingGPTResp: false,
      isInCooldownTime: false, // prevent users sending requests consecutively
      cooldownSecLeft: 0,
    });
    const botSuggestedDisciplines = ref<Discipline[]>([]);
    const currViewingDiscipline = ref<Discipline>();
    const getRecommendedDisciplinesFromGPT = async () => {
      try {
        botSuggestedDisciplines.value = []; // reset
        currViewingDiscipline.value = undefined; // reset
        const MAX_DISCIPLINES = 10;
        const strengthDescriptions = `${gpt.tagText ? `${gpt.tagText} - ` : ``}${gpt.userInputText || ""}`;

        let prompt = `Key strength: ${strengthDescriptions}`;
        prompt += `\n\nAbove is the information about a Hong Kong F3 student. Please suggest at most ${MAX_DISCIPLINES} unique disciplines that are most relevant to the student based on his/her strength. `;
        prompt += `The discipline must ONLY be one of those in the data source "fdmt-disciplines-chunk-X" (consider all chunks).`;
        prompt += ` For each discipline, please explain in detail how the student's key strength is applied in most related study areas`;
        prompt += ` in TRADITIONAL CHINESE to convince the student that the discipline is suitable. Be as specific & concrete as possible.`;
        prompt += `\n\nYour response MUST be formatted in JSON with only an array of JavaScript objects (at most ${MAX_DISCIPLINES} objects), each object must contain exactly 2 fields: "id", "explanation"\n`;
        prompt += `Example response: [{"id":"499","explanation":"XXX"}]`;

        // Send request
        const data = await fetchGPTResponse(gpt, "https://ab-chatgpt-api.fdmt.hk/ab3", prompt, user.value.id, "", props.readOnly);
        if (!data) throw new Error("No data");

        // Parse data (bot responses)
        await parseGPTResponse(gpt, data, currViewingDiscipline, botSuggestedDisciplines, allDisciplines, '.discipline-slides', 'disciplineId');

        // Not update data / send WhatsApp for AB3 parents
        if (!props.readOnly) {
          // Insert to DB for records first
          upsertUserItems(user.value.id, botSuggestedDisciplines, userDisciplines, 'disciplineId');

          // Send out the results to student's WhatsApp group for records
          whatsAppSendGPTResults(user.value.phone, user.value.waGroupId, strengthDescriptions, botSuggestedDisciplines, 'discipline');
        }
      } catch (e) {
        console.error(e);
        presentAlert("ChatGPT did not response. Please try again");
        gpt.currStep = 1;
      } finally {
        gpt.waitingGPTResp = false;
      }
    }

    // Card Slide helper functions
    const getAppState = () => ({
      selectedFilterGroup: selectedFilterGroup.value,
      selectedOption: selectedOption.value,
      searchKeyword: searchKeyword.value,
    });
    const confirmSelect = async (noLoading = false) => {
      if (props.readOnly) return await closeModal({});
      if (props.isListSelect) return await closeModal({ "chosen": chosenDisciplines.value });
      return await closeModal({
        "chosen": chosenDisciplines.value,
        "userDisciplines": processUserItems(chosenDisciplines.value, userDisciplines.value, tmpNewUserDisciplines.value, 'disciplineId', store.state.user.id),
        noLoading,
      }); // return selected discipline & order here
    };

    // Helper functions for discipline reaction
    const recordActiveSlideReaction = () => {
      recordCurrSlideReaction('disciplineId', userDisciplines, tmpNewUserDisciplines, getAppState());
    }
    const isDisciplineSelected = (discipline: any) => (isItemSelected(discipline, chosenDisciplines));
    const isDisciplineDisliked = (discipline: any) => (isItemDisliked(discipline, 'disciplineId', userDisciplines));

    const startIdx = ref(0); // for card slides

    // INIT
    onMounted(() => {
      syncChosenItems('disciplineId', chosenDisciplines, userDisciplines, allDisciplines.value);

      setTimeout(() => {
        // Pre-select profession from last step
        const { targetProfessions } = props;
        if (targetProfessions && targetProfessions.length > 0) {
          const professionWithDisciplines = targetProfessions.find(p => p.relatedDisciplines.length > 0);
          if (professionWithDisciplines) {
            selectedFilterGroup.value = "professions";
            selectedOption.value = professionWithDisciplines;
          }
        }

        // Init card slides
        delayLoading.value = false;
        setTimeout(() => {
          const slides: any = document.querySelector('#card-swiper-slides');
          if (slides) {
            slides.swiper.on('slideChange', recordActiveSlideReaction);
            recordActiveSlideReaction(); // initial slide
          }
        }, 200);
      }, 200);

      // AI: Prefill previously input text & selected tag
      initGPTObj(gpt, user.value.claims);
    })

    watch(selectedOption, () => {
      resetActiveSlide(startIdx, delayLoading, 'disciplineId', userDisciplines, tmpNewUserDisciplines, getAppState());
    })
    watch(selectedFilterGroup, (currGroup, prevGroup) => {
      if (currGroup) {
        if (['like', 'dislike'].includes(currGroup)) {
          selectedOption.value = currGroup;
        }
        else if (currGroup == 'random') {
          resetFilters(selectedFilterGroup, selectedOption);
          allDisciplines.value = store.getters.shuffledDisciplines;
          animateToFirstCard();
        }
        else if (currGroup == 'search') {
          resetFilters(selectedFilterGroup, selectedOption);
          focusKeywordSearchbar(isSearching);
        }
        else {
          if (currGroup == 'professions') {
            const { targetProfessions: professions } = props;
            selectedOption.value = professions.find(p => p.relatedDisciplines.length > 0) || professions[0];
          } else if (currGroup == 'disciplineGroups') {
            selectedOption.value = disciplineGroups.value[0].id;
          }
        }
      }
    })
    
    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, checkbox, trashOutline,
      thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline, pencil,

      // variables
      selectedOption, delayLoading,
      disciplineGroups, allDisciplines,
      chosenDisciplines, userDisciplines, tmpNewUserDisciplines,
      searchKeyword, isSearching,

      // methods
      t, confirmSelect, closeModal, isMobileWeb,
      isDisciplineSelected, isDisciplineDisliked,
      onThumbsUp: (discipline: any, flipToNextSlide = true) => (onThumbsUpItem(discipline, 'disciplineId', chosenDisciplines, userDisciplines, tmpNewUserDisciplines, getAppState(), flipToNextSlide)),
      onThumbsDown: (discipline: any) => (onThumbsDownItem(discipline, 'disciplineId', chosenDisciplines, userDisciplines, tmpNewUserDisciplines, getAppState())),
      
      filteredDisciplines: () => {
        if (searchKeyword.value) {
          const filteredDisciplines = allDisciplines.value.filter(d => d.name.toLowerCase().includes(searchKeyword.value.toLowerCase().trim()));
          return filteredDisciplines;
        }
        if (['like', 'dislike'].includes(selectedOption.value)) {
          return userDisciplines.value.filter(ud => ud.reaction == selectedOption.value).map(ud => {
            return allDisciplines.value.find(d => d.id == ud.disciplineId); 
          });
        }
        const { relatedDisciplines, disciplineIds } = selectedOption.value;
        let res: any = allDisciplines.value;
        if (selectedOption.value != 'all') res.sort((a,b) => (a.seq-b.seq)); // sort if not random
        if (relatedDisciplines) res = relatedDisciplines;
        else if (disciplineIds) res = res.filter(d => disciplineIds.includes(d.id));
        else if (selectedOption.value != 'all') {
          res = allDisciplines.value.filter(d => d.disciplineGroupId.toString() == selectedOption.value);
        }
        return [
          ...res.filter(d => !isDisciplineDisliked(d)),
          ...res.filter(d => isDisciplineDisliked(d)),
        ];
      },
      openProgramModal: (specificDiscipline: any) => (openModal(ABProgramSelectModal, { specificDiscipline, readOnly: true })),

      // swiper
      modules: [EffectCards, IonicSlides, Navigation],
      startIdx,
      onClickMoreBtn: () => { onClickMoreBtn(startIdx) },
      onClickPrevBtn: () => { onClickPrevBtn(startIdx) },

      // Filter groups & filters
      chevronBack, chevronForward, repeat, search,
      selectedFilterGroup,

      // AB3
      step1Questions, responseCards, getProxyImgLink,
      openProfessionListModal: async (discipline) => {
        return openModal(ListProfessionModal, { discipline });
      },
      relatedCards: (): Card[] => {
        //if (selectedOption.value == 'all') return responseCards.value;
        return selectedOption.value.cardSpecs?.map(cs => responseCards.value.find(c => c.id == cs.cardId)) || [];
      },

      // List Disciplines
      loadData, numOfVisibleItems,
      onCheckDiscipline: (checked: any, d: Discipline) => {
        if (checked) {
          if (chosenDisciplines.value.find(x => x.id == d.id) == null) {
            chosenDisciplines.value.unshift(d);
          }
        }
        else {
          const idx = chosenDisciplines.value.findIndex(x => x.id == d.id);
          if (idx !== -1) chosenDisciplines.value.splice(idx, 1);
        }
      },

      // GPT (AI-assisted)
      gpt, botSuggestedDisciplines, currViewingDiscipline,
      getRecommendedDisciplinesFromGPT, parseMsg,
      tagObjOther, toggleSelectedTag: (tagId, tagText) => { gpt.tagId = tagId; gpt.tagText = tagText },
    }
  }
});
