import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4fddca7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"font-weight":"500"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    class: "class-bar",
    style: {"margin-bottom":"0"},
    disabled: $props.isDisabled
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_content, { style: {"padding":"0"} }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_item, {
            color: "primary",
            lines: "full"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { style: {"margin":"4px 0"} }, {
                default: _withCtx(() => [
                  _createElementVNode("h2", _hoisted_1, _toDisplayString($props.title), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["disabled"]))
}