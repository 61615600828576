
// icon
import { checkmarkCircle, alertCircle, pencil, add, } from 'ionicons/icons';

// components
import { IonCard, IonCardContent, IonItem, IonIcon, IonLabel, IonNote,
        IonList, IonButtons, IonButton, } from '@ionic/vue';

export default {
  props: [
    "headerTitle",
    "noPadding",
    "icon",
  ],
  emits: [
    "listHeaderClicked"
  ],
  components: {
    IonCard, IonCardContent, IonItem, IonIcon, IonLabel, IonNote,
    IonList, IonButtons, IonButton,
  },
  setup(props, { emit }) {
    return {
      // icons
      checkmarkCircle, alertCircle, pencil, add,

      // methods
      onClickListHeader: () => {
        emit('listHeaderClicked');
      },
    }
  }
}
