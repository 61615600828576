/**
 * UST special handling for 6th subject bonus
 * Ref: https://publication.join.ust.hk/view/13965330/18-19/#zoom=true
 */
const getUST6thSubjectBonus = (score) => {
  if (score >= 8.5) return 0.05; // 5%
  if (score >= 7) return 0.0412; // 4.12%
  if (score >= 5.5) return 0.0324; // 3.24%
  if (score >= 4) return 0.0235; // 2.35%
  if (score >= 3) return 0.0176; // 1.76%
  return 0;
}

/**
 * AchieveJUPAS
 */
const getDSEScore = (program: any, grade, targetRuleYear: any = null) => {
  const { scoreRules } = program;
  const ruleObj = scoreRules.find(sr => sr.intakeYear == targetRuleYear) || scoreRules[0];
  switch(grade) {
    case '5**': return ruleObj?.level5ss || 7;
    case '5*': return ruleObj?.level5s || 6;
    case '5': return ruleObj?.level5 || 5;
    case '4': return ruleObj?.level4 || 4;
    case '3': return ruleObj?.level3 || 3;
    case '2': return ruleObj?.level2 || 2;
    case '1': return ruleObj?.level1 || 1;
    default: return ruleObj?.levelU || 0;
  }
}
const getWeightedScore = (program, subjectId, grade, targetRuleYear: any = null) => {
  subjectId = Number(subjectId);
  const score = getDSEScore(program, grade, targetRuleYear);
  const { subjectRules } = program;
  const relatedSubjectRules = subjectRules?.filter(sr => sr.subjectId == subjectId);
  const { weighting } = relatedSubjectRules?.find(sr => sr.intakeYear == targetRuleYear) || relatedSubjectRules[0] || { weighting: 1 }; // default weighting: 1
  return { score, weightedScore: score * weighting, weighting };
}
const getUserSubjects = (allSubjects, userObj) => {
  const coreSubjects = ["Chinese Language", "English Language", "Mathematics"];
  const electives = Array.isArray(userObj.studyingElectives) ? userObj.studyingElectives
                        : userObj.studyingElectives.toString().split(" , ").filter(e => !!e);

  if (!userObj.isSecondaryStudent && electives.length == 0) {
    return [...coreSubjects, "Physics", "Chemistry", "Mathematics Extended Part Module 1 (M1)"]
            .map(e => allSubjects.find(se => se.name === e));
  }
  return [...coreSubjects, ...new Set(electives)]
          .map(e => allSubjects.find(se => se.name === e))
          .filter(e => !!e);
}
const getSubjectGrade = (userSubjectGrades, subjectId: any, source: any) => {
  const res = userSubjectGrades.find(usg => usg.subjectId == subjectId && usg.source == source);
  return res?.grade;
};

const calculateSumWeightedScores = (allSubjects, userSubjects, userSubjectGrades, program, source, targetRuleYear: any = null) => {
  // 1. Convert subject grade to grade points based on program_subject_grade & get weighted scores for each subject
  const subjectScores = userSubjects.map(s => {
    const grade = getSubjectGrade(userSubjectGrades, s?.id, source);
    return {
      subjectId: s?.id, grade,
      ...getWeightedScore(program, s?.id, grade, targetRuleYear),
    }
  });

  // 2. Sort weighted scores from highest to lowest
  const sortedScores = subjectScores.sort((a, b) => b.weightedScore - a.weightedScore);

  // 3. Loop from subject1 to subject7, filter out N/A ones & sum the weighted scores (or times 0.X for 6th/7th electives)
  const { scoreRules, institutionId, } = program; // Get rule of specific year (for correct comparison with past admission score)
  const ruleObj = scoreRules.find(sr => sr.intakeYear == (targetRuleYear || new Date().getFullYear())) || scoreRules[0];
  const calculationSteps: any = [], missingSubjects: any = [];

  let totalScores = 0;
  for (const k of Object.keys(ruleObj).filter(k => k.startsWith('subject')).sort()) {
    const rule = ruleObj[k];

    // Special handling for UST 6th elective bonus
    // Ref: https://publication.join.ust.hk/view/13965330/18-19/#zoom=true
    if (k == 'subject6' && institutionId == 6) {
      const nextScore = sortedScores.shift();
      if (nextScore) {
        // 6th subject grade -> bonus %
        const bonusPercent = getUST6thSubjectBonus(nextScore.score);

        // Consider all current subjects, assume all 5**
        const highestAttainableScores = userSubjects.map(s => {
          const grade = '5**'
          return {
            subjectId: s?.id, grade,
            ...getWeightedScore(program, s?.id, grade, targetRuleYear),
          }
        });

        // Sum the best 5 weighted scores
        const best5Scores = highestAttainableScores.sort((a, b) => b.weightedScore - a.weightedScore)
                                                    .slice(0, 5).reduce((sum, s) => sum + s.weightedScore, 0);

        // Bonus = best5Scores * bonus %
        totalScores += best5Scores * bonusPercent;
      }
    }
    else {
      if (!rule || rule == 'N/A') continue;
      if (rule == 'Any') { // can be any subjects
        const nextScore = sortedScores.shift();
        if (nextScore) {
          totalScores += nextScore.weightedScore;
          calculationSteps.push({ scoreObj: nextScore, weightedScore: nextScore.weightedScore });
        } else {
          missingSubjects.push(rule);
        }
        continue;
      }
      if (!isNaN(Number(rule))) { // mainly for HKU 7th elective bonus (x0.2)
        const nextScore = sortedScores.shift();
        if (nextScore) {
          totalScores += (nextScore.weightedScore || 0) * Number(rule);
          calculationSteps.push({ scoreObj: nextScore, multiplier: Number(rule) });
        }
        continue;
      }

      // Must be specific subjects
      const acceptedSubjects = rule.split(',').map(s => s.trim());
      let foundSubject = false;
      for (let i = 0; i < sortedScores.length; i++) {
        const score = sortedScores[i];
        const subjectName = allSubjects.find(s => s.id == score.subjectId)?.shortName;
        if (acceptedSubjects.includes(subjectName)) {
          totalScores += score.weightedScore;
          calculationSteps.push({ scoreObj: score, mustInclude: true, });
          foundSubject = true;
          sortedScores.splice(i, 1); // remove from scores to prevent duplicate calculation
          break;
        }
      }
      if (!foundSubject) missingSubjects.push(acceptedSubjects);
    }
  }

  // TODO: Check UST program & add 6th subject bonus
  //console.log(missingSubjects); // TODO: show if meet studying subject requirements

  return totalScores;
}
const getAvailableScoreType = (program) => {
  const { admissions, } = program;
  const { scoreLowerQuartile, scoreMedian, scoreMean } = admissions?.[0] || {};
  if (scoreLowerQuartile) return 'LQ';
  if (scoreMedian) return 'Median';
  if (scoreMean) return 'Mean';
  return 'N/A';
}

// Score difference between admission score & predicted score (weighted)
// calculate score using same rule (past score's intake year)
const getScoreDiff = (program, allSubjects, userSubjects, userSubjectGrades, source) => {
  const { admissions, } = program;
  const { scoreLowerQuartile, scoreMedian, scoreMean, intakeYear } = admissions?.[0] || {};
  const score = calculateSumWeightedScores(allSubjects, userSubjects, userSubjectGrades, program, source, intakeYear);
  const compareScore = scoreLowerQuartile || scoreMedian || scoreMean;
  return score - compareScore;
}
  
export default {
  getDSEScore,
  getWeightedScore,
  getUserSubjects,
  calculateSumWeightedScores,
  getAvailableScoreType,
  getScoreDiff,
}