
// Vue reactivity
import { computed, defineComponent, onMounted, reactive, ref, watchEffect } from 'vue';

// icons
import { } from 'ionicons/icons';

// Swiper
import 'swiper/swiper.min.css';
import 'swiper/modules/effect-cards/effect-cards.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import '@ionic/vue/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { EffectCards, Navigation, } from 'swiper';

// components
import { IonicSlides, IonCol, IonRow, } from '@ionic/vue';

export default {
  props: [],
  emits: [],
  components: { IonCol, IonRow, Swiper, SwiperSlide, },
  setup(props, { emit }) {
    return {
      // swiper
      modules: [EffectCards, IonicSlides, Navigation],
    }
  }
}
