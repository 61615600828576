
// Vue reactivity
import { reactive, defineComponent, computed, ref, onMounted } from 'vue';

// icons
import { mail, close, arrowBack, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonSpinner, IonItem, IonLabel, IonChip, IonIcon,
        IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea,
        IonGrid, IonCol, IonRow, IonSelect, IonSelectOption, IonCard, IonCardContent,
        modalController, loadingController, toastController } from '@ionic/vue';

// API services
import UserService from '@/services/UserService';

// Utils
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import config from '@/config';

import { FormQuestion } from '@/types';
import ABSService from '@/services/ABSService';
import EventService from '@/services/EventService';

export default defineComponent({
  name: 'FormQuestionModal',
  props: ["formTitle", "formQuestionIds", "sessionId", "taskId", "oldResponses"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
                IonSpinner, IonItem, IonLabel, IonChip, IonIcon,
                IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea,
                IonGrid, IonCol, IonRow, IonSelect, IonSelectOption, IonCard, IonCardContent, },
  setup(props) {
    // 1. declare state variables (ref to make them reactive)
    const store = useStore();
    const user = computed(() => store.state.user);
    const questions = ref<FormQuestion[]>();
    const OPTIONS = {
      linearScale: ["5", "4", "3", "2", "1"],
    }

    const formResponse = reactive({});

    // methods or filters
    const { t } = useI18n();
    const { formatDate, presentToast, presentAlert } = utils();
    const closeModal = async (updatedUser: any = null) => {
      await modalController.dismiss({ updatedUser });
    };

    onMounted(() => {
      const { formQuestionIds, oldResponses } = props;

      // Get form questions related to the task
      questions.value = formQuestionIds.map(qid => (store.state.formQuestions.find(q => q.id == qid))).filter(q => !!q);

      // Prefill previous responses
      for (const r of oldResponses) {
        formResponse[r.questionId] = r.answer;
      }
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      mail, close, arrowBack,

      // variables
      questions, formResponse,
      OPTIONS,

      // methods
      t, formatDate, closeModal,
      submitFormResponse: async () => {
        const loading = await loadingController.create({ });
        await loading.present();
        const { sessionId, taskId } = props;
        const responses = Object.keys(formResponse).map(questionId => ({
          questionId,
          questionTitle: questions.value?.find(q => q.id == questionId)?.title || "",
          answer: formResponse[questionId],
        }));
        EventService.upsertFormResponses(sessionId, taskId, responses); // pass session & event task IDs as well
        store.commit('upsertUserFormResponses', responses.map(resp => ({
          ...resp,
          sessionId,
          taskId,
        })));
        loading.dismiss();
        presentToast('Your responses have been saved.');
        closeModal();
      },

      someAnswersUpdated: () => {
        const { oldResponses } = props;
        if (oldResponses.length == 0) return Object.keys(formResponse).length > 0; // new submission
        for (const questionId in formResponse) {
          const latestAns = formResponse[questionId];
          const oldResponse = oldResponses.find(r => r.questionId == questionId) || {};
          if (oldResponse.answer != latestAns) {
            return true; // some answers updated
          }
        }
        return false;
      }
    }
  }
});
