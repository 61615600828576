import { RouteRecordRaw } from 'vue-router';
import Tabs from '@/pages/Tabs.vue'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/login/:userId?',
    alias: '/n/:userId?',
    component: () => import('@/pages/LoginPage.vue'),
    props: {
      isPage: true,
    }
  },
  {
    path: '/register/:schoolId?/:eventId?',
    alias: '/r/:schoolId?/:eventId?',
    component: () => import('@/pages/RegisterPage.vue')
  },
  {
    path: '/regevent/:eventId?', // generic (no roles prefilled)
    component: () => import('@/pages/RegisterPage.vue')
  },
  {
    path: '/regapply/:eventId?',
    component: () => import('@/pages/RegisterPage.vue')
  },
  {
    path: '/s/:schoolId?',
    component: () => import('@/pages/RegisterPage.vue')
  },
  {
    path: '/s/:schoolId?/:eventId?',
    component: () => import('@/pages/RegisterPage.vue')
  },
  {
    path: '/sr/:group/:ucirclerPhone?',
    component: () => import('@/pages/RegisterPage.vue')
  },
  {
    path: '/t/:schoolId?',
    component: () => import('@/pages/RegisterPage.vue')
  },
  {
    path: '/t/:schoolId?/:eventId?',
    component: () => import('@/pages/RegisterPage.vue')
  },
  {
    path: '/u/:programId?',
    component: () => import('@/pages/RegisterPage.vue')
  },
  {
    path: '/p/:serviceId?', // mainly for principal registration (promoting specific service)
    component: () => import('@/pages/RegisterPage.vue')
  },
  {
    path: '/forgot-password',
    component: () => import('@/pages/ForgotPasswordPage.vue')
  },
  {
    path: '/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/home'
      },
      {
        path: 'home',
        alias: ['f5'],
        component: () => import('@/pages/HomePage.vue')
      },
      {
        path: 'profile',
        component: () => import('@/pages/UserProfilePage.vue')
      },
      {
        path: 'terms-and-conditions',
        component: () => import('@/pages/TermsAndConditionsPage.vue')
      },
      {
        path: 'feedback',
        name: 'FeedbackPage',
        component: () => import('@/pages/FeedbackPage.vue')
      },

      // PSS
      {
        path: 'browsed',
        component: () => import('@/pages/UserBrowsedListPage.vue'),
      },
      {
        path: 'liked',
        component: () => import('@/pages/UserLikedListPage.vue'),
      },
      {
        path: 'professions/:name',
        name: 'ProfessionPage',
        component: () => import("@/pages/pss/ProfessionPage.vue"),
        props: true,
      },
      {
        path: 'sectors/:name',
        name: 'SectorPage',
        component: () => import("@/pages/pss/SectorPage.vue"),
        props: true,
      },
      {
        path: 'segments/:segmentName',
        name: 'SectorPage',
        component: () => import("@/pages/pss/SectorPage.vue"),
        props: true,
      },

      // Services / Events
      {
        path: 'events/:id/:userId?',
        alias: ['apply/:id/:userId?', 'confirm/:id/:userId?', 'withdraw/:id/:userId?', 'unsubscribe/:id/:userId?'],
        name: 'WorkEventPage',
        component: () => import("@/pages/WorkEventPage.vue"),
        props: true,
      },
      {
        path: 'services/sf5e13b2b/:userId?', // TMP: LMS scholarship link redirect to nomination page
        redirect: 'events/e4c145b9'
      },
      {
        path: 'services/:id/:userId?',
        alias: ['survey/:id/:userId?'],
        name: 'ServicePage',
        component: () => import("@/pages/ServicePage.vue"),
        props: true,
      },

      // Booking
      {
        path: 'booking/:itemId?/:staffName?',
        alias: ['appointment/:staffName?'],
        name: 'BookingPage',
        component: () => import("@/pages/BookingPage.vue"),
        props: true,
      },
    ]
  },

  // Modals
  {
    path: '/sessions/:sessionId?',
    name: 'UniEventsDetails',
    component: () => import('@/components/client/UniEventsDetails.vue'),
    props: {
      isPage: true
    }
  },
  {
    path: '/uni-profession-deck',
    name: 'UniProfessionDeckModal',
    component: () => import('@/components/university/UniProfessionDeckModal.vue'),
    props: {
      isYear1: true,
      isPage: true,
    }
  },
  {
    path: '/ab4-customization',
    name: 'AB4CustomizeContentModal',
    component: () => import('@/components/teacher/AB4CustomizeContentModal.vue'),
    props: {
      isPage: true,
    }
  },

  // AchieveJUPAS (Page)
  {
    path: '/mock-jupas',
    alias: ['/achievejupas'],
    component: () => import('@/components/achievejupas/AchieveJUPASResultPageModal.vue'),
    props: {
      isPage: true,
    }
  },

  {
    path: '/achievejupas-student-demo',
    component: () => import('@/components/achievejupas/AchieveJUPASResultPageModal.vue'),
    props: {
      isPage: true,
      isDemo: true,
    }
  },
  {
    path: '/achievejupas-teacher-demo',
    component: () => import('@/components/achievejupas/TeacherAchieveJUPASModal.vue'),
    props: {
      isPage: true,
      isDemo: true,
    }
  },
  {
    path: '/achievejupas-demo',
    component: () => import('@/components/achievejupas/AchieveJUPASDemoPage.vue'),
  },

  // AB3 (Page)
  {
    path: '/ab3',
    component: () => import('@/components/achievejupas/AchieveJUPASResultPageModal.vue'),
    props: {
      isPage: true,
      isAB3: true,
    }
  },

  // AB3 (Parent's view)
  {
    path: '/ab3-pv/:userId',
    component: () => import('@/components/achievejupas/AchieveJUPASResultPageModal.vue'),
    props: {
      isPage: true,
      isAB3: true,
      isAB3ParentView: true,
    }
  },

  // Update personal info / roles (mainly for teachers)
  {
    path: '/update-info/targetUserId?',
    name: 'UserProfileFormModal',
    component: () => import('@/components/modals/UserProfileFormModal.vue'),
    props: {
      isPage: true,
    }
  },
  {
    path: '/update-roles/:userId?',
    name: 'TeacherRolesModal',
    component: () => import('@/components/teacher/TeacherRolesModal.vue'),
    props: {
      isPage: true,
    }
  },
]