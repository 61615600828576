
// Vue reactivity
import { computed, defineComponent, onMounted, reactive, ref, watchEffect } from 'vue';

// components
import { IonToolbar, IonItem, IonLabel, IonList, IonListHeader, IonCheckbox, IonAccordion, IonAccordionGroup,
        IonRow, IonChip, IonSpinner, loadingController, } from '@ionic/vue';

// composables
import { useStore } from '@/store';
import { utils } from '@/composables/utils';

// types
import { Sector, Segment, } from '@/types';

// services
import PortalService from '@/services/PortalService';
import UserService from '@/services/UserService';

export default defineComponent({
  props: {
    sector: Object as () => Sector,
    segmentId: null
  },
  emits: ['segmentChanged'],
  components: { IonToolbar, IonItem, IonLabel, IonList, IonListHeader, IonCheckbox, IonAccordion, IonAccordionGroup,
                IonRow, IonChip, IonSpinner, },
  setup(props, { emit }) {
    const store = useStore();
    const { sleep } = utils();

    const relatedSegments = ref<Segment[]>([]);
    const selectedSegment = ref<Segment>();

    const loadingLocations = ref(true);
    const loadingExtraInfo = ref(true);
    const extraInfosGlobal = ref<any>({});
    const extraInfosLocation = ref<any>({});
    const locations = ref([]);
    const selectedLocationId = ref(1);
    const employers = reactive({
      local: [],
      mnc: []
    });

    const sectorExtraInfo = ref<any>(null);

    const getSectorSlideHtml = () => {
      const code = sectorExtraInfo.value ? sectorExtraInfo.value['__CODE__'] : null;
      return code && code[0] ? code[0].content.replace('width="480"', 'width="100%"') : '';
    }

    const getGroupedInfos = (infosByType: any) => {
      const groupedInfos = {};
      for (const info of infosByType) {
        if(!groupedInfos[info.type]){
          groupedInfos[info.type] = [];
        }
        groupedInfos[info.type] = info.infos;
      }
      return groupedInfos;
    }

    const loadExtraInfosLocation = (segmentId: any) => {
      loadingExtraInfo.value = true;
      PortalService.getSegmentExtraInfos(segmentId, selectedLocationId.value).then(res => {
        loadingExtraInfo.value = false;
        extraInfosLocation.value = getGroupedInfos(res.extra_infos_by_type);
        console.log(res);
        employers.local = res.employers.local.always.concat(res.employers.local.more);
        employers.mnc = res.employers.mnc.always.concat(res.employers.mnc.more);
      });
    }

    onMounted(() => {
      const { sector, segmentId } = props;
      if (sector) {
        const { id, extraInfo, fetchedExtraInfo, extraInfoGroupId } = sector;
        if (!fetchedExtraInfo) {
          PortalService.getExtraInfosByGroupId(extraInfoGroupId, true).then(extraInfo => {
            store.commit('setSectorExtraInfo', { id, extraInfo });
            sectorExtraInfo.value = extraInfo;
          });
        } else {
          sectorExtraInfo.value = extraInfo;
        }
        PortalService.getSector(sector.id).then(res => {
          loadingLocations.value = false;
          locations.value = res.locations;
          selectedLocationId.value = res.locations[0] ? res.locations[0].id : null;
          if (segmentId) loadExtraInfosLocation(segmentId);
        });

        // Related Segment Chips
        relatedSegments.value = store.getters.getRelatedSegments(sector.id);

        // Pre-select Segment
        if (segmentId) {
          selectedSegment.value = relatedSegments.value.find(s => s.id == segmentId);
          emit('segmentChanged', selectedSegment.value);
        }
      }
      
      /**
       * Browse History (sector / segment)
       */
      setTimeout(() => {
        if (segmentId) { // Specfic segment selected
          UserService.addUserBrowsedSegment(segmentId); // add to browse history
          store.commit('addUserBrowsedSegment', segmentId);
        } else { // Only Sector
          UserService.addUserBrowsedSector(sector?.id); // add to browse history
          store.commit('addUserBrowsedSector', sector?.id);
        }
      }, 500);
    });

    return {
      // variables
      relatedSegments, selectedSegment,
      locations, selectedLocationId, employers,
      loadingExtraInfo, extraInfosGlobal, extraInfosLocation,

      sectorExtraInfo,

      // methods
      getSectorSlideHtml, loadExtraInfosLocation,

      onSelectSegmentChip: (segment: Segment) => {
        selectedSegment.value = segment;
        emit('segmentChanged', segment);

        // extra infos of location
        loadExtraInfosLocation(segment.id);

        // extra infos of global
        PortalService.getSegmentExtraInfos(segment.id).then(res => {
          loadingExtraInfo.value = false;
          extraInfosGlobal.value = getGroupedInfos(res.extra_infos_by_type);
          UserService.addUserBrowsedSegment(segment.id); // add to browse history
          //store.commit('addUserBrowsedSegment', segment.id);
        });
      },

      onSelectSectorChip: (sector) => {
        selectedSegment.value = undefined;
        emit('segmentChanged', undefined);
        UserService.addUserBrowsedSector(sector.id); // add to browse history
        store.commit('addUserBrowsedSector', sector.id);
      },
    }
  }
})
