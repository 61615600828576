
// Vue reactivity
import { ref, defineComponent, computed, watch, reactive, onMounted, onBeforeMount, onBeforeUnmount } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, openOutline, trashOutline, send, refresh, thumbsUp, thumbsUpOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid,
        IonSpinner, IonCheckbox, IonTextarea, IonInput, IonFab, IonFabButton, IonAvatar,
        IonicSlides, loadingController, alertController, } from '@ionic/vue';
import ProfessionModal from '@/components/pss/ProfessionModal.vue';

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { utilsGPT } from '@/composables/utilsGPT';
import { useStore } from '@/store';

// Swiper
import 'swiper/swiper.min.css';
import 'swiper/modules/effect-cards/effect-cards.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import '@ionic/vue/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { EffectCards, Navigation, } from 'swiper';

// types
import { DisciplineClaim, Profession, Service, Step1Option, UserClaim, UserProfession, Program, } from '@/types';

// services
import SLPService from "@/services/SLPService";
import ABSService from '@/services/ABSService';

// lib
import { jsonrepair } from 'jsonrepair'; // for repairing JSON from GPT responses
import config from '@/config';

export default defineComponent({
  name: 'ABSLPModal',
  props: ["ev", "serviceId", "relatedProgramId", "relatedClientId", "isPreview"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid,
                IonSpinner, IonCheckbox, IonTextarea, IonInput, IonFab, IonFabButton, IonAvatar,
                Swiper, SwiperSlide, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { openModal, closeModal, presentAlert, presentPrompt, sleep, presentToast,
            setUserItemReaction, isItemSelected, isItemDisliked, isMobileWeb,
            onThumbsUpItem, onThumbsDownItem, animateToFirstCard, htmlToPlainText, uniqueId, } = utils();
    const { t } = useI18n();

    // state variables
    const loading = computed(() => store.state.loadingData || store.state.loadingPortalData);
    const loadingProgram = ref(false);
    const user = computed(() => store.state.user); // user claims
    const disciplineClaims = computed(() => store.state.disciplineClaims); // all claims
    const customClaims = ref<any>([]);
    const client = computed(() => store.getters.getClientById(props.ev?.clientId || props.relatedClientId));
    const allProfessions = computed(() => store.state.allProfessions);

    // For admin switching clients
    const allClients = computed(() => store.state.allClients);
    const selectedClientId = ref(props.ev?.clientId || props.relatedClientId);

    // GPT (interests & strengths)
    const BOT_URL = "https://ab-chatgpt-api.fdmt.hk/slp";
    const { tagObjOther, parseMsg, fetchGPTResponse, parseGPTResponse, whatsAppSendGPTResults, upsertUserItems, getStrengthDescription, initGPTObj, } = utilsGPT();
    const selectedFilterGroup = ref("Passion");
    const passionTags = computed<Step1Option[]>(() => store.getters.getStep1OptionsByQuestionId("q-192282d2"));
    const subjectTags = computed<Step1Option[]>(() => store.getters.getStep1OptionsByQuestionId("q-2c213906"));
    const userClaims = ref<UserClaim[]>(user.value.claims || []); // use user claims to store selected tags
    const getStep1OptionIds: any = () => ([...passionTags.value, ...subjectTags.value].map(tag => tag.id));

    // Recommended professions (shown in card view)
    const currViewingProfession = ref<Profession>();
    const recommendedProfessions = ref<Profession[]>([]);
    const selectedProfessions = ref<Profession[]>([]);
    const userProfessions = ref<UserProfession[]>(user.value.userProfessions || []);
    const isProfessionSelected = (profession: any) => (isItemSelected(profession, selectedProfessions));
    const isProfessionDisliked = (profession: any) => (isItemDisliked(profession, 'professionId', userProfessions));
    const tmpNewUserProfessions = ref<UserProfession[]>([]); // for storing slideChange user professions
    const setUserProfessionReaction = (professionId: any, reaction: any, skipIfExists = false) => {
      setUserItemReaction(
        professionId, reaction, 'professionId', userProfessions, tmpNewUserProfessions, {}, skipIfExists
      );
    }

    // Current step
    const currStep = ref(1);
    const isReadonly = () => (!user.value.isAdmin && user.value.slp?.accessType != 'full');

    // SLP
    const relatedService = computed<Service>(() => store.getters.getServiceById(props.serviceId));
    const chosenClaims = ref<DisciplineClaim[]>([]); // selected claims
    const selectedSLPType = ref('original');
    const userSLP = reactive({
      userInputText: "", // user input prompt
      tagId: "",
      tagText: "",
      original: "",
      gpt: "",
      waitingGPTResp: false,
      isInCooldownTime: false, // prevent users sending requests consecutively
      cooldownSecLeft: 0,
    });
    const combineUserClaims = () => {
      if (!isReadonly()) {
        userSLP.original = chosenClaims.value.map(c => (`${c.userText}. ${c.userElaboration || ""}`)).join("\n\n");
      }
    }
    const upsertSLPClaims = (commitToStore = true) => {
      if (!isReadonly() && chosenClaims.value.length > 0) {
        // Make all existing claims not selected first
        const latestUserClaims: any = (user.value.claims || []).filter(c => !c.type).map(c => {
          c.selected = false;
          return c;
        });

        // Only make chosenClaims selected
        for (const cc of chosenClaims.value) {
          const existingClaim = latestUserClaims.find(c => c.claimId == cc.id);
          const { id, userText, userElaboration, order } = cc;
          if (existingClaim) {
            existingClaim.text = userText || "";
            existingClaim.elaboration = userElaboration || "";
            existingClaim.order = order;
            existingClaim.selected = true;
          } else {
            latestUserClaims.push({
              claimId: id,
              text: userText || "",
              elaboration: userElaboration || "",
              selected: true,
              order,
            });
          }
        }

        // Update store & DB
        if (commitToStore) store.commit('upsertUserClaims', latestUserClaims);
        SLPService.upsertUserClaims(latestUserClaims);
      }
    }
    const wordCounter = (text) => {
      const chineseWords = text.match(/[\u4e00-\u9fa50-9]/g) || [];
      const englishWords = text.match(/[a-zA-Z0-9]+/g) || [];
      return chineseWords.length + englishWords.length;
    }
    const checkGPTAction = async (forceGenerate = false) => {
      if (!isReadonly() && !userSLP.waitingGPTResp && selectedSLPType.value == "gpt" && userSLP.original && (forceGenerate || !userSLP.gpt.trim())) {
        userSLP.waitingGPTResp = true;
        try {
          let content = `Key strength: ${getStrengthDescription(userSLP)}\n`;
          if (recommendedProfessions.value.length > 1 && selectedProfessions.value.length > 0) {
            content += `Interested professions: ${selectedProfessions.value.map(p => p.name).join(" , ")}\n`;
          }
          //content += `\nStudying elective subjects: ${studyingElectives}`;
          content += `\nAbove is the information about a Hong Kong F5/6 student, who is preparing the personal statement used in JUPAS SLP (Student Learning Profile),` +
                      ` and s/he wrote below draft claims. Please proofread & polish the claims in both English and 繁體中文. Please reply directly with the revised paragraphs ONLY.` + 
                      ` No need to translate the company 'FDMT Consulting' and the event name '${relatedService.value.name}' into Chinese. \n\n\n${userSLP.original}`;
          
          const data = await fetchGPTResponse(userSLP, BOT_URL, content, user.value.id);
          if (!data) throw new Error("No data");

          userSLP.waitingGPTResp = false;
          userSLP.gpt = "";

          const reader = data.getReader(), decoder = new TextDecoder();
          let done = false;
          while (!done) {
            const { value, done: doneReading } = await reader.read();
            done = doneReading;
            const chunkValue = decoder.decode(value);
            for (const message of chunkValue.split("\n")) {
              const matches = message.match(/\{([^}]+)\}/);
              if (matches) {
                const parsed = JSON.parse(matches[0]);
                if (parsed.text) userSLP.gpt += parsed.text;
                if (userSLP.gpt.startsWith('\n')) userSLP.gpt = userSLP.gpt.trimStart();
              }
            }
          }

          // Insert latest response to DB
          SLPService.upsertUserSLP(userSLP.original, userSLP.gpt);
          store.commit('upsertUserSLP', userSLP);
        } catch (e) {
          presentAlert("ChatGPT did not response. Please try again");
        } finally {
          userSLP.waitingGPTResp = false;
        }
      }
    }

    const getRecommendedProfessionsFromGPT = async () => {
      currViewingProfession.value = undefined;
      try {
        let clientId = props.ev?.clientId || props.relatedClientId;
        if (user.value.isAdmin && selectedClientId.value) clientId = selectedClientId.value; // for admin
        const overrideBotName = config.separatePoeBotClientIds.includes(clientId) ? clientId : "";
        const MAX_PROFESSIONS = 8;

        let content = `Key strength: ${getStrengthDescription(userSLP)}`;
        content += `\n\nAbove is the information about a Hong Kong F5/6 student, who is joining the event of the client "${clientId}". Please suggest at most ${MAX_PROFESSIONS} unique professions that are most suitable for the student based on his/her strengths. The professions must ONLY be those related to the client "${clientId}".`;
        //content += ` For each profession, please write 3 most related points in TRADITIONAL CHINESE to convince the student why the profession is suitable and how it is related to student's interests (e.g. part of work is associated to student's interest). Be specific & natural`;
        content += ` For each profession, please explain in detail how the student's key strength is applied in most related daily tasks in TRADITIONAL CHINESE to convince the student that the profession is suitable. Be as specific & concrete as possible.`;

        // Customized for SurveyorWork
        if (clientId == "PolyU_BRE") {
          content += `For Surveyor profession (client: PolyU_BRE), please give your explanation for each division (建築測量組, 產業測量組, 規劃及發展組, 物業設施管理組, 工料測量組) paragraph by paragraph, with the first paragraph being the most suitable division. Paragraphs are separated with blank newlines, shown like numbered list, with the divisions highlighted in bold.`;
        }
        // Customized for NursingWork
        if (clientId == "c45a8af14") {
          //content += 'For Nurse profession, please give your detailed explanation for each stream (General Nursing, Mental Health Nursing).' // 20240929: already split into 2 professions
          //content += 'For Nurse profession, please give your explanation for each ENTRY roles/divisions paragraph by paragraph, with the first paragraph being the most suitable role/division. Paragraphs are separated with blank newlines, shown like numbered list, with the role/division highlighted in bold.'
          //content += 'For Nurse profession, please give your explanation for each common employer group (e.g. 公立醫院, 私家醫院, 安老院, ...) or each common entry role (e.g. Registered nurse, Admin Nurse, Research Nurse, ...) paragraph by paragraph, with the first paragraph being the most suitable division. Paragraphs are separated with blank newlines, shown like numbered list, with the divisions highlighted in bold.'
        }
        content += `\n\nYour response MUST be formatted in JSON with only an array of JavaScript objects (at most ${MAX_PROFESSIONS} objects), each object must contain exactly 2 fields: "id", "explanation"\n`;
        content += `Example response: [{"id":"499","explanation":"XXX"}]`;

        // Send request
        const data = await fetchGPTResponse(userSLP, BOT_URL, content, user.value.id, overrideBotName);
        if (!data) throw new Error("No data");

        // Parse data (bot responses)
        await parseGPTResponse(userSLP, data, currViewingProfession, recommendedProfessions, allProfessions, '#card-swiper-slides', 'professionId');

        // Insert to DB for records first
        upsertUserItems(user.value.id, recommendedProfessions, userProfessions, 'professionId');

        // Send out the results to student's WhatsApp group for records
        whatsAppSendGPTResults(user.value.phone, user.value.waGroupId, getStrengthDescription(userSLP), recommendedProfessions, 'profession');
        //const program: Program = store.getters.getProgramsById(props.relatedProgramId || client.value.programId);
        //if (program) msg += ` and the program *${program.displayName}* `; // 20240929: hide this for NursingWork SLP
      } catch (e) {
        console.error(e);
        presentAlert("ChatGPT did not response. Please try again");
        currStep.value = 1;
      } finally {
        userSLP.waitingGPTResp = false;
      }
    }

    onMounted(() => {
      const { slp, } = user.value;

      // Restore previous saved SLP text
      if (slp) {
        userSLP.original = slp.original; // will be replaced from step 2 -> 3
        userSLP.gpt = slp.gpt;
      }

      // AI: Prefill previously input text & selected tag
      initGPTObj(userSLP, user.value.claims);
    });

    onBeforeUnmount(() => {
      //upsertUserClaims(true); // save before leave
    })

    watch(currStep, (curr, prev) => {
      if (prev == 3 && curr == 4) { // SLP claims combine
        combineUserClaims();
        upsertSLPClaims();
      }
      else if (prev == 4 && curr == 5) { // Finalize
        upsertSLPClaims();
      }
    });

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, openOutline, trashOutline, send, refresh,
      thumbsUp, thumbsUpOutline,

      // variables
      loading, loadingProgram,
      user, currStep,

      // methods
      t, closeModal,
      openProfessionModal: async (professionId: any) => (await openModal(ProfessionModal, { professionId, useBackButton: true })),

      // SLP
      selectedSLPType,
      isReadonly,
      getCounterFormatterTextOriginal: () => (`${wordCounter(userSLP.original)} words`),
      getCounterFormatterTextGPT: () => (`${wordCounter(userSLP.gpt)} words`),

      sendSLPWhatsAppRecord: async () => {
        presentPrompt("Send the above SLP draft to your WhatsApp group for records?", async () => {
          const loading = await loadingController.create({});
          await loading.present();
          SLPService.sendSLPWhatsAppRecord(selectedSLPType.value, userSLP.original, userSLP.gpt, user.value.phone, user.value.waGroupId);
          await sleep(2);
          loading.dismiss();
          presentToast("Your SLP draft will be sent to your WhatsApp group in minutes.");
        });
      },

      // Swiper
      setUserItemReaction, isItemSelected, isItemDisliked, isMobileWeb, onThumbsUpItem, onThumbsDownItem, animateToFirstCard,
      modules: [EffectCards, IonicSlides, Navigation],
      onThumbsUp: (profession: any) => {
        onThumbsUpItem(profession, 'professionId', selectedProfessions, userProfessions, tmpNewUserProfessions, {}, false);

        // Update DB (save user reaction)
        const newReaction = selectedProfessions.value.find(d => d.id == profession.id) ? 'like' : '';
        const existingUP = (user.value.userProfessions || []).find(up => up.professionId == profession.id);
        if (existingUP) {
          existingUP.reaction = newReaction;
          ABSService.upsertUserProfessions([existingUP]);
        }
      },
      onThumbsDown: (profession: any) => (onThumbsDownItem(profession, 'professionId', selectedProfessions, userProfessions, tmpNewUserProfessions, {})),
      setUserProfessionReaction,
      isProfessionSelected, isProfessionDisliked,

      // SLP claims
      userClaims,
      disciplineClaims,
      chosenClaims,
      doReorder: async (event: CustomEvent, claims: any) => {
        claims = event.detail.complete(claims).map((claim: any, idx: any) => {
          claim.order = idx+1;
          return claim;
        });
        upsertSLPClaims(false); // save once
      },
      filteredClaims: () => {
        return disciplineClaims.value.concat(customClaims.value).filter(c => {
          return c.clientId == (selectedClientId.value || client.value.id) && chosenClaims.value.find(cc => cc.id == c.id) == null;
        });
      },
      onCheckClaim: (checked: CustomEvent, claim: any) => {
        if (checked) {
          if (chosenClaims.value.find(c => c.id == claim.id) == null) {
            if (!claim.userText) claim.userText = claim.text;
            if (!claim.userElaboration) claim.userElaboration = claim.elaboration;
            chosenClaims.value.push(claim);

            if (chosenClaims.value.length > 5) {
              presentAlert("You can only select at most 5 claims!");
              setTimeout(() => {
                const idx = chosenClaims.value.findIndex(c => c.id == claim.id);
                if (idx !== -1) chosenClaims.value.splice(idx, 1);
              }, 100);
            }
          }
        }
        else {
          const idx = chosenClaims.value.findIndex(c => c.id == claim.id);
          if (idx !== -1) chosenClaims.value.splice(idx, 1);
        }
      },
      openNewClaimPrompt: async () => {
        const alert = await alertController.create({
          header: 'Claim',
          inputs: [
            {
              name: 'claim',
              type: 'text',
              placeholder: 'Enter your claim here',
            },
          ],
          buttons: [
            {
              text: t('cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            }, {
              text: t('confirm'),
              handler: (value) => {
                if (value.claim) {
                  customClaims.value.push({ id: `c${uniqueId()}`, text: value.claim, });
                  alert.dismiss();
                }
                return false;
              }
            }
          ]
        });
        await alert.present();
      },

      // GPT
      selectedFilterGroup,
      passionTags, subjectTags, tagObjOther,
      toggleSelectedTag: (tagId, tagText) => {
        if (userSLP.tagId == tagId) {
          userSLP.tagId = '';
          userSLP.tagText = '';
        } else {
          userSLP.tagId = tagId;
          userSLP.tagText = tagText;
        }
      },
      getRecommendedProfessionsFromGPT, parseMsg,
      userSLP, currViewingProfession, recommendedProfessions,
      
      checkGPTAction,
      regenerateGPTResponse: async () => {
        presentPrompt("Do you confirm?", async () => {
          checkGPTAction(true);
        });
      },

      // For switching clients
      allClients, selectedClientId,
    }
  }
});
