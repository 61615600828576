import firebase from "firebase/compat/app";
import 'firebase/compat/functions';

export default {
  // Program Users
  async getUsersByProgramId(programId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('jobEX-getUsersByProgramId')({ programId })).data;
  },

  // Employers & Jobs
  async getEmployers() {
    return (await firebase.app().functions('asia-east2').httpsCallable('jobEX-getEmployers')({})).data;
  },

  // User Employer Jobs (reactions, reason, order)
  async upsertUserEmployerJobs(userEmployerJobs: any, extraPayload: any = {}) {
    return (await firebase.app().functions('asia-east2').httpsCallable('jobEX-upsertUserEmployerJobs')({ userEmployerJobs, ...extraPayload })).data;
  },

  // CL
  async upsertUserClaims(userClaims: any, userExps: any) { // User Claims
    return (await firebase.app().functions('asia-east2').httpsCallable('jobEX-upsertUserClaims')({ userClaims, userExps })).data;
  },

  // Career Plan
  async saveUserCareerPlan(careerPlan: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('jobEX-saveUserCareerPlan')({ careerPlan })).data;
  },
  async getSavedCareerPlan() {
    return (await firebase.app().functions('asia-east2').httpsCallable('jobEX-getUserCareerPlan')({})).data;
  },

  // User Credentials
  async getUserCredentials() {
    return (await firebase.app().functions('asia-east2').httpsCallable('jobEX-getUserCredentials')({})).data;
  },
  async addUserCredentials(credentials: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('jobEX-addUserCredentials')({ credentials })).data;
  },
  async upsertUserCredential(credential: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('jobEX-upsertUserCredential')({ credential })).data[0];
  },
  async deleteUserCredential(credentialId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('jobEX-deleteUserCredential')({ credentialId })).data;
  },
  async updateUserCredentials(credentials: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('jobEX-updateUserCredentials')({ credentials })).data;
  },
  async syncCredentialsToProfileDoc() {
    return (await firebase.app().functions('asia-east2').httpsCallable('jobEX-syncCredentialsToProfileDoc')()).data;
  },

  // User Credential Sections
  async upsertUserSection(section: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('jobEX-upsertUserSection')({ section })).data;
  },
  async updateUserSections(sections: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('jobEX-updateUserSections')({ sections })).data;
  },
  async deleteUserSection(sectionId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('jobEX-deleteUserSection')({ sectionId })).data;
  }
}