
// Vue reactivity
import { ref, defineComponent, computed, onMounted, watch, inject, onBeforeUnmount, } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, checkbox, trashOutline,
         thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline,
         star, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder, IonProgressBar,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption, IonSpinner,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonRippleEffect,
        IonNote, IonTextarea, IonFab, IonFabButton, IonBadge, IonInfiniteScroll, IonInfiniteScrollContent, IonModal,
        isPlatform, getPlatforms, modalController, } from '@ionic/vue';
import UserDetailsModal from '@/components/modals/UserDetailsModal.vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';
import { School, Session, User, UserProgram } from '@/types';
import EventService from '@/services/EventService';

// Supabase
import { SupabaseClient } from '@supabase/supabase-js'

export default defineComponent({
  name: 'SessionStudentListModal',
  props: ["sessionId", "isStudentView", "isShowUniStudents"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder, IonProgressBar,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption, IonSpinner,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonRippleEffect,
                IonNote, IonTextarea, IonBadge, IonFab, IonFabButton, IonInfiniteScroll, IonInfiniteScrollContent, IonModal, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { closeModal, doReorder, openModal, getProxyImgLink, isMobileWeb,
            openImageModal, getAppSheetFileLink, formatStudentNumber, convertKeysToCamelCase, } = utils();
    const { t } = useI18n();

    const selectedSection = ref(props.isStudentView ? "groups" : "all");
    const selectedFilter = ref("all");
    const searchKeyword = ref("");
    const isSearching = ref(false);
    
    const loadingData = ref(true);
    const allParticipants = ref<User[]>([]);
    const allSchools = computed<School[]>(() => store.state.schools);
    const session = computed<Session>(() => store.getters.getSessionById(props.sessionId));
    const user = computed(() => store.state.user);
    const relatedUserUsers = (reaction: any = null) => (user.value.userUsers || []).filter(uu => uu.sessionId == props.sessionId && (!reaction || uu.reaction == reaction));

    // Get users who applied / attended the session
    const refreshSessionParticipants = async () => {
      const { sessionId, } = props;
      loadingData.value = true;
      const res = await EventService.getSessionParticipants(sessionId);
      allParticipants.value = res;
      loadingData.value = false;
    }

    // Supabase real-time subscriptions (for voting)
    const supabase = inject('supabase') as SupabaseClient;
    let channel;
    const handleChangedUserUsers = (payload) => {
      const obj = convertKeysToCamelCase(payload.new);

      // user who votes
      let userObj = allParticipants.value.find(p => p.id == obj.userId);
      if (userObj) {
        const relatedUserUserIdx = (userObj.userUsers || []).findIndex(uu => uu.targetUserId == obj.targetUserId);
        if (relatedUserUserIdx !== -1) userObj.userUsers?.splice(relatedUserUserIdx, 1, obj);
        else userObj.userUsers?.push(obj);
      }

      // user who gets voted
      userObj = allParticipants.value.find(p => p.id == obj.targetUserId);
      if (userObj) {
        const relatedUserUserIdx = (userObj.votedByUsers || []).findIndex(uu => uu.targetUserId == obj.targetUserId);
        if (relatedUserUserIdx !== -1) userObj.votedByUsers?.splice(relatedUserUserIdx, 1, obj);
        else userObj.votedByUsers?.push(obj);
      }
    }
    const subscribeSupabaseTables = () => {
      if (channel) return; // already subscribed
      channel = supabase.channel('fdmt-best-presenters');

      // Set up project work photo data change event handler
      channel.on('postgres_changes', { event: '*', schema: 'public', table: 'user_users', filter: `session_id=eq.${props.sessionId}` }, handleChangedUserUsers);

      // Subscribe to specified tables
      channel.subscribe(async (status) => {
        console.log(status);
        if (status !== 'SUBSCRIBED') { return }
      });
    }

    // INIT
    onMounted(() => {
      refreshSessionParticipants();
      subscribeSupabaseTables(); // Main: subscribe real-time best presenter votes
    });
    onBeforeUnmount(() => {
      if (channel) channel.unsubscribe(); // leave the channel
    });
    
    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, checkbox, trashOutline,
      thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline, star,

      // variables
      loadingData, allParticipants,
      selectedFilter, selectedSection,
      searchKeyword, isSearching,
      session,

      // methods
      t, isMobileWeb,
      getProxyImgLink, openImageModal, getAppSheetFileLink,
      closeModal, doReorder, formatStudentNumber,
      openUserDetailsModal: async (user: any) => {
        const { isStudentView, sessionId } = props;
        return await openModal(UserDetailsModal, { user, sessionId, isStudentView });
      },

      filteredStudents: () => {
        let filteredParticipants = allParticipants.value;

        if (selectedFilter.value != 'all') {
          filteredParticipants = filteredParticipants.filter(p => p.schoolId?.toLowerCase() == selectedFilter.value.toLowerCase());
        }
        if (searchKeyword.value) {
          filteredParticipants = filteredParticipants.filter(p => {
            const targetText = `${p.fullName?.toLowerCase()} ${p.preferredName?.toLowerCase()} ${(p.schoolId || '').toLowerCase()}`;
            return targetText.includes(searchKeyword.value.toLowerCase().trim());
          });
        }

        const groupedObj = {};
        for (const student of filteredParticipants) {
          let key = student.schoolId?.toUpperCase() || "Other";
          if (props.isShowUniStudents) key = "Participants"
          if (selectedSection.value == 'groups') { // By profession group
            const targetTaskId = "t64d798cb";
            const relatedResp = student.formResponses?.find(resp => resp.sessionId == props.sessionId && resp.taskId == targetTaskId &&
                                                                    resp.questionTitle == "What's your group?");
            key = relatedResp?.answer || "Unassigned";
          }
          groupedObj[key] = groupedObj[key] || [];
          groupedObj[key].push(student);
        }
        return groupedObj;
      },

      // Shortlisted / graded students
      getUserUserObj: (targetUserId, targetKey = "") => {
        const obj = relatedUserUsers().find(uu => uu.targetUserId == targetUserId);
        return targetKey ? (obj || {})[targetKey] : obj;
      },
      shortlistedStudents: () => {
        return relatedUserUsers('like').map(uu => (allParticipants.value.find(p => p.id == uu.targetUserId) || {}));
      },
      reorderShortlistedStudents: (event: CustomEvent, targetArr: any) => {
        doReorder(event, targetArr); // for completing the event only
        const updatedUserUsers: any = [];
        for (const uu of user.value.userUsers || []) {
          if (uu.sessionId != props.sessionId) continue;
          uu.order = targetArr.findIndex(u => u.id == uu.targetUserId)+1;
          updatedUserUsers.push(uu);
        }
        (user.value.userUsers || []).sort((a: any, b: any) => (a.order-b.order)); // not update store for performance consideration
        EventService.upsertUserUsers(updatedUserUsers); // update DB
      },
      sortedStudentsWithVotes: (targetRank: any = null) => {
        // user.votedByUsers: only count votes from session participants & reaction = 'like'
        const sortedParticipants = allParticipants.value.filter(p => {
          p.numOfVotes = (p.votedByUsers || []).filter(uu => uu.reaction == 'like' && allParticipants.value.find(p => p.id == uu.userId)).length;
          return p.numOfVotes > 0;
        }).sort((a,b) => b.numOfVotes - a.numOfVotes);

        // Assign ranks to participants
        let currentRank = 1;
        for (let i = 0; i < sortedParticipants.length; i++) {
          if (i > 0 && sortedParticipants[i].numOfVotes < sortedParticipants[i - 1].numOfVotes) {
            currentRank = i + 1;
          }
          sortedParticipants[i].rank = currentRank;
        }

        return targetRank == null ? sortedParticipants : sortedParticipants.filter(p => p.rank == targetRank);
      },
      getMyCurrentVotes: () => {
        const me = allParticipants.value.find(p => p.id == user.value.id);
        return me?.numOfVotes || 0;
      },

      // School accordion groups
      allRelatedSchoolIds: () => {
        return [...new Set(allParticipants.value.map(s => s.schoolId))].filter(c => !!c).map((c) => c?.toUpperCase()).sort();
      },
      getSchoolName: (schoolId) => {
        const schoolObj = allSchools.value.find(sch => sch.id == schoolId?.toLowerCase());
        if (!schoolId || !schoolObj) return null;
        return schoolObj.name;
      },
    }
  }
});
