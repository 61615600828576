import firebase from "firebase/compat/app";
import 'firebase/compat/storage';
import moment from 'moment';
import Compressor from 'compressorjs'; // for compressing image size before upload
import JSONH from 'jsonh';

export const uploadImage = async (imageFile: any, imageTitle: string) => {
  // compress the image
  imageFile = await new Promise((resolve, reject) => {
    new Compressor(imageFile, {
      maxWidth: 1500,
      quality: 0.6,
      success: resolve,
      error: reject,
    });
  });
  // upload the post image file to Firebase Storage
  const extension = imageFile.name.split('.').pop();
  const fileName = `${moment().format('YYYYMMDDHHmmss')}-${imageTitle.substring(0, 10)}.${extension}`;
  const snapshot = await firebase.app().storage().ref(`feedback-${fileName}`).put(imageFile);
  return await snapshot.ref.getDownloadURL();
}

const toCamel = (str) => {
  return str.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};
export const convertKeysToCamelCase = (o) => {
  let newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function(value) {
        if (typeof value === "object") {
          value = convertKeysToCamelCase(value)
        }
        return value
    })
  } else {
    newO = {};
    for (origKey in o) {
      if (Object.prototype.hasOwnProperty.call(o, origKey)) {
        if (origKey === "_RowNumber") { // skip AppSheet row number property
          newO[origKey] = o[origKey];
        } else {
          newKey = toCamel(origKey);
          value = o[origKey]
          if (value instanceof Array || (value && value.constructor === Object)) {
            value = convertKeysToCamelCase(value)
          }
          newO[newKey] = value
        }
      }
    }
  }
  return newO
};

export const unpackJSONHData = (packedData, needJSONHParse = true) => {
  const unpackedData = JSONH.unpack(packedData);
  const parseJsonhStr = (str) => (convertKeysToCamelCase(str ? JSONH.parse(str) : []));
  if (!needJSONHParse) return unpackedData; // for normal array of objects
  const res = unpackedData[0];
  for (const key in res) res[key] = parseJsonhStr(res[key]);
  return res;
}