import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, vModelRadio as _vModelRadio, vModelText as _vModelText, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ca89a9f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["onUpdate:modelValue", "name", "value", "required", "onClick"]
const _hoisted_5 = ["onUpdate:modelValue", "name", "value", "required", "onClick"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["value", "required"]
const _hoisted_8 = {
  key: 2,
  class: "bottom-margin"
}
const _hoisted_9 = {
  key: 3,
  class: "bottom-margin"
}
const _hoisted_10 = {
  key: 0,
  slot: "date-target"
}
const _hoisted_11 = {
  key: 1,
  slot: "time-target"
}
const _hoisted_12 = ["onUpdate:modelValue", "required"]
const _hoisted_13 = {
  key: 4,
  class: "bottom-margin"
}
const _hoisted_14 = {
  key: 5,
  class: "bottom-margin"
}
const _hoisted_15 = {
  key: 6,
  class: "bottom-margin"
}
const _hoisted_16 = {
  key: 7,
  class: "bottom-margin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_section_header = _resolveComponent("section-header")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_datetime_button = _resolveComponent("ion-datetime-button")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_popover = _resolveComponent("ion-popover")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => ($setup.upsertTeacherResponse()), ["prevent"]))
  }, [
    ($props.group =='event' && !$props.event && $props.serviceSessions && $props.serviceSessions.length > 0)
      ? (_openBlock(), _createBlock(_component_ion_list, {
          key: 0,
          style: {"padding-top":"0","padding-bottom":"0"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_section_header, { content: "Target session" }),
            _createVNode(_component_ion_item, {
              lines: "none",
              style: {"margin-bottom":"8px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_select, {
                  fill: "outline",
                  interface: "popover",
                  modelValue: $setup.selectedEventId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedEventId) = $event)),
                  onIonChange: _cache[1] || (_cache[1] = ($event: any) => ($setup.refreshTeacherResponse()))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.serviceSessions, (ev) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: ev.id,
                        value: ev.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(ev.formattedDateTime), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredQuestions(), (question, idx) => {
      return (_openBlock(), _createElementBlock("div", {
        key: question.id
      }, [
        _withDirectives(_createVNode(_component_ion_item, {
          style: {"margin-top":"10px","--background":"transparent"},
          lines: "none"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
              default: _withCtx(() => [
                (question.title)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(question.title), 1))
                  : _createCommentVNode("", true),
                (question.subtitle)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_2, [
                      _createElementVNode("i", null, _toDisplayString(question.subtitle), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1536), [
          [_vShow, !$props.hideFirstQuestionTitle || idx >= 1]
        ]),
        (question.type == 'multiple-choice')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredQuestionOptions(question).slice(0, 8), (opt) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  lines: "none",
                  class: "answer-option-item",
                  key: opt.id
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      slot: "start",
                      "onUpdate:modelValue": ($event: any) => (($setup.formResponse[question.id]) = $event),
                      name: question.id,
                      value: opt.title,
                      required: question.isRequired,
                      onClick: ($event: any) => ($setup.updateSelectedOption(question.id, opt))
                    }, null, 8, _hoisted_4), [
                      [_vModelRadio, $setup.formResponse[question.id]]
                    ]),
                    _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", null, _toDisplayString(opt.title), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              (!$setup.showMoreOptions && $setup.filteredQuestionOptions(question).length > 8)
                ? (_openBlock(), _createBlock(_component_ion_item, {
                    key: 0,
                    lines: "none",
                    class: "answer-option-item",
                    button: "",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.showMoreOptions = true))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, {
                        class: "ion-text-wrap",
                        style: {"color":"var(--ion-color-primary)","cursor":"pointer"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("More options...")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredQuestionOptions(question).slice(8), (opt) => {
                return _withDirectives((_openBlock(), _createBlock(_component_ion_item, {
                  lines: "none",
                  class: "answer-option-item",
                  key: opt.id
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      slot: "start",
                      "onUpdate:modelValue": ($event: any) => (($setup.formResponse[question.id]) = $event),
                      name: question.id,
                      value: opt.title,
                      required: question.isRequired,
                      onClick: ($event: any) => ($setup.updateSelectedOption(question.id, opt))
                    }, null, 8, _hoisted_5), [
                      [_vModelRadio, $setup.formResponse[question.id]]
                    ]),
                    _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", null, _toDisplayString(opt.title), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)), [
                  [_vShow, $setup.showMoreOptions]
                ])
              }), 128)),
              ($setup.showMoreOptions)
                ? (_openBlock(), _createBlock(_component_ion_item, {
                    key: 1,
                    lines: "none",
                    class: "answer-option-item",
                    button: "",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.showMoreOptions = false))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, {
                        class: "ion-text-wrap",
                        style: {"color":"var(--ion-color-primary)","cursor":"pointer"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Less options...")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]))
          : (question.type == 'multiple-answer')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_ion_select, {
                  placeholder: "Please feel free to choose more than one",
                  fill: "outline",
                  interface: "popover",
                  multiple: true,
                  modelValue: $setup.formResponse[question.id],
                  "onUpdate:modelValue": ($event: any) => (($setup.formResponse[question.id]) = $event),
                  onIonChange: ($event: any) => ($setup.updateMultipleAnswerSelectedOptions(question.id, question.options, $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.options, (opt) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: opt.id,
                        value: opt.title
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(opt.title), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["modelValue", "onUpdate:modelValue", "onIonChange"]),
                _createElementVNode("input", {
                  type: "text",
                  value: Array.isArray($setup.formResponse[question.id]) ? $setup.formResponse[question.id].join('') : $setup.formResponse[question.id],
                  required: question.isRequired,
                  style: {"opacity":"0","height":"0.1px"}
                }, null, 8, _hoisted_7)
              ]))
            : (question.type == 'number')
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_ion_input, {
                    fill: "outline",
                    type: "number",
                    inputmode: "numeric",
                    modelValue: $setup.formResponse[question.id],
                    "onUpdate:modelValue": ($event: any) => (($setup.formResponse[question.id]) = $event),
                    required: question.isRequired
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "required"])
                ]))
              : (question.type == 'datetime')
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_ion_datetime_button, {
                      mode: "md",
                      datetime: `${question.id}_datetime`,
                      style: {"font-size":"14px","justify-content":"start","padding-left":"16px"}
                    }, {
                      default: _withCtx(() => [
                        (!$setup.formResponse[question.id])
                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Select date"))
                          : _createCommentVNode("", true),
                        (!$setup.formResponse[question.id])
                          ? (_openBlock(), _createElementBlock("span", _hoisted_11, "Select time"))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1032, ["datetime"]),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": ($event: any) => (($setup.formResponse[question.id]) = $event),
                      style: {"opacity":"0","height":"0.01px"},
                      required: question.isRequired
                    }, null, 8, _hoisted_12), [
                      [_vModelText, $setup.formResponse[question.id]]
                    ]),
                    _createVNode(_component_ion_modal, {
                      class: "datetime-modal",
                      "keep-contents-mounted": true
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_datetime, {
                          mode: "md",
                          presentation: "date-time",
                          id: `${question.id}_datetime`,
                          modelValue: $setup.formResponse[question.id],
                          "onUpdate:modelValue": ($event: any) => (($setup.formResponse[question.id]) = $event),
                          showDefaultButtons: true,
                          preferWheel: false,
                          min: $setup.formatDate(new Date(new Date(new Date().getTime() + 1*24*3600*1000)), 'YYYY-MM-DD'),
                          hourValues: "[9,10,11,12,13,14,15,16,17]",
                          minuteValues: [0,15,30,45]
                        }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "min"])
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                : (question.type == 'date')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createVNode(_component_ion_input, {
                        id: `${question.id}_date`,
                        fill: "outline",
                        type: "text",
                        modelValue: $setup.formResponse[question.id],
                        "onUpdate:modelValue": ($event: any) => (($setup.formResponse[question.id]) = $event),
                        required: question.isRequired
                      }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "required"]),
                      _createVNode(_component_ion_popover, {
                        trigger: `${question.id}_date`,
                        size: "auto",
                        "keep-contents-mounted": true
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_datetime, {
                            mode: "ios",
                            presentation: "date",
                            modelValue: $setup.formResponse[question.id],
                            "onUpdate:modelValue": ($event: any) => (($setup.formResponse[question.id]) = $event),
                            showDefaultButtons: true,
                            min: $setup.formatDate(new Date(), 'YYYY-MM-DD'),
                            onIonChange: ($event: any) => ($setup.formatAnswer(question.id, 'date'))
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "min", "onIonChange"])
                        ]),
                        _: 2
                      }, 1032, ["trigger"])
                    ]))
                  : (question.type == 'time')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createVNode(_component_ion_input, {
                          fill: "outline",
                          type: "time",
                          modelValue: $setup.formResponse[question.id],
                          "onUpdate:modelValue": ($event: any) => (($setup.formResponse[question.id]) = $event),
                          required: question.isRequired
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "required"])
                      ]))
                    : (question.type == 'paragraph')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _createVNode(_component_ion_textarea, {
                            fill: "outline",
                            modelValue: $setup.formResponse[question.id],
                            "onUpdate:modelValue": ($event: any) => (($setup.formResponse[question.id]) = $event),
                            required: question.isRequired
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "required"])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                          _createVNode(_component_ion_input, {
                            fill: "outline",
                            type: "text",
                            modelValue: $setup.formResponse[question.id],
                            "onUpdate:modelValue": ($event: any) => (($setup.formResponse[question.id]) = $event),
                            required: question.isRequired
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "required"])
                        ]))
      ]))
    }), 128)),
    ($setup.isShowSubmitBtn())
      ? (_openBlock(), _createBlock(_component_ion_button, {
          key: 1,
          size: "default",
          style: {"margin":"0","margin-bottom":"5px"},
          color: "success",
          type: "submit",
          expand: "block"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Submit "),
            _createVNode(_component_ion_icon, {
              slot: "end",
              icon: $setup.send
            }, null, 8, ["icon"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 32))
}