
// Vue reactivity
import { ref, defineComponent, computed, onMounted, watch, reactive, } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, checkbox, trashOutline,
         thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline,
         pencil, } from 'ionicons/icons';

// components
import { IonPage, IonGrid, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonReorder, IonReorderGroup,
        IonList, IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonTextarea,
        IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonSelect, IonSelectOption,
        IonBadge, IonAccordionGroup, IonAccordion, IonSpinner, IonListHeader, IonSegment, IonSegmentButton,
        isPlatform, modalController, } from '@ionic/vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';

// amcharts
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5index from "@amcharts/amcharts5/index";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";

// types
import { Program, User, UserProgram } from '@/types';
import TeacherService from '@/services/TeacherService';

export default defineComponent({
  name: 'AchieveJUPASChartsModal',
  props: ["isPage", "userPrograms"],
  components: { IonPage, IonGrid, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonReorder, IonReorderGroup,
                IonList, IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonTextarea,
                IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonSelect, IonSelectOption,
                IonBadge, IonAccordionGroup, IonAccordion, IonSpinner, IonListHeader, IonSegment, IonSegmentButton, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { closeModal, doReorder, openModal, syncChosenItems, presentPrompt, getBandLabel, getBandClass,
            getDisciplineGroupColor, getF5YearDSE, getF6YearDSE, sleep, } = utils();
    const { t } = useI18n();
    const router = useRouter();

    const selectedSection = ref("myself"); // Myself / School
    const user = computed(() => store.state.user);
    const loading = computed(() => store.state.loadingPortalData);
    const allPrograms = computed<Program[]>(() => store.state.allPrograms);
    const selectedPrograms = ref<Program[]>([]);

    // School filters
    const schoolChartFilters = reactive({
      loadingData: true,
      yearDSE: getF5YearDSE(), // default F6 students
      bandOrders: ['1'], // default A1 only
      numOfStudents: null,
    });

    /**
     * AmCharts helpers
     */

    // Pie Chart
    const createPieChart = (rootElementId = "chartdiv1", dataObjs, valueField = "value", categoryField = "category") => {
      // Clear existing chart if any
      am5.array.each(am5.registry.rootElements, (root) => {
        if (root && root.dom.id == rootElementId) root.dispose();
      });

      // Create root element & set theme
      const root = am5.Root.new(rootElementId);
      const themes: am5.Theme[] = [am5themes_Animated.new(root), am5themes_Dark.new(root)];
      root.setThemes(themes);

      // Create chart
      const chart = root.container.children.push(am5percent.PieChart.new(root, { layout: root.verticalLayout, }));

      // Create series
      const series = chart.series.push(am5percent.PieSeries.new(root, { valueField, categoryField, }));
      series.labels.template.adapters.add("text", (text, target: any) => {
        if (target.dataItem) {
          if (target.dataItem.get("valuePercentTotal") < 5) {
            target.setAll({
              textType: "regular",
              centerX: am5.percent(50),
              radius: 20
            });
            target.dataItem.get("tick").set("forceHidden", false);
          }
          else {
            target.setAll({
              textType: "regular",
              centerX: am5.percent(50),
              radius: -60,
              text: `{${categoryField}}`,
            });
            target.dataItem.get("tick").set("forceHidden", true);
          }
        }
        return text;
      });

      // Slice colors (dynamic)
      series.slices.template.setAll({ templateField: "sliceSettings" });
      

      // Set data (array of objects)
      series.data.setAll(dataObjs);

      // Create legend
      /*const legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.percent(50), x: am5.percent(50),
        marginTop: 0, marginBottom: 0,
      }));
      legend.data.setAll(series.dataItems);
      legend.labels.template.adapters.add("fill", function(fill, target) {
        return am5.color(0xffffff);
      });*/
      
      // Play initial series animation
      series.appear(1000, 100);
    }

    // Bar Chart
    const createBarChart = async (rootElementId, data, name, valueField = "value", categoryField = "category") => {
      am5.array.each(am5.registry.rootElements, (root) => {
        if (root && root.dom.id == rootElementId) root.dispose();
      });

      const root = am5.Root.new(rootElementId);
      const themes: am5.Theme[] = [am5themes_Animated.new(root)];
      if (user.value.darkTheme == true) themes.push(am5themes_Dark.new(root));
      root.setThemes(themes);

      // Create chart
      const chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false, panY: false,
        wheelX: "none", wheelY: "none",
        paddingLeft: 0
      }));
      chart.zoomOutButton.set("forceHidden", true);

      // Create axes
      const yRenderer = am5xy.AxisRendererY.new(root, { minGridDistance: 10, minorGridEnabled: true });
      yRenderer.grid.template.set("location", 1);

      const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField,
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, { themeTags: ["axis"] })
      }));

      const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        numberFormatter: am5.NumberFormatter.new(root, { "numberFormat": "#,###a" }),
        extraMax: 0.1,
        renderer: am5xy.AxisRendererX.new(root, { strokeOpacity: 0.1, minGridDistance: 80, })
      }));

      // Add series (https://www.amcharts.com/docs/v5/charts/xy-chart/series)
      const series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: valueField,
        categoryYField: categoryField,
        tooltip: am5.Tooltip.new(root, { pointerOrientation: "left", labelText: "{valueX}" })
      }));

      // Rounded corners for columns
      series.columns.template.setAll({ cornerRadiusTR: 5, cornerRadiusBR: 5, strokeOpacity: 0 });
      
      // Add label bullet
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 1,
          sprite: am5.Label.new(root, {
            text: "{description}：{valueXWorking.formatNumber('#.#')}",
            fill: root.interfaceColors.get("alternativeText"),
            centerX: am5.p100,
            centerY: am5.p50,
            populateText: true,
            oversizedBehavior: "wrap",
          })
        });
      });

      // Make each column to be of a different color
      /*series.columns.template.adapters.add("fill", (fill, target) => {
        return chart.get("colors").getIndex(series.columns.indexOf(target));
      });
      series.columns.template.adapters.add("stroke", function (stroke, target) {
        return chart.get("colors").getIndex(series.columns.indexOf(target));
      });*/

      // Set data
      yAxis.data.setAll(data);
      chart.set("cursor", am5xy.XYCursor.new(root, { behavior: "none", xAxis: xAxis, yAxis: yAxis }));

      // Make stuff animate on load
      series.appear(1000);
      chart.appear(1000, 100);

      await sleep(1.5);
      series.data.setAll(data);
    }

    const getProgramStatCountObjs = (selectedPrograms) => {
      // Universities
      const universityCountObjs = [...new Set(selectedPrograms.map(p => p.institutionNameShort))].map(institutionNameShort => ({
        category: institutionNameShort,
        value: selectedPrograms.filter(p => p.institutionNameShort == institutionNameShort).length,
      }));

      // Disciplines
      const disciplineGroupCountObjs = [...new Set(selectedPrograms.map(p => p.mainDisciplineGroupName).filter(n => n))].map(disciplineGroupName => ({
        category: disciplineGroupName,
        value: selectedPrograms.filter(p => p.mainDisciplineGroupName == disciplineGroupName).length,
        sliceSettings: {
          fill: am5.color(getDisciplineGroupColor(disciplineGroupName)),
          stroke: am5.color(getDisciplineGroupColor(disciplineGroupName)),
        }
      }));

      // Program Count
      const programCountObj = {};
      for (const p of selectedPrograms) {
        const key = `${p.jupasCode} ${p.displayName}`;
        programCountObj[key] = programCountObj[key] || 0;
        programCountObj[key]++;
      }
      return { universityCountObjs, disciplineGroupCountObjs, programCountObj };
    }

    /**
     * Charts for whole school
     */
    const renderSchoolMockJUPASCharts = () => {
      const { schoolId } = user.value;
      if (schoolId) {
        const { yearDSE, bandOrders, } = schoolChartFilters;

        // Fetch school-based data
        TeacherService.getSchoolMockJUPASResults(schoolId, yearDSE, bandOrders).then(res => {
          console.log(res);
          schoolChartFilters.loadingData = false;
          schoolChartFilters.numOfStudents = res['1'].length; // Number of students

          const selectedPrograms: Program[] = [];
          for (const order in res) { // { '1': [<programId], '2': ... }
            for (const programId of res[order]) {
              const program: any = allPrograms.value.find(p => p.id == programId) || {};
              selectedPrograms.push(program);
            }
          }
          const { universityCountObjs, disciplineGroupCountObjs, programCountObj, } = getProgramStatCountObjs(selectedPrograms);

          // Pie chart: university distributions
          createPieChart("school-chartdiv1", universityCountObjs.slice());

          // Pie chart: discipline group distributions
          createPieChart("school-chartdiv2", disciplineGroupCountObjs.slice());

          // Bar chart: Most selected programs
          const data = Object.entries(programCountObj).sort((a: any, b: any) => b[1]-a[1]).slice(0, 10).map(([programDisplayName, count], idx) => ({
            value: count, category: `P${idx+1}`,
            description: `${programDisplayName}`,
          }));
          createBarChart("school-chartdiv3", data.slice().reverse(), "Most Selected Programs");
        });
      }
    }

    onMounted(() => {
      const { userPrograms } = props;

      const selectedPrograms = userPrograms.filter(up => up.reaction == 'like').sort((a, b) => (a.order-b.order))
                                            .map(up => allPrograms.value.find(p => p.id == up.programId) || {});
      const { universityCountObjs, disciplineGroupCountObjs, } = getProgramStatCountObjs(selectedPrograms);

      /*
       * Pie chart: university distributions
       */
      createPieChart("myself-chartdiv1", universityCountObjs);

      /*
       * Pie chart: discipline group distributions
       */
      createPieChart("myself-chartdiv2", disciplineGroupCountObjs);

      /*
       * Bar chart: best 5 median sorted
       */
      const data = selectedPrograms.map((p, idx) => ({
        value: p.best5Score,
        category: getBandLabel(idx),
        description: `${p.institutionNameShort} ${p.jupasCode}`,
      })).reverse();
      createBarChart("myself-chartdiv3", data, "Best 5 median");

      // Render Mock JUPAS charts for school students
      if (user.value.schoolId) renderSchoolMockJUPASCharts();
    })
    
    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, checkbox, trashOutline,
      thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline, pencil,

      // variables
      loading, selectedPrograms,
      user, selectedSection,
      schoolChartFilters,

      // methods
      t,
      closeModal,

      // Mock JUPAS
      getBandLabel, getBandClass,
      getProgramIdxInList: (p) => {
        return selectedPrograms.value.findIndex(program => program.id == p.id);
      },
      getDisciplineGroupColor,

      getF6YearDSE, renderSchoolMockJUPASCharts,
    }
  }
});
