
// Vue reactivity
import { computed, defineComponent, onMounted, reactive, ref, watchEffect } from 'vue';

// icons
import { } from 'ionicons/icons';

// Swiper
import 'swiper/swiper.min.css';
import 'swiper/modules/effect-fade/effect-fade.min.css';
import 'swiper/modules/effect-cards/effect-cards.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import '@ionic/vue/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { EffectCards, FreeMode, Navigation, Pagination, Scrollbar, Thumbs, Autoplay, EffectFade, } from 'swiper';

// components
import { IonicSlides, IonCol, IonRow, } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';

export default {
  props: [
    "videoIds", "imageLinks", "homeBanners",
    "useSlotAsSwiperSlides",
    "sliderId",
  ],
  emits: [],
  components: { IonCol, IonRow, Swiper, SwiperSlide, },
  setup(props, { emit }) {
    const { isMobileWeb } = utils();

    const delayLoading = ref(true);
    const thumbsSwiper = ref(null);

    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    }
    onMounted(() => {
      setTimeout(() => {
        // Init card slides
        delayLoading.value = false;
      }, 200);
    })

    return {
      thumbsSwiper, setThumbsSwiper,

      isMobileWeb,
      delayLoading,

      // swiper modules
      modules: [IonicSlides, EffectCards, Navigation, Pagination, FreeMode, Scrollbar, Thumbs, Autoplay, EffectFade],
    }
  }
}
