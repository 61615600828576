
// vue
import { computed } from 'vue';

// icon
import { checkmarkCircle, alertCircle, createOutline, pencil,
        handRightOutline, peopleOutline, personOutline, calendarClearOutline, calendarOutline, } from 'ionicons/icons';

// components
import { IonItem, IonIcon, IonLabel, IonNote, IonSpinner, } from '@ionic/vue';

export default {
  props: [
    "color",
    "title",
    "extraNote1",
    "extraNote2",
    "isDone",
    "hideIcon",
    "emptyIcon",
    "isLesson",
    "isDisabled",
    "noIndent",
    "isLoading",
    "textColor"
  ],
  components: {
    IonItem, IonIcon, IonLabel, IonNote, IonSpinner,
  },
  setup(props) {
    return {
      // icons
      checkmarkCircle, alertCircle, createOutline, pencil,
      handRightOutline, peopleOutline, personOutline, calendarClearOutline, calendarOutline,

      // methods
      getItemIcon: () => {
        const { emptyIcon, isDone, isLesson } = props;
        return null;
        /*
        if (emptyIcon) return null;
        //if (isDone) return checkmarkCircle;
        //if (isLesson) return calendarClearOutline;
        //if (isLesson) return calendarOutline;
        //if (isLesson) return createOutline;
        return createOutline;*/
      },
      getItemIconColor: () => {
        const { isDone, isLesson } = props;
        //if (isDone) return 'success';
        //return (isLesson ? undefined : 'danger')
        return undefined;
      }
    }
  }
}
