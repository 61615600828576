
// Vue reactivity
import { defineComponent, reactive, ref, watchEffect, computed, onMounted, onBeforeUnmount } from 'vue';

// icons
import { informationCircleOutline, close, arrowBack, heart, heartOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner, IonButtons, IonButton, IonIcon, IonLabel,
        loadingController, } from '@ionic/vue';
import SectorDetails from '@/components/pss/SectorDetails.vue';

// composables
import { useStore } from '@/store';
import { utils } from '@/composables/utils';

// types
import { Sector, Segment, } from '@/types';

// services
import UserService from '@/services/UserService';
import ABSService from '@/services/ABSService';

export default defineComponent({
  name: "SectorModal",
  props: {
    sectorId: null,
    segmentId: null,
    useBackButton: null,
  },
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner, IonButtons, IonButton, IonIcon, IonLabel,
                SectorDetails, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { closeModal, sleep, } = utils();

    const sector = ref<Sector>();
    const selectedSegment = ref<Segment>();

    let accessTime, duration = 0, counterInterval;
    onMounted(() => {
      const { sectorId, } = props;
      sector.value = store.getters.getSectorById(sectorId);

      // Record Access & Leave Time
      accessTime = new Date();
      counterInterval = setInterval(() => (duration++), 1000);
    });
    onBeforeUnmount(() => {
      if (accessTime && duration >= 5) {
        const { id, name } = sector.value || {};
        ABSService.insertPageAccessRecord(`SectorModal | ${name} | ${id}`, accessTime, new Date(accessTime.getTime() + duration*1000));
        accessTime = undefined;
        duration = 0; // reset;
        clearInterval(counterInterval);
      }
    });

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      informationCircleOutline, close, arrowBack, heart, heartOutline,

      // variables
      sector, selectedSegment,

      // methods
      closeModal,
      onSegmentChanged: (value) => {
        selectedSegment.value = value;
      },

      /**
       * Bookmark Sectors
       */
      updateUserLikedSector: async (sector: Sector, selectedSegment: Segment, action = 'add') => {
        const loading = await loadingController.create({});
        await loading.present();
        if (action == 'add') {
          if (selectedSegment) UserService.addUserLikedSegment(selectedSegment.id);
          else UserService.addUserLikedSector(sector.id);
        }
        else {
          if (selectedSegment) UserService.removeUserLikedSegment(selectedSegment.id);
          else UserService.removeUserLikedSector(sector.id);
        }
        await sleep(1.5); // 1.5 seconds
        loading.dismiss();

        if (selectedSegment) selectedSegment.likedByUser = (action == 'add');
        else sector.likedByUser = (action == 'add');
      },
    }
  }
});
