
// vue
import { defineComponent, ref, onMounted, watch, computed, } from 'vue';

// icons
import { arrowBack, checkmarkCircle, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonButton, IonButtons, IonLabel, IonIcon,
          modalController } from '@ionic/vue';

// services
import AchieveJUPASService from '@/services/AchieveJUPASService';

// lib / composables
import SignaturePad from 'signature_pad';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

// types
import { User } from '@/types';

export default defineComponent({
  name: 'ParentConsentSignatureModal',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButton,
    IonButtons,
    IonLabel,
    IonIcon,
  },
  props: {},
  setup(props, { emit }) {
    const { closeModal, presentToast, presentPrompt, uniqueId, formatDate, } = utils();

    // store
    const store = useStore();
    const user = computed<User>(() => store.state.user);

    // signature pad
    const signaturePadElement = ref<HTMLCanvasElement | null>(null);
    const signaturePad = ref<SignaturePad | null>(null);
    const hasSignature = ref(false);

    const initSignaturePad = () => {
      const canvas: any = document.querySelector("#signaturePad");

      signaturePad.value = new SignaturePad(canvas, {
        backgroundColor: 'rgb(255, 255, 255)',
        penColor: 'rgb(0, 0, 0)',
      });
      
      signaturePad.value.addEventListener('endStroke', () => {
        hasSignature.value = !signaturePad.value?.isEmpty();
      });
    };

    const resizeCanvas = () => {
      const canvas: any = document.querySelector("#signaturePad");
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext('2d')?.scale(ratio, ratio);
      if (signaturePad.value) signaturePad.value.clear();
    };

    onMounted(() => {
      initSignaturePad();
      setTimeout(() => {
        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();
      }, 300);
    });

    const clearSignature = () => {
      if (signaturePad.value) {
        signaturePad.value.clear();
        hasSignature.value = false;
      }
    };

    const saveSignature = async () => {
      if (signaturePad.value && !signaturePad.value.isEmpty()) {
        presentPrompt("Submit your consent with signature?", async () => {
          try {
            const signatureDataUrl = signaturePad.value?.toDataURL("image/jpeg", 0.5);
            const { schoolId, fullName, roles, phone, waGroupId, } = user.value;
            const payload = {
              id: `uc${uniqueId()}`,
              schoolId,
              fullName,
              target: 'achievejupas_parent_consent',
              roles: roles,
              response: 'I hereby consent to my child/ward using AchieveJUPAS',
              phone, waGroupId,
              signatureDataUrl,
              createdAt: new Date(),
            };
            AchieveJUPASService.insertUserConsent(payload);
            user.value.userConsentRecords?.push(payload);
            presentToast('Parent consent recorded successfully', 2000);
            closeModal();
          } catch (error) {
            console.error('Error saving parent consent:', error);
            presentToast('Failed to record parent consent. Please try again', 2000);
          }
        })
      }
    };

    return {
      // icons
      arrowBack,
      checkmarkCircle,

      // variables
      signaturePadElement,
      hasSignature,
      user,
      submittedConsent: computed(() => {
        return user.value.userConsentRecords?.find(r => r.target == 'achievejupas_parent_consent');
      }),

      // methods
      clearSignature,
      saveSignature,
      closeModal,
      formatDate,
    };
  },
});
