const setUserItemReaction = (itemId, reaction, keyField, userItems, tmpNewUserItems, appState = {}, skipIfExists = false) => {
  const idx = userItems.value.findIndex(ui => ui[keyField] == itemId);
  if (idx !== -1) {
    if (userItems.value[idx].reaction == '') userItems.value[idx].appState = appState; // set app state only if prev no reaction
    if (!skipIfExists) userItems.value[idx].reaction = reaction;
  }
  else {
    const newUserItem = {
      [keyField]: itemId,
      reaction,
      createdAt: new Date(),
      appState
    };
    if (reaction == '') tmpNewUserItems.value.push(newUserItem);
    else userItems.value.push(newUserItem);
  }
}
const isItemSelected = (item: any, selectedItems) => (selectedItems.value.find(x => x.id == item.id) != null);
const isItemDisliked = (item: any, keyField, userItems) => (userItems.value.find(ui => (ui[keyField] == item.id && ui.reaction == 'dislike')) != null);

const recordCurrSlideReaction = (keyField, userItems, tmpNewUserItems, appState) => {
  const slides: any = document.querySelector('#card-swiper-slides');
  if (slides) {
    const itemId = slides.swiper.slides[slides.swiper.activeIndex]?.dataset[keyField];
    if (itemId) setUserItemReaction(itemId, '', keyField, userItems, tmpNewUserItems, appState);
    return itemId;
  }
}

export default {
  setUserItemReaction,
  isItemSelected, isItemDisliked,
  recordCurrSlideReaction,

  resetActiveSlide: (startIdx, delayLoading, keyField, userItems, tmpNewUserItems, appState) => {
    startIdx.value = 0; // reset start index
    delayLoading.value = true;
    setTimeout(() => {
      delayLoading.value = false;
      const slides: any = document.querySelector('#card-swiper-slides');
      if (slides) slides.swiper.slideTo(0);
      recordCurrSlideReaction(keyField, userItems, tmpNewUserItems, appState); // impression on first slide
    }, 100);
  },

  onThumbsUpItem: (item, keyField, selectedItems, userItems, tmpNewUserItems, appState, flipToNextSlide = true, unshiftItem = false) => {
    const idx = selectedItems.value.findIndex(d => d.id == item.id);
    if (idx !== -1) {
      selectedItems.value.splice(idx, 1);
      setUserItemReaction(item.id, '', keyField, userItems, tmpNewUserItems, appState);
    }
    else {
      //if (unshiftItem == true) selectedItems.value.unshift(item); // TBC: which is better? adding to top / bottom
      //else selectedItems.value.push(item);
      selectedItems.value.push(item);
      setUserItemReaction(item.id, 'like', keyField, userItems, tmpNewUserItems, appState);
      
      if (flipToNextSlide) {
        const slides: any = document.querySelector('#card-swiper-slides');
        slides.swiper.slideNext();
      }
    }
  },
  onThumbsDownItem: (item, keyField, selectedItems, userItems, tmpNewUserItems, appState) => {
    // Remove from selected items (if any)
    const idx = selectedItems.value.findIndex(d => d.id == item.id);
    if (idx !== -1) selectedItems.value.splice(idx, 1);

    // Update user item reaction
    if (isItemDisliked(item, keyField, userItems)) {
      setUserItemReaction(item.id, '', keyField, userItems, tmpNewUserItems, appState);
    } else {
      setUserItemReaction(item.id, 'dislike', keyField, userItems, tmpNewUserItems, appState);
    }
  },

  onClickMoreBtn: (startIdx, increment = 10) => {
    startIdx.value += increment;
    setTimeout(() => {
      const slides: any = document.querySelector('#card-swiper-slides');
      if (slides) slides.swiper.slideTo(1);
    }, 100)
  },
  onClickPrevBtn: (startIdx, increment = 10) => {
    startIdx.value -= increment;
    setTimeout(() => {
      const slides: any = document.querySelector('#card-swiper-slides');
      if (slides) slides.swiper.slideTo(startIdx.value >= increment ? increment : increment-1);
    }, 100)
  },

  animateToFirstCard: () => {
    setTimeout(() => {
      const slides: any = document.querySelector('#card-swiper-slides');
      slides.swiper.slideTo(9, 200);
      setTimeout(() => slides.swiper.slideTo(0, 200), 300);
    }, 200)
  },

  //isMobileWeb: () => (isPlatform('mobileweb') || isPlatform('tablet')),
  isMobileWeb: () => ('ontouchstart' in document.documentElement),
  navigateMaterialCategories: (direction, selector = '.filter-group-segment') => {
    const selectedSegmentBtn = document.querySelector(`${selector} ion-segment-button.segment-button-checked`);
    const targetElement: any = direction == 'prev' ? selectedSegmentBtn?.previousElementSibling : selectedSegmentBtn?.nextElementSibling;
    if (targetElement) {
      targetElement.click();
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
    }
  },
  processUserItems: (chosenItems, userItems, tmpNewUserItems, keyField, userId) => {
    // Items browsed with no reaction
    for (const tui of tmpNewUserItems) {
      const idx = userItems.findIndex(ui => ui[keyField] == tui[keyField]);
      if (idx == -1) userItems.push(tui);
    }
    // User Items reactions
    for (const item of chosenItems) {
      const idx = userItems.findIndex(ui => ui[keyField] == item.id);
      if (idx !== -1) {
        userItems[idx].reason = item.reason || null;
        userItems[idx].action = item.action || null;
        userItems[idx].reaction = 'like';
      } else {
        // should rarely happen
        userItems.push({
          [keyField]: item.id,
          reason: item.reason,
          action: item.action,
          reaction: 'like',
          createdAt: new Date(),
          appState: {},
        })
      }
    }
    return userItems.map(ud => {
      const order = (chosenItems.findIndex(x => x.id == ud[keyField]) + 1);
      ud.order = order || undefined;
      ud.userId = userId; // context.auth.uid may not be correct (for one-time login users)
      return ud;
    }).sort((a,b) => (Number(a.order)-Number(b.order)));
  },

  syncChosenItems: (keyField, chosenItems, userItems, allItems) => {
    userItems.value.sort((a,b) => (Number(a.order)-Number(b.order))); // sort the items first
    
    // Prefill with previously written reasons
    for (const item of chosenItems.value) {
      const relatedUserItem = userItems.value.find(ui => ui[keyField] == item.id);
      if (relatedUserItem) {
        item.reason = relatedUserItem.reason || null;
        item.action = relatedUserItem.action || null;
        relatedUserItem.reaction = 'like'; // like = selected
      }
    }
    for (const ui of userItems.value) {
      const chosenItem = chosenItems.value.find(c => c.id == ui[keyField]);
      if (!chosenItem && ui.reaction == 'like') {
        const item = allItems.find(x => x.id == ui[keyField]);
        if (item) {
          item.reason = ui.reason || null;
          item.action = ui.action || null;
          chosenItems.value.push(item); // set selected
        }
        //ui.reaction = ''; // reset reaction (not selected)
      }
    }
  },

  // e.g. link selected electives to disciplines (nested)
  linkNestedChosenItems: (userItems, targetItems, targetItemKeyField, nestedItems, nestedItemKeyField, selectedItemsKeyField) => {
    userItems.value.sort((a,b) => (Number(a.order)-Number(b.order))); // sort the items first

    for (const us of userItems.value) {
      if (us.reaction == 'like') {
        const targetItem = targetItems.value.find(item => item.id == us[targetItemKeyField]);
        const nestedItem = nestedItems.value.find(item => item.id == us[nestedItemKeyField]);
        if (targetItem && nestedItem) {
          targetItem[selectedItemsKeyField] = targetItem[selectedItemsKeyField] || [];
          if (!targetItem[selectedItemsKeyField].find(s => s.id == nestedItem.id)) {
            targetItem[selectedItemsKeyField].push(nestedItem);
          }
        }
      }
    }
  },

  resetFilters: (selectedFilterGroup, selectedOption) => {
    selectedFilterGroup.value = '';
    selectedOption.value = 'all';
  },
  focusKeywordSearchbar: (isSearching) => {
    isSearching.value = true;
    setTimeout(() => {
      const el: any = document.querySelector('#keyword-searchbar');
      el.setFocus();
    }, 100)
  }
}