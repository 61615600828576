import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bbf17b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.isAccordionHeader)
      ? (_openBlock(), _createBlock(_component_ion_item, {
          key: 0,
          lines: "none",
          class: _normalizeClass(["section-header", { 'short-item': $props.size == 'small' }]),
          color: "primary",
          slot: "header"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                ($props.size == 'small')
                  ? (_openBlock(), _createElementBlock("h3", _hoisted_1, [
                      _createElementVNode("b", null, _toDisplayString($props.title), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("h2", {
                      key: 1,
                      innerHTML: $props.title
                    }, null, 8, _hoisted_2)),
                ($props.content)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 2,
                      innerHTML: $props.content
                    }, null, 8, _hoisted_3))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["class"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_list_header, {
      class: _normalizeClass(["section-header", { 'short-item': $props.size == 'small' }]),
      color: "primary"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_label, null, {
          default: _withCtx(() => [
            ($props.size == 'small')
              ? (_openBlock(), _createElementBlock("h3", _hoisted_4, [
                  _createElementVNode("b", null, _toDisplayString($props.title), 1)
                ]))
              : (_openBlock(), _createElementBlock("h2", {
                  key: 1,
                  innerHTML: $props.title
                }, null, 8, _hoisted_5)),
            ($props.content)
              ? (_openBlock(), _createElementBlock("p", {
                  key: 2,
                  innerHTML: $props.content
                }, null, 8, _hoisted_6))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["class"])
  ], 64))
}