import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo_img = _resolveComponent("logo-img")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_swiper, {
      class: "card-slides",
      effect: 'cards',
      modules: $setup.modules
    }, {
      default: _withCtx(() => [
        _createVNode(_component_swiper_slide, {
          class: "card-slide",
          style: {"background-color":"var(--ion-color-primary)"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, { size: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_logo_img, {
                      useWhiteLogo: true,
                      withText: true,
                      style: {"width":"250px"}
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modules"])
  ]))
}