
// Vue reactivity
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';

// icons
import { close, arrowBack, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon, IonLabel } from '@ionic/vue';

// composables
import { useStore } from '@/store';
import { utils } from '@/composables/utils';

// types
import { Service } from '@/types';

export default defineComponent({
  name: 'ServiceModal',
  props: ["serviceId", "event", "hideTeacherResponse", "prefilledOption", "isSurvey", "isSecondaryStudentView", "isClientView"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon, IonLabel, },
  setup(props) {
    const store = useStore();
    const user = computed(() => store.state.user);
    const selectedService = computed<Service>(() => store.getters.getServiceById(props.serviceId));

    const { closeModal } = utils();

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close, arrowBack,
      
      // variables
      user,
      selectedService,
      
      // methods
      closeModal,
    }
  }
});
