
// Vue reactivity
import { ref, defineComponent, computed, watch, onMounted, reactive, } from 'vue';

// icons
import { add, close, checkmark, arrowUp, arrowForward, arrowBack, trashOutline, caretDown, createOutline, openOutline, send, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonLabel, IonContent, IonFooter, IonGrid, IonCol, IonRow, IonInput,
        IonList, IonItem, IonButtons, IonButton, IonIcon, IonSpinner, IonCard, IonTextarea, IonAccordionGroup, IonAccordion,
        modalController, loadingController, } from '@ionic/vue';
import ProfessionModal from '@/components/pss/ProfessionModal.vue';

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

// services
import ChatService from '@/services/ChatService';

// lib
import markdownit from 'markdown-it'
import { Profession, Segment } from '@/types';
import SLPService from '@/services/SLPService';

export default defineComponent({
  name: 'ChatbotModal',
  props: [
    "isFromAB4", "professionName", "botExplanation", "professionId", "prefilledPrompt",
    "isImageBot", "imageBotTargetProfession",
  ],
  components: { IonHeader, IonToolbar, IonTitle, IonLabel, IonContent, IonFooter, IonGrid, IonCol, IonRow, IonInput,
                IonList, IonItem, IonButtons, IonButton, IonIcon, IonSpinner, IonCard, IonTextarea, IonAccordionGroup, IonAccordion, },
  setup(props) {
    // methods or filters
    const { openModal, openImageModal, closeModal, presentToast, presentAlert, getProxyImgLink, uniqueId, presentPrompt, sleep, } = utils();
    const { t } = useI18n();
    const md = markdownit({ breaks: true });

    // state variables
    const store = useStore();
    const user = computed(() => store.state.user);
    const allSegments = computed<Segment[]>(() => store.state.allSegments);
    const allProfessions = ref<Profession[]>(store.state.allProfessions);
    const contentEl = ref<any>(null);
    const waitingBotResp = ref(false);
    const latestBotResp = ref("");
    const userInputs = reactive({
      custom: "",
      workExps: "",
      jd: "",

      photoOfA: "",
      wearing: "",
      holding: "",
      engaging: "",
      gesture: "",
      workingIn: "",
      facialExp: "",
      lookingAt: "",
      style: "in japanese Anime Art Styles",
      // 一個男／女___，穿着___，拿着___，在___裏，背景有__、_____等，以日系動漫風繪畫
    });
    const messages = ref<any>([]);
    const loading = ref(true);

    // utils
    const scrollToBottom = () => {
      setTimeout(() => {
        contentEl.value?.$el.scrollToBottom();
      }, 100)
    }
    const addNewMsgObj = (msgObj) => {
      if (!msgObj.createdAt) msgObj.createdAt = new Date().valueOf();
      messages.value.push(msgObj);
      scrollToBottom();
    }
    const getReqType = () => {
      const { isFromAB4, isImageBot, } = props;
      if (isFromAB4) return 'ab4';
      if (isImageBot) return 'image';
      return 'cvcl';
    }
    const sendPromptToBot = async (type) => {
      let sendingPrompt = userInputs.custom;
      if (['cv','cl'].includes(type)) {
        sendingPrompt = `--Below is a draft of my working experiences:--\n${userInputs.workExps}`;
        if (type == 'cl') {
          sendingPrompt = `--Below is the job description of my applied job:--\n${userInputs.jd}\n\n${sendingPrompt}`;
        }
      } else if (type == 'image') { // guided prompt for image generation
        sendingPrompt = `Photo of a ${userInputs.photoOfA} worker`;
        if (userInputs.wearing) sendingPrompt += `, wearing ${userInputs.wearing}`;
        if (userInputs.holding) sendingPrompt += `, holding ${userInputs.holding}`;
        if (userInputs.engaging) sendingPrompt += `, engaging ${userInputs.engaging}`;
        if (userInputs.gesture) sendingPrompt += `, ${userInputs.gesture}`;
        if (userInputs.workingIn) sendingPrompt += `, working in ${userInputs.workingIn}`;
        if (userInputs.facialExp) sendingPrompt += `, ${userInputs.facialExp}`;
        if (userInputs.lookingAt) sendingPrompt += `, looking at ${userInputs.lookingAt}`;
      }

      if (props.isImageBot) {
        if (userInputs.style) sendingPrompt += `, ${userInputs.style}`; // in Japanese Anime Art Styles
      }

      // Show user prompt on UI
      const msgId  = `m${uniqueId()}`;
      const newPrompt = { id: msgId, fromUser: true, content: sendingPrompt, type, };
      addNewMsgObj(newPrompt);

      // construct query msgs (with previous conversation as context)
      const queryMsgs = messages.value.filter(m => !m.fromUser).concat(newPrompt).map(m => ({
        "role": m.fromUser ? "user" : "bot",
        "content": m.content,
        "timestamp": (new Date(m.createdAt)).valueOf(),
        "message_id": m.id,
      }))

      // Send request to Chatbot
      latestBotResp.value = "...";
      waitingBotResp.value = true;
      const reqType = getReqType();
      const url = `https://ab-chatgpt-api.fdmt.hk/${reqType}`;
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer 617ba408824b9f2c2e9e1b61e2c04761",
          },
          body: JSON.stringify({
            "version":"1.0","type":"query","query": queryMsgs,
            "user_id": user.value.id,"conversation_id": `${reqType}-${user.value.id}`,"message_id":"",
          })
        });

        if (!response.ok) throw new Error(response.statusText);

        // This data is a ReadableStream
        const data = response.body;
        if (!data) return;
        latestBotResp.value = ""; // empty bot response first

        const reader = data.getReader();
        const decoder = new TextDecoder();
        let done = false;

        while (!done) {
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
          const chunkValue = decoder.decode(value);
          //console.log(chunkValue);
          for (const message of chunkValue.split("\n")) {
            const matches = message.match(/\{([^}]+)\}/);
            if (matches) {
              const parsed = JSON.parse(matches[0]);
              if (parsed.text) {
                latestBotResp.value += parsed.text;
                scrollToBottom();
              }
            }
          }
        }
        addNewMsgObj({ id: `b-${msgId}`, content: latestBotResp.value, type }); // bot msg
      } catch (e) {
        presentAlert("Bot did not response. Please try again");
        if (!['cl', 'cv'].includes(type)) userInputs.custom = sendingPrompt;
        latestBotResp.value = "";
      } finally {
        ChatService.insertUserChatbotPrompts([{
          ...newPrompt,
          botResponse: latestBotResp.value,
          professionId: props.professionId,
        }]);
        latestBotResp.value = "";
        waitingBotResp.value = false;
      }
    }

    // Init

    onMounted(() => {
      const { isFromAB4, professionId, botExplanation, prefilledPrompt, } = props;

      if (!isFromAB4) {
        // Prefill JD (target profession & segment)
        const { userProfessions, userSegments } = user.value;
        const likedProfessions = (userProfessions || []).filter(up => up.reaction == 'like').sort((a, b) => Number(a.order)-Number(b.order));
        if (likedProfessions.length > 0) {
          const targetProfession = allProfessions.value.find(p => p.id == likedProfessions[0].professionId);
          if (targetProfession) userInputs.jd = `A ${targetProfession?.name}`;
          const likedSegments = (userSegments || []).filter(us => us.professionId == targetProfession?.id).filter(up => up.reaction == 'like');
          if (likedSegments.length > 0) {
            const targetSegment = allSegments.value.find(s => s.id == likedSegments[0].segmentId);
            if (targetSegment) userInputs.jd = `${userInputs.jd} working in ${targetSegment.name}`;
          }
        }
      }

      // Retrieve old conversations
      ChatService.getUserChatbotPrompts(null, professionId).then(res => {
        loading.value = false;
        for (const row of res) {
          const { id, prompt, botResponse, type, createdAt, } = row;
          messages.value.push({ id: `p${id}`, fromUser: true, content: prompt, type, createdAt, });
          if (botResponse) messages.value.push({ id: `b${id}`, content: botResponse, type, createdAt, });
        }
        scrollToBottom();

        // AB4: trigger "tell me more"
        if (botExplanation) {
          messages.value.push({ id: `b${uniqueId()}`, content: botExplanation, type: 'ab4', createdAt: new Date(), });
          userInputs.custom = prefilledPrompt;
          sendPromptToBot('ab4');
          userInputs.custom = "";
        }
      });

      // Image Bot
      if (props.isImageBot && props.imageBotTargetProfession) {
        const { imagePrompt, name, } = props.imageBotTargetProfession;
        userInputs.photoOfA = name;
        userInputs.wearing = imagePrompt.wearing || "";
        userInputs.holding = imagePrompt.holding || "";
        userInputs.engaging = imagePrompt.engaging || "";
        userInputs.gesture = imagePrompt.gesture || "";
        userInputs.workingIn = imagePrompt["working in"] || "";
        userInputs.facialExp = imagePrompt["Facial expression"] || "";
        userInputs.lookingAt = imagePrompt["Looking at"] || "";
      }
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, trashOutline, caretDown, createOutline, openOutline,
      send,

      // variables
      loading, user,
      contentEl,
      userInputs, messages,
      waitingBotResp, latestBotResp,

      // methods
      t,
      closeModal, getProxyImgLink,
      openImageModal,
      sendCLClaimsReq: () => {
        sendPromptToBot('cl');
      },
      sendCVBulletPointsReq: () => {
        sendPromptToBot('cv');
      },
      sendMsg: () => {
        if (userInputs.custom && userInputs.custom.trim()) {
          sendPromptToBot('cvcl-custom');
          userInputs.custom = "";
        }
      },
      parseMsg: (msg) => {
        return md.render(msg);
      },
      isImageMessage: (msg) => {
        return msg.match(/^https?:\/\/.*\.(png|jpg|jpeg|gif|webp)$/i);
      },
      getPreviousUserMessage: (currentMsg) => {
        const currentIndex = messages.value.findIndex(m => m.content === currentMsg.content);
        const prevUserMsg = messages.value.slice(0, currentIndex)
          .reverse()
          .find(m => m.fromUser)?.content;
        return prevUserMsg || '';
      },
      sendBotMsgToWhatsApp: async (msg, imgLink = "") => {
        // If this is an image message, find the previous user message
        let messageToSend = msg;
        if (msg.match(/^https?:\/\/.*\.(png|jpg|jpeg|gif|webp)$/i)) {
          // Find the last user message before this image
          const currentIndex = messages.value.findIndex(m => m.content === msg);
          const prevUserMsg = messages.value.slice(0, currentIndex).reverse().find(m => m.fromUser)?.content;
          
          if (prevUserMsg) {
            messageToSend = prevUserMsg;
            imgLink = msg;
          }
        }

        presentPrompt("Send this message to your WhatsApp group for records?", async () => {
          const loading = await loadingController.create({});
          await loading.present();
          SLPService.sendWhatsAppMsg(messageToSend, user.value.phone, user.value.waGroupId, imgLink);
          await sleep(2);
          loading.dismiss();
          presentToast("The message will be sent to your WhatsApp group in minutes.");
        });
      },

      // For Demo only (generic CV & JD)
      prefillDemoData: () => {
        userInputs.workExps = `Marketing Coordinator at TechCorp (2021-2023)
- Managed social media campaigns across 3 platforms
- Coordinated with design team for marketing materials
- Analyzed campaign metrics and prepared monthly reports
- Assisted in organizing 4 major product launch events

Digital Marketing Intern at CreativeAgency (2020)
- Supported email marketing campaigns
- Helped maintain company website content
- Created social media content calendars`

        userInputs.jd = `Position: Senior Marketing Specialist
Company: InnovateDigital

We're seeking a creative and data-driven Senior Marketing Specialist to join our growing team. The ideal candidate will lead our digital marketing initiatives and develop comprehensive marketing strategies.

Key Responsibilities:
- Develop and execute digital marketing campaigns
- Manage social media presence and content strategy
- Analyze marketing metrics and optimize campaign performance
- Coordinate with internal teams for content creation
- Lead product launch marketing activities

Requirements:
- 3+ years of digital marketing experience
- Strong analytical and project management skills
- Experience with social media marketing
- Knowledge of SEO and content marketing`;
      },

      // Demo prompt (image bot)
      prefillDemoDataForImageBot: () => {
        userInputs.photoOfA = userInputs.photoOfA || "Lawyer";
        userInputs.wearing = userInputs.wearing || "Suit";
        userInputs.holding = userInputs.holding || "a pen";
        userInputs.engaging = userInputs.engaging || "with a client";
        userInputs.gesture = userInputs.gesture || "talking to a client";
        userInputs.workingIn = userInputs.workingIn || "in a conference room";
        userInputs.facialExp = userInputs.facialExp || "smile";
        userInputs.lookingAt = userInputs.lookingAt || "the camera";
      },
      sendPromptToBot,
      openProfessionModal: async (professionId) => (await openModal(ProfessionModal, { professionId })),
    }
  }
});
