
// Vue reactivity
import { reactive, defineComponent, computed, ref, onMounted } from 'vue';

// icons
import { mail, close, arrowBack, arrowForward, checkmark, add, send, calendar, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonSpinner, IonItem, IonLabel, IonChip, IonIcon,
        IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea, IonSearchbar,
        IonGrid, IonCol, IonRow, IonSelect, IonSelectOption, IonCard, IonCardContent,
        IonDatetimeButton, IonDatetime, IonModal, IonPopover, IonList, IonCheckbox, IonBadge, IonText,
        loadingController, modalController, } from '@ionic/vue';
import RegistrationQRCodeModal from '@/components/modals/RegistrationQRCodeModal.vue'
import SchoolTeacherModal from '@/components/teacher/SchoolTeacherModal.vue';
import AchieveJUPASResultPageModal from '@/components/achievejupas/AchieveJUPASResultPageModal.vue';

// utils
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';

// services
import TeacherService from '@/services/TeacherService';

// types
import { FormQuestion, TeacherResponseOption, TeacherResponseQuestion, User, } from '@/types';

export default {
  props: ["serviceId", "group", "event", "serviceSessions", "isSurvey", "prefilledOption", "hideFirstQuestionTitle", "hideSubmitButton",
          "isClientView", "targetClass", "prefilledOptionId"],
  emits: ['selectedOptionChanged'],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonSpinner, IonItem, IonLabel, IonChip, IonIcon,
                IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea, IonSearchbar,
                IonGrid, IonCol, IonRow, IonSelect, IonSelectOption, IonCard, IonCardContent,
                IonDatetimeButton, IonDatetime, IonModal, IonPopover, IonList, IonCheckbox, IonBadge, IonText, },
  setup(props, { emit }) {
    // methods or filters
    const { t } = useI18n();
    const { formatDate, presentToast, presentAlert, openModal, presentPrompt, } = utils();

    // 1. declare state variables (ref to make them reactive)
    const store = useStore();
    const user = computed(() => store.state.user);
    const allOptions = computed<TeacherResponseOption[]>(() => store.state.teacherResponseOptions);
    const questions = computed<TeacherResponseQuestion[]>(() => props.targetClass ? store.state.teacherResponseQuestions : store.getters.getTeacherResponseQuestions(props.serviceId, props.group));
    const service = computed(() => store.getters.getServiceById(props.serviceId));
    const formResponse = reactive({});
    const selectedOptions = reactive({}); // for checking whether to show following questions
    const showMoreOptions = ref(false);
    const teacherResponse = reactive({
      id: "",
      optionId: "",
      response: "",
      estNumOfStudents: "",
      preferredSessionDates: null as any,
      preferredSessionTime: null as any,
      eventName: "",
      updatedAt: "",
      type: props.group, // service / event / survey
      role: "",
      assignedTo: "",
      intakeYear: null,
      targetClass: props.targetClass || "",
    });
    const isEnableNextStep = (opt) => (opt?.immediateAction == "enable next step"); // for Work only

    // List of filtered questions
    const filteredQuestions = () => {
      if (props.targetClass) { // Class-based response: show questions directly
        return questions.value.filter(q => (q.showInOptionIds?.includes(props.prefilledOptionId))); 
      }
      return questions.value.filter(q => {
        return !q.showInOptionIds || Object.values(selectedOptions).some((optId: any) => {
          if (!optId) return false;
          if (Array.isArray(optId)) return optId.some(id => (q.showInOptionIds?.includes(id)));
          return q.showInOptionIds?.includes(optId);
        });
      });
    }

    // target specific session of a service (for nomination)
    const selectedEventId = ref(null);
    const getTargetEvent = () => {
      const { event, serviceSessions, group, } = props;
      const targetEv = selectedEventId.value ? serviceSessions.find(s => s.id == selectedEventId.value) : event;
      if (group == 'service') return null;
      return targetEv;
    }

    // Sync response answer based on latest selected service / events (mainly for switching "Target session")
    const refreshTeacherResponse = () => {
      if (['teacher', 'university-client'].includes(user.value.roles || "")) {
        const { event, serviceId, isSurvey, } = props;
        const { intakeYear } = service.value;
        const relatedResponse = user.value.teacherResponses?.find(resp => {
          //const eventId = selectedEventId.value || event?.id;
          const eventId = event?.id;
          //if (resp.type == 'assign') return false;
          if (isSurvey && resp.type != 'survey') return false;
          if (resp.type == 'action') return false;
          if (eventId) return resp.sessionId == eventId;
          return resp.serviceId == serviceId && (!intakeYear || resp.intakeYear == intakeYear) && (!resp.preferredSessionDates || new Date(resp.preferredSessionDates) > new Date());
        });
        setTimeout(() => {
          // Check & set default option
          for (const q of filteredQuestions()) {
            const targetOpt = q.options.find(opt => isEnableNextStep(opt));
            if (targetOpt) {
              teacherResponse.optionId = targetOpt.id; // proceed to next step
              selectedOptions[q.id] = targetOpt.id;
              formResponse[q.id] = targetOpt.title;
              emit('selectedOptionChanged', targetOpt);
              break;
            }
          }

          // Prefill previous response
          //teacherResponse.id = relatedResponse?.id || ""; // Every time is new submission (only prefill option)
          teacherResponse.optionId = relatedResponse?.optionId || "";
          teacherResponse.response = relatedResponse?.response || "";
          teacherResponse.estNumOfStudents = relatedResponse?.estNumOfStudents || "";
          teacherResponse.preferredSessionDates = relatedResponse?.preferredSessionDates;
          //sessionDateInput.value.$el.reset(relatedResponse?.preferredSessionDates);
          teacherResponse.preferredSessionTime = relatedResponse?.preferredSessionTime || "";
          teacherResponse.updatedAt = relatedResponse?.updatedAt || "";

          if (relatedResponse && relatedResponse.questionAnswers) {
            for (const qa of relatedResponse.questionAnswers) {
              const { questionId, answer, optionId } = qa;
              if (optionId) {
                // prefilled previous selected option if not one-off action (e.g. try out the tool)
                const option = allOptions.value.find(opt => opt.id == optionId);
                emit('selectedOptionChanged', option);
                if (option?.immediateAction) continue; // not prefill previously filled option
                selectedOptions[questionId] = optionId;
              }
              formResponse[questionId] = answer;
            }
          }
        }, 100);
      }
    }

    onMounted(() => {
      const { isSurvey, serviceSessions, prefilledOption, isClientView, prefilledOptionId, } = props;
      if (serviceSessions) selectedEventId.value = serviceSessions[0]?.id;
      if (isSurvey) teacherResponse.type = "survey"; // Survey Responses
      refreshTeacherResponse(); // check prefill previous response option

      const prefilledOptionTitle = isClientView ? 'propose a date' : prefilledOption;
      if (prefilledOptionTitle) {
        // Prefill specific option
        for (const question of filteredQuestions()) {
          const { id: questionId, options } = question;
          for (const opt of options) {
            if (opt.title?.includes(prefilledOptionTitle)) {
              formResponse[questionId] = opt.title;
              selectedOptions[questionId] = opt.id;
            }
          }
        }
      }

      // Prefill option ID for class-based response (focus on teacherResponse)
      if (prefilledOptionId) {
        teacherResponse.optionId = prefilledOptionId;
        teacherResponse.response = (allOptions.value.find(opt => opt.id == prefilledOptionId) || {}).title || "";
      }
    });

    const upsertTeacherResponseObj = async () => {
      // Prevent error: key (option_id)=() is not present in table "teacher_response_options".
      if (!props.prefilledOptionId) {
        const defaultOptionId: any = Object.values(selectedOptions)[0] || null; // default optionId
        teacherResponse.optionId = defaultOptionId;
      }

      // Map latest form responses to teacher response
      for (const question of filteredQuestions()) {
        const { relatedTeacherResponseField: field } = question;
        if (field && field in teacherResponse) {
          teacherResponse[field] = formResponse[question.id];
          if (field == 'response') teacherResponse.optionId = selectedOptions[question.id];
          if (field == 'preferredSessionDates') {
            teacherResponse[field] = formatDate(formResponse[question.id], 'YYYY-MM-DD');
            teacherResponse.preferredSessionTime = formatDate(formResponse[question.id], 'HH:mm');
          }
        }
      }
      const { serviceId, } = props;
      const targetEvent = getTargetEvent();
      teacherResponse.eventName = targetEvent?.displayName || service.value?.name;
      teacherResponse.intakeYear = service.value?.currIntakeYear;
      const questionAnswers = filteredQuestions().map(q => ({
        questionId: q.id,
        questionTitle: q.title || "",
        answer: formResponse[q.id],
        optionId: selectedOptions[q.id] || '',
      })); // answers for each questions

      // Assign role based on response (selected option)
      Object.entries(selectedOptions).forEach(([questionId, optId]) => {
        const question = questions.value.find(q => q.id == questionId);
        const opt = question?.options.find(o => o.id == optId);
        if (opt?.targetRole) teacherResponse.role = opt?.targetRole;
      });
      if (teacherResponse.role == "Not involved") teacherResponse.assignedTo = user.value.id; // assign to myself

      // Insert response
      const responseObj = await TeacherService.upsertTeacherResponse(targetEvent, serviceId, teacherResponse, user.value.phone, user.value.schoolId, user.value.id, questionAnswers);
      teacherResponse.id = responseObj.id;
      teacherResponse.updatedAt = new Date().toString();
      store.commit('upsertTeacherResponse', { ...responseObj, updatedAt: new Date().toString() });
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      mail, close, arrowBack, arrowForward, checkmark, add, send, calendar,

      // variables
      service,
      questions, formResponse,
      teacherResponse,
      selectedOptions, showMoreOptions,

      // methods
      t, formatDate,
      refreshTeacherResponse,
      upsertTeacherResponse: async () => {
        if (props.targetClass) { // Class-based response, present prompt to confirm
          const respDescriptions = filteredQuestions().map(q => {
            return `${q.title}: ${q.type == 'datetime' ? formatDate(formResponse[q.id], "YYYY-MM-DD HH:mm") : formResponse[q.id] || "/"}`;
          }) // answers for each question
          presentPrompt(`Submit your response for ${props.targetClass}?<br /><br />${respDescriptions.join("<br />")}`, async () => {
            const loading = await loadingController.create({ duration: 60000 });
            await loading.present();
            await upsertTeacherResponseObj();
            presentToast("Your response has been submitted successfully!", 2000, "bottom"); 
            modalController.dismiss();
            loading.dismiss();
          });
        } else {
          const loading = await loadingController.create({ });
          await loading.present();
          await upsertTeacherResponseObj();
          presentToast("Your response has been submitted successfully!", 2000, "bottom");
          loading.dismiss();
        }
      },

      // Nominate students
      selectedEventId, getTargetEvent,

      // Show list of students based on search keyword & filters
      openRegQRCodeModal: async (targetTo = 'student') => (await openModal(RegistrationQRCodeModal, { event: getTargetEvent(), targetTo, serviceId: props.serviceId, })),

      // Questions & Options
      filteredQuestions,
      filteredQuestionOptions: (question) => {
        const { serviceId, group, serviceSessions, } = props;
        return question.options.filter(opt => {
          const isProposeDateOpt = opt.title.includes("propose a date");
          if (props.isClientView) return isProposeDateOpt;
          if (isProposeDateOpt) return service.value.isAllowProposeDates != false;
          //if (opt.actionCreate == 'proposed session') return service.value.isAllowProposeDates != false;
          if (opt.excludingServiceIds?.includes(serviceId)) return false;
          if (group == 'service' && (opt.showNominateBtn || opt.showIfExistSessions)) return serviceSessions && serviceSessions.length > 0;
          return true;
        });
      },
      updateSelectedOption: (questionId, option) => {
        setTimeout(() => {
          selectedOptions[questionId] = option.id;
          emit('selectedOptionChanged', option);

          // Check immediate action (e.g. on select -> open modal -> reset)
          if (option.immediateAction) {
            // Update teacher response if different from prev option
            const prevResponse = user.value.teacherResponses?.find(r => r.id == teacherResponse.id);
            for (const question of filteredQuestions()) {
              const { relatedTeacherResponseField: field } = question;
              if (field && field in teacherResponse) {
                teacherResponse[field] = formResponse[question.id];
                if (field == 'response') teacherResponse.optionId = selectedOptions[question.id];
              }
            }
            if (prevResponse?.optionId != teacherResponse.optionId) upsertTeacherResponseObj();
            
            // Trigger immediate action
            switch (option.immediateAction) {
              case "open mock jupas tool": // Mock JUPAS try out the tool
                openModal(AchieveJUPASResultPageModal, {});
                break;
              case "assign teachers": // assigning teachers' roles
                openModal(SchoolTeacherModal, { service: service.value, });
                break;
              case "nominate students":
                openModal(SchoolTeacherModal, { service: service.value, });
                break;
            }

            // reset option
            if (!isEnableNextStep(option)) {
              selectedOptions[questionId] = "";
              formResponse[questionId] = "";
            }
          }
          else if (option.title.includes("nominate students by")) {
            const dateQuestion = filteredQuestions().find(q => q.type == 'date');
            if (dateQuestion) {
              const { nominationDeadline } = service.value; // default set nomination deadline (if not yet over)
              if (new Date(nominationDeadline) > new Date()) formResponse[dateQuestion.id] = formatDate(nominationDeadline, 'YYYY-MM-DD');
            }
          }
          else {
            for (const question of filteredQuestions()) {
              const { id: questionId, type } = question;
              if (type == 'date') {
                formResponse[questionId] = formResponse[questionId] || formatDate(new Date(new Date().getTime() + 7*24*60*60*1000), 'YYYY-MM-DD'); // 1 week after
              }
            }
          }
        }, 200)
      },
      updateMultipleAnswerSelectedOptions: (questionId, options, ev) => {
        selectedOptions[questionId] = ev.detail.value.map(title => (options.find(opt => opt.title == title)?.id));
      },
      formatAnswer: (questionId, type) => {
        if (formResponse[questionId]) {
          if (type == 'date') formResponse[questionId] = formResponse[questionId].split("T")[0];
          if (type == 'datetime') formResponse[questionId] = formatDate(formResponse[questionId], 'YYYY-MM-DD HH:mm');
        }
      },

      // Dynamically show submit button
      isShowSubmitBtn: () => {
        if (props.targetClass) return true; // class-based response
        if (props.hideSubmitButton) return false
        for (const [questionId, optId] of Object.entries(selectedOptions)) {
          if (optId) {
            const question = questions.value.find(q => q.id == questionId);
            const opt = question?.options.find(o => o.id == optId);
            if (isEnableNextStep(opt)) return false; // not show submit button if proceed to next step
            return true; // selected some option
          }
        }
        return false; // not select anything yet
      },
    }
  }
}
