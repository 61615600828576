import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9b68d1ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "question" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onUpdate:modelValue", "name", "value", "required"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["onUpdate:modelValue", "name", "value", "required"]
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h2", null, _toDisplayString(_ctx.formTitle), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  slot: "icon-only",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.arrowBack }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { fullscreen: true }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_grid, null, {
          default: _withCtx(() => [
            _createElementVNode("form", {
              onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.submitFormResponse()), ["prevent"]))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions || [], (question, index) => {
                return (_openBlock(), _createBlock(_component_ion_card, {
                  key: question.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_content, null, {
                      default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_1, [
                          _createElementVNode("b", null, _toDisplayString(index+1) + ". " + _toDisplayString(question.title) + _toDisplayString(question.isRequired ? "*" : ""), 1)
                        ]),
                        (question.type == 'linear-scale')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                              _createElementVNode("h3", null, _toDisplayString(question.lsUpperScaleLabel), 1),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.OPTIONS.linearScale, (opt) => {
                                return (_openBlock(), _createBlock(_component_ion_item, {
                                  lines: "none",
                                  class: "answer-option-item",
                                  key: opt
                                }, {
                                  default: _withCtx(() => [
                                    _withDirectives(_createElementVNode("input", {
                                      type: "radio",
                                      slot: "start",
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.formResponse[question.id]) = $event),
                                      name: question.id,
                                      value: opt,
                                      required: question.isRequired
                                    }, null, 8, _hoisted_3), [
                                      [_vModelRadio, _ctx.formResponse[question.id]]
                                    ]),
                                    _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("p", null, _toDisplayString(opt), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128)),
                              _createElementVNode("h3", null, _toDisplayString(question.lsLowerScaleLabel), 1)
                            ]))
                          : (question.type == 'multiple-choice')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.options, (opt) => {
                                  return (_openBlock(), _createBlock(_component_ion_item, {
                                    lines: "none",
                                    class: "answer-option-item",
                                    key: opt
                                  }, {
                                    default: _withCtx(() => [
                                      _withDirectives(_createElementVNode("input", {
                                        type: "radio",
                                        slot: "start",
                                        "onUpdate:modelValue": ($event: any) => ((_ctx.formResponse[question.id]) = $event),
                                        name: question.id,
                                        value: opt,
                                        required: question.isRequired
                                      }, null, 8, _hoisted_5), [
                                        [_vModelRadio, _ctx.formResponse[question.id]]
                                      ]),
                                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("p", null, _toDisplayString(opt), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]))
                            : (['single-select', 'multi-select'].includes(question.type))
                              ? (_openBlock(), _createBlock(_component_ion_select, {
                                  key: 2,
                                  fill: "outline",
                                  interface: question.type == 'multi-select' ? null : 'popover',
                                  placeholder: "Please select",
                                  multiple: question.type == 'multi-select',
                                  modelValue: _ctx.formResponse[question.id],
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.formResponse[question.id]) = $event)
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.options, (opt) => {
                                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                                        key: opt,
                                        value: opt
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(opt), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["value"]))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1032, ["interface", "multiple", "modelValue", "onUpdate:modelValue"]))
                              : (question.type == 'paragraph')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                    _createVNode(_component_ion_item, { fill: "outline" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_textarea, {
                                          modelValue: _ctx.formResponse[question.id],
                                          "onUpdate:modelValue": ($event: any) => ((_ctx.formResponse[question.id]) = $event),
                                          required: question.isRequired
                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "required"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]))
                                : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                    _createVNode(_component_ion_item, { fill: "outline" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_input, {
                                          type: "text",
                                          modelValue: _ctx.formResponse[question.id],
                                          "onUpdate:modelValue": ($event: any) => ((_ctx.formResponse[question.id]) = $event),
                                          required: question.isRequired
                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "required"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              _createVNode(_component_ion_button, {
                class: "ion-margin-vertical",
                type: "submit",
                expand: "block",
                disabled: !_ctx.someAnswersUpdated()
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('submit')), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ], 32)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}