
// Vue reactivity
import { ref, defineComponent, computed, watch, reactive } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, trashOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
        IonRadioGroup, IonRadio, IonInfiniteScroll, IonInfiniteScrollContent, IonReorder, IonReorderGroup,
        IonProgressBar,
        loadingController,
        modalController, } from '@ionic/vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

// services
import ABSService from '@/services/ABSService';

// types
import { Profession, Step1Option } from '@/types';
import UserService from '@/services/UserService';

export default defineComponent({
  name: 'ProfessionSurveyModal',
  props: [],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
                IonRadioGroup, IonRadio, IonInfiniteScroll, IonInfiniteScrollContent, IonReorder, IonReorderGroup,
                IonProgressBar, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { openModal, presentPrompt, infiniteScrollLoadData, } = utils();
    const { t } = useI18n();
    const updatedProfessionResponses = ref<any>([]);

    //const professions = computed<Profession[]>(() => store.state.allProfessions);
    ///const professions = computed<Profession[]>(() => store.getters.shuffledProfessions);
    const professions: Profession[] = store.getters.shuffledProfessions;

    const numOfVisibleItems = ref(100);
    const loadData = (ev: any) => {
      infiniteScrollLoadData(ev, numOfVisibleItems, professions, 100);
    }

    const closeModal = async (leaveWithoutSave = false) => {
      if (leaveWithoutSave) {
        await presentPrompt(t("confirmLeave"), async () => {
          for (const response of updatedProfessionResponses.value) {
            // clear filled responses before leave
            const profession = professions.find(p => p.id == response.professionId);
            if (profession) profession.userResponse = "";
          }
          await modalController.dismiss({});
        });
      } else {
        await modalController.dismiss({});
      }
    };

    // return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack,
      trashOutline,

      // variables
      numOfVisibleItems,
      professions,
      updatedProfessionResponses,

      // methods
      t,
      loadData, closeModal,

      updateProfessionResponse: (profession: Profession, response: string) => {
        updatedProfessionResponses.value.push({
          professionId: profession.id,
          response,
          createdAt: new Date(),
        });
        profession.userResponse = response;
      },

      submitProfessionResponses: async (updatedProfessionResponses) => {
        if (updatedProfessionResponses.length > 0) {
          const loading = await loadingController.create({ });
          await loading.present();
          let { heardOfProfessionIds, notHeardOfProfessionIds } = store.state.user;
          heardOfProfessionIds = heardOfProfessionIds || [];
          notHeardOfProfessionIds = notHeardOfProfessionIds || [];

          // Update user latest responses (heard of & not heard of)
          for (const { response, professionId } of updatedProfessionResponses) {
            if (response == "Yes") {
              heardOfProfessionIds.push(professionId);
              if (notHeardOfProfessionIds.includes(professionId)) {
                notHeardOfProfessionIds.splice(notHeardOfProfessionIds.indexOf(professionId), 1);
              }
            } else {
              notHeardOfProfessionIds.push(professionId);
              if (heardOfProfessionIds.includes(professionId)) {
                heardOfProfessionIds.splice(heardOfProfessionIds.indexOf(professionId), 1);
              }
            }
          }

          // Update DB & Store
          const updatingObj = {
            heardOfProfessionIds: [...new Set(heardOfProfessionIds)],
            notHeardOfProfessionIds: [...new Set(notHeardOfProfessionIds)],
          }
          UserService.updateUser(updatingObj)
          store.commit('updateUser', updatingObj);
          //ABSService.upsertProfessionResponses(updatedProfessionResponses);
          store.commit('injectUserProfessionResponses', updatedProfessionResponses);
          loading.dismiss();
        }
        closeModal();
      },

      getProfessionResponseCount: (response: any) => (professions.filter(p => p.userResponse == response).length),
      getTotalResponseCount: () => (professions.filter(p => !!p.userResponse).length),
      //getResponseProgress: () => (professions.filter(p => !!p.userResponse).length / professions.length),
      getResponseProgress: () => (professions.filter(p => !!p.userResponse).length / 50),
    }
  },
});
