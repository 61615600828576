import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_service_details = _resolveComponent("service-details")!

  return (_openBlock(), _createBlock(_component_service_details, {
    isModal: true,
    serviceId: _ctx.serviceId,
    event: _ctx.event,
    hideTeacherResponse: _ctx.hideTeacherResponse,
    prefilledOption: _ctx.prefilledOption,
    isSurvey: _ctx.isSurvey,
    isSecondaryStudentView: _ctx.isSecondaryStudentView,
    isClientView: _ctx.isClientView
  }, null, 8, ["serviceId", "event", "hideTeacherResponse", "prefilledOption", "isSurvey", "isSecondaryStudentView", "isClientView"]))
}