
// Vue reactivity
import { ref, defineComponent, computed, watch, reactive, onMounted, onUnmounted, onBeforeUnmount } from 'vue';

// icons
import { add, close, checkmark, arrowUp, arrowForward, arrowBack, trashOutline,
        thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline,
        chevronBack, chevronForward, repeat, search, pencil, refresh, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonTextarea, IonThumbnail,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
        IonRadioGroup, IonRadio, IonInfiniteScroll, IonInfiniteScrollContent, IonReorder, IonReorderGroup,
        IonFab, IonFabButton, IonRippleEffect, IonSpinner, IonAvatar,
        IonicSlides, isPlatform, modalController, loadingController, } from '@ionic/vue';
import ProfessionModal from '@/components/pss/ProfessionModal.vue';
import ServiceModal from '@/components/modals/ServiceModal.vue';
import ChatbotModal from '@/components/modals/ChatbotModal.vue';

// Swiper
import 'swiper/swiper.min.css';
import 'swiper/modules/effect-cards/effect-cards.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import '@ionic/vue/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { EffectCards, Navigation, } from 'swiper';

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';
import { utilsGPT } from '@/composables/utilsGPT';

// types
import { ClientProfession, Profession, ProfessionTab, Step1Option, UserClaim, UserProfession } from '@/types';

// services
import ABSService from '@/services/ABSService';
import config from '@/config';

export default defineComponent({
  name: 'ABProfessionSelectModal',
  props: [
    "prefilledProfessions", "oldUserProfessions", "isPreview", "client", "clientId", "isFromProgramDeck",
    "isAIImageCompetition",      
  ],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonTextarea, IonThumbnail,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
                IonRadioGroup, IonRadio, IonInfiniteScroll, IonInfiniteScrollContent, IonReorder, IonReorderGroup,
                IonFab, IonFabButton, IonRippleEffect, IonSpinner, IonAvatar,
                Swiper, SwiperSlide },
  setup(props) {
    // methods or filters
    const { t } = useI18n();
    const store = useStore();
    const { openModal, closeModal, doReorder, navigateMaterialCategories, infiniteScrollLoadData,
            processUserItems, isItemSelected, isItemDisliked, isMobileWeb,
            onThumbsUpItem, onThumbsDownItem, onClickMoreBtn, onClickPrevBtn, animateToFirstCard,
            recordCurrSlideReaction, resetActiveSlide, syncChosenItems, resetFilters, focusKeywordSearchbar,
            presentAlert, } = utils();

    const showDemoOnly = !store.getters.isAB4User; // show demo options only
    const user = computed(() => store.state.user);
    const settings = computed(() => store.state.settings);

    const step1Questions = computed(() => store.getters.getStep1Questions(false, true, ['Passion', 'Subject']));
    const selectedProfessions = ref<Profession[]>(props.prefilledProfessions || []);
    const userProfessions = ref<UserProfession[]>(props.oldUserProfessions || []);
    const tmpNewUserProfessions = ref<UserProfession[]>([]); // for storing slideChange user professions
    const allProfessions = ref<Profession[]>(store.getters.shuffledProfessions(false, false));
    const uniGradProfessions = ref<Profession[]>(store.getters.shuffledProfessions(false, true));
    const allProfessionTabs = computed<ProfessionTab[]>(() => store.state.professionTabs);

    // Deck filters
    const selectedFilterGroup = ref(props.isAIImageCompetition ? 'AI Images' : (props.client ? 'Subject' : 'gpt'));
    const selectedOption = ref<any>("all");

    // GPT (strength-based recommendations)
    const { parseMsg, fetchGPTResponse, parseGPTResponse, whatsAppSendGPTResults, upsertUserItems, getStrengthDescription, initGPTObj, } = utilsGPT();
    const gpt = reactive({
      userInputText: "", // user input prompt
      tagId: "",
      tagText: "",
      currStep: 1,
      waitingGPTResp: false,
      isInCooldownTime: false, // prevent users sending requests consecutively
      cooldownSecLeft: 0,
      selectedTagGroup: "Passion",
    });
    const botSuggestedProfessions = ref<Profession[]>([]);
    const currViewingProfession = ref<Profession>();
    const getRecommendedProfessionsFromGPT = async () => {
      try {
        botSuggestedProfessions.value = []; // reset
        currViewingProfession.value = undefined; // reset
        const { studyingElectives, firstSelectedDisciplineNames, } = user.value; // TBC: include more info about the students?
        const MAX_PROFESSIONS = 10;

        let prompt = `Key strength: ${getStrengthDescription(gpt)}`;
        let overrideBotName = "", botUrl = "https://ab-chatgpt-api.fdmt.hk/ab4";

        if (props.isFromProgramDeck) {
          // Professions related to specific program (e.g. PolyU LMS)
          const { clientId } = props;
          overrideBotName = config.separatePoeBotClientIds.includes(clientId) ? clientId : "";
          botUrl = "https://ab-chatgpt-api.fdmt.hk/slp";
          prompt += `\n\nAbove is the information about a Hong Kong F5/6 student, who is joining the event of the client "${clientId}". Please suggest at most ${MAX_PROFESSIONS} unique professions that are most suitable for the student based on his/her strengths. The professions must ONLY be those related to the client "${clientId}".`;
          prompt += ` For each profession, please explain in detail how the student's key strength is applied in most related daily tasks in TRADITIONAL CHINESE to convince the student that the profession is suitable. Be as specific & concrete as possible.`;
          if (clientId == "PolyU_BRE") { // Customized for SurveyorWork
            prompt += `For Surveyor profession (client: PolyU_BRE), please give your explanation for each division (建築測量組, 產業測量組, 規劃及發展組, 物業設施管理組, 工料測量組) paragraph by paragraph, with the first paragraph being the most suitable division. Paragraphs are separated with blank newlines, shown like numbered list, with the divisions highlighted in bold.`;
          }
        } else {
          // AB4: data source is university-graduate professions
          //prompt += `\nStudying elective subjects: ${studyingElectives}`;
          //prompt += firstSelectedDisciplineNames ? `\nInterested university disciplines: ${firstSelectedDisciplineNames}` : '';
          prompt += `\n\nAbove is the information about a Hong Kong F5/6 student. Please suggest at most ${MAX_PROFESSIONS} unique professions that are most relevant to the student based on his/her strength. `;
          prompt += `The profession must ONLY be one of those in the data source "fdmt-professions-X" (consider all chunks).`;
          prompt += ` For each profession, please explain in detail how the student's key strength is applied in most related daily tasks`;
          prompt += ` in TRADITIONAL CHINESE to convince the student that the profession is suitable. Be as specific & concrete as possible.`;
        }
        prompt += `\n\nYour response MUST be formatted in JSON with only an array of JavaScript objects (at most ${MAX_PROFESSIONS} objects), each object must contain exactly 2 fields: "id", "explanation"\n`;
        prompt += `Example response: [{"id":"499","explanation":"XXX"}]`;

        // Send request
        const data = await fetchGPTResponse(gpt, botUrl, prompt, user.value.id, overrideBotName);
        if (!data) throw new Error("No data");
  
        // Parse data (bot responses)
        await parseGPTResponse(gpt, data, currViewingProfession, botSuggestedProfessions, allProfessions, '#card-swiper-slides', 'professionId');

        // Insert to DB for records first
        upsertUserItems(user.value.id, botSuggestedProfessions, userProfessions, 'professionId');

        // Send out the results to student's WhatsApp group for records
        whatsAppSendGPTResults(user.value.phone, user.value.waGroupId, getStrengthDescription(gpt), botSuggestedProfessions, 'profession');
      } catch (e) {
        console.error(e);
        presentAlert("ChatGPT did not response. Please try again");
        gpt.currStep = 1;
      } finally {
        gpt.waitingGPTResp = false;
      }
    }

    // Search
    const searchKeyword = ref('');
    const isSearching = ref(false);
    const delayLoading = ref(true);

    const numOfVisibleItems = ref(20);
    const loadData = (ev: any) => { infiniteScrollLoadData(ev, numOfVisibleItems, store.state.allProfessions) }

    const getAppState = () => ({
      selectedFilterGroup: selectedFilterGroup.value,
      selectedOption: selectedOption.value,
      searchKeyword: searchKeyword.value,
    });
    const confirmSelect = async (noLoading = false) => {
      await closeModal({
        "selectedProfessions": selectedProfessions.value,
        "userProfessions": processUserItems(selectedProfessions.value, userProfessions.value, tmpNewUserProfessions.value, 'professionId', store.state.user.id),
        noLoading,
      }); // return selected profession & order here
    };
    const openProfessionModal = async (professionId: any, gptExplanationHTML = "") => await openModal(ProfessionModal, { gptExplanationHTML, professionId, useBackButton: true });

    // Helper functions for profession reaction
    const recordActiveSlideReaction = () => {
      recordCurrSlideReaction('professionId', userProfessions, tmpNewUserProfessions, getAppState());
    }
    const isProfessionSelected = (profession: any) => (isItemSelected(profession, selectedProfessions));
    const isProfessionDisliked = (profession: any) => (isItemDisliked(profession, 'professionId', userProfessions));

    const startIdx = ref(0); // for card slides

    // Record Access & Leave Time
    let accessTime, duration = 0, counterInterval;
    onMounted(() => {
      accessTime = new Date();
      counterInterval = setInterval(() => (duration++), 1000);

      // Prefill with previously written reasons
      syncChosenItems('professionId', selectedProfessions, userProfessions, allProfessions.value);

      setTimeout(() => {
        delayLoading.value = false;
        setTimeout(() => {
          const slides: any = document.querySelector('#card-swiper-slides');
          if (slides) {
            slides.swiper.on('slideChange', recordActiveSlideReaction);
            recordActiveSlideReaction(); // initial slide
          }
          const opts = step1Questions.value[selectedFilterGroup.value] || [];
          selectedOption.value = opts.length > 0 ? opts[0] : 'all';

          if (props.client) { // check & prefill with teacher school roles (if exist related tags)
            const userSchoolRoles = (user.value.teacher?.schoolRoles || "").split(" , ").map(r => store.state.schoolRoles.find(sr => sr.title == r)).filter(r => r);
            const relatedClientProfession = props.client.professionsByTags.find(p => userSchoolRoles.find(sr => p.tag == sr?.relatedSubject));
            if (relatedClientProfession) selectedOption.value = relatedClientProfession;
          }
        }, 200);
      }, 200);

      // AI: Prefill previously input text & selected tag
      initGPTObj(gpt, user.value.claims);
    })
    onBeforeUnmount(() => {
      if (accessTime && duration >= 5) {
        ABSService.insertPageAccessRecord('ABProfessionSelectModal', accessTime, new Date(accessTime.getTime() + duration*1000));
        accessTime = undefined;
        duration = 0; // reset;
        clearInterval(counterInterval);
      }
    })
    watch(selectedOption, () => {
      resetActiveSlide(startIdx, delayLoading, 'professionId', userProfessions, tmpNewUserProfessions, getAppState());
    })
    watch(selectedFilterGroup, (currGroup) => {
      if (currGroup) {
        if (['like', 'dislike'].includes(currGroup)) {
          selectedOption.value = currGroup;
        }
        else if (currGroup == 'random') {
          resetFilters(selectedFilterGroup, selectedOption);
          allProfessions.value = store.getters.shuffledProfessions(false, true);
          animateToFirstCard();
        }
        else if (currGroup == 'search') {
          resetFilters(selectedFilterGroup, selectedOption);
          focusKeywordSearchbar(isSearching);
        }
        else {
          selectedOption.value = step1Questions.value[currGroup] ? step1Questions.value[currGroup][0] : {};
        }
      }
    })

    // return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, trashOutline,
      thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline,
      pencil, refresh,

      // variables
      step1Questions,
      selectedOption,
      selectedProfessions, userProfessions, tmpNewUserProfessions,
      searchKeyword, isSearching,
      delayLoading,
      settings,

      // for schools not paid for AB4, show demo options only
      showDemoOnly, user,

      // methods
      t, isMobileWeb,
      confirmSelect, closeModal, openProfessionModal,
      doReorder,
      onSearchKeywordUpdated: () => {
        ABSService.insertProfessionSearchKeywordRecord(searchKeyword.value);
      },
      recommendedProfessions: () => {
        //let filteredProfessions: any = allProfessions.value; // Default shuffle all professions (every time enter)
        let filteredProfessions: any = uniGradProfessions.value; // Default shuffle all professions (every time enter)

        // AI image competition (only show specific professions)
        if (props.isAIImageCompetition) {
          const targetProfessionIds = [279, 203, 409, 500, 455, 62, 267, 128, 337, 244, 428, 55, 406, 81, 80]
          filteredProfessions = [
            ...filteredProfessions.filter(p => targetProfessionIds.includes(p.id)),
            ...filteredProfessions.filter(p => !p.imagePrompt || !p.imagePrompt.wearing),
          ];
        }

        // Subject-related career prospects, default show professions related to the client
        if (props.client) {
          filteredProfessions = props.client.programRelatedProfessionIds.map(id => (allProfessions.value.find((p: Profession) => p.id == id.toString()))).filter(p => p != null);
        }

        const selectedOpt = selectedOption.value;
        if (searchKeyword.value) {
          const filteredProfessions = searchKeyword.value == '' ? allProfessions.value : allProfessions.value.filter(p => {
            return p.name.toLowerCase().includes(searchKeyword.value.toLowerCase()) || (p.nameChinese && p.nameChinese.includes(searchKeyword.value));
          });
          return showDemoOnly ? filteredProfessions.slice(0, 2) : filteredProfessions;
        }
        if (['like', 'dislike'].includes(selectedOpt)) {
          return userProfessions.value.filter(up => up.reaction == selectedOpt).map(up => {
            return allProfessions.value.find(p => p.id == up.professionId);
          });
        }
        if (selectedOpt != 'all') {
          if (props.client) {
            // Subject-related professions
            const chosenTag: ClientProfession = props.client.professionsByTags.find(t => t.id == selectedOpt.id);
            if (chosenTag) {
              filteredProfessions = chosenTag.professionIds.map(id => (allProfessions.value.find((p: Profession) => p.id == id.toString()))).filter(p => p != null);
            }
          }
          else {
            const { tabIds, professionIds } = selectedOpt;
            let tabProfessionIds = [];
            for (const tid of tabIds) {
              const pTab = allProfessionTabs.value.find(pt => pt.id == tid);
              if (pTab) tabProfessionIds = tabProfessionIds.concat(pTab.relatedProfessionIds);
            }
            const relatedProfessionIds = professionIds.length > 0 ? professionIds : tabProfessionIds; // override
            filteredProfessions = [
              ...relatedProfessionIds.map(id => (allProfessions.value.find((p: Profession) => p.id == id.toString()))),
            ].filter(p => p != null);
          }
        }
        filteredProfessions = [
          ...filteredProfessions.filter(d => !isProfessionDisliked(d)),
          ...filteredProfessions.filter(d => isProfessionDisliked(d)),
        ];
        return showDemoOnly ? filteredProfessions.slice(0, 2) : filteredProfessions;
      },

      onThumbsUp: (profession: any, flipToNextSlide = true) => (onThumbsUpItem(profession, 'professionId', selectedProfessions, userProfessions, tmpNewUserProfessions, getAppState(), flipToNextSlide, true)),
      onThumbsDown: (profession: any) => (onThumbsDownItem(profession, 'professionId', selectedProfessions, userProfessions, tmpNewUserProfessions, getAppState())),
      isProfessionSelected,
      isProfessionDisliked,

      // swiper
      modules: [EffectCards, IonicSlides, Navigation],
      startIdx,
      onClickMoreBtn: () => { onClickMoreBtn(startIdx) },
      onClickPrevBtn: () => { onClickPrevBtn(startIdx) },

      // Filter groups & filters
      chevronBack, chevronForward, repeat, search,
      selectedFilterGroup, navigateMaterialCategories,

      getAppStateText: (professionId: any) => {
        const up = userProfessions.value.find(up => (up.professionId == professionId));
        const { selectedOption, searchKeyword } = up?.appState || {};
        return searchKeyword || (selectedOption ? selectedOption.text : null);
      },

      // For teachers of non-AB4 schools
      openAB4ServiceModal: () => {
        openModal(ServiceModal, { serviceId: "s9215dbaf" });
      },

      // List professions
      loadData, numOfVisibleItems,
      onCheckProfession: (checked: any, profession: Profession) => {
        if (checked) {
          if (selectedProfessions.value.find(p => p.id == profession.id) == null) {
            selectedProfessions.value.unshift(profession);
          }
        }
        else {
          const idx = selectedProfessions.value.findIndex(p => p.id == profession.id);
          if (idx !== -1) selectedProfessions.value.splice(idx, 1);
        }
      },

      // GPT
      gpt, botSuggestedProfessions, currViewingProfession,
      getRecommendedProfessionsFromGPT, parseMsg,
      toggleSelectedTag: (tagId: any, tagText: any) => {
        if (gpt.tagId == tagId && gpt.tagText == tagText) { // unselect tag
          gpt.tagId = "";
          gpt.tagText = "";
        } else {
          gpt.tagId = tagId;
          gpt.tagText = tagText
        }
      },

      // Chatbot (explain professions)
      openChatbotModal: async (profession: any) => {
        const { name, nameChinese, explanation } = profession;
        const professionName = `${name} ${nameChinese}`; // Tell me more about the profession? (too general)
        const botExplanation = `${professionName}\n${explanation}`;
        const prefilledPrompt = `Please elaborate more about how my key strength is applied in ${professionName}: ${getStrengthDescription(gpt)}`;
        return await openModal(ChatbotModal, { isFromAB4: true, professionName, botExplanation, professionId: profession.id, prefilledPrompt });
      },

      // AI image competition
      openImageChatbotModal: (profession: Profession) => {
        return openModal(ChatbotModal, { isImageBot: true, professionId: profession.id, imageBotTargetProfession: profession }); // image bot
      },
    }
  },
});
