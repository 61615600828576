
// Vue reactivity
import { ref, defineComponent, computed, onMounted, watch, reactive, isReadonly, } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, checkbox, trashOutline,
         thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline,
         chevronBack, chevronForward, repeat, search, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder, IonProgressBar,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption, IonSpinner, IonListHeader,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonRippleEffect,
        IonNote, IonTextarea, IonFab, IonFabButton, IonBadge, IonInfiniteScroll, IonInfiniteScrollContent, IonModal, IonAvatar,
        IonicSlides, isPlatform, getPlatforms, modalController, loadingController, } from '@ionic/vue';
import ProgramInfoStats from '@/components/achievejupas/ProgramInfoStats.vue';
import ProgramItemContent from '@/components/achievejupas/ProgramItemContent.vue';
import ABProfessionSelectModal from '@/components/pss/profession/ABProfessionSelectModal.vue';

// Swiper
import 'swiper/swiper.min.css';
import 'swiper/modules/effect-cards/effect-cards.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import '@ionic/vue/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { EffectCards, Navigation, Virtual, } from 'swiper';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';
import { Institution, Program, Service, UserClaim, UserProgram } from '@/types';

export default defineComponent({
  name: 'ABProgramDeckModal',
  props: [
    "isAB3", "isGPT", "targetDiscipline", "prefilledPrograms", "oldUserPrograms",
    "showFirstProgramId", "singleSelectMode",
    "prefilledDisciplines", "oldUserDisciplines",
    "targetUser", "isDemo",
  ],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder, IonProgressBar,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption, IonSpinner, IonListHeader,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonRippleEffect,
                IonNote, IonTextarea, IonBadge, IonFab, IonFabButton, IonInfiniteScroll, IonInfiniteScrollContent, IonModal, IonAvatar,
                Swiper, SwiperSlide, ProgramInfoStats, ProgramItemContent, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { closeModal, doReorder, openModal, navigateMaterialCategories, getProxyImgLink,
            processUserItems, setUserItemReaction, isItemSelected, isItemDisliked, isMobileWeb,
            onThumbsUpItem, onThumbsDownItem, onClickMoreBtn, onClickPrevBtn, animateToFirstCard,
            recordCurrSlideReaction, resetActiveSlide, syncChosenItems, resetFilters, focusKeywordSearchbar,
            openImageModal, presentPrompt, sleep, htmlToPlainText, presentAlert,
            getBandLabel, getBandClass, openServiceModal, } = utils();
    const { t } = useI18n();

    const searchKeyword = ref("");
    const isSearching = ref(false);
    const delayLoading = ref(true);
    
    const user = computed(() => props.targetUser || store.state.user);
    const disciplines = computed(() => store.state.allDisciplines); // for discipline tab
    const relatedInstitutions = ref<Institution[]>([]);
    const allPrograms = ref<Program[]>([]);
    const chosenPrograms = ref<Program[]>(props.prefilledPrograms || []);
    const userPrograms = ref<UserProgram[]>(props.oldUserPrograms || []);
    const tmpNewUserPrograms = ref<UserProgram[]>([]); // for storing slideChange reaction
    const takenActions = ref(false);

    // TODO: Work Events (implanted promotion)
    const workServices = computed<Service[]>(() => store.getters.getServicesByTypes(["Work"]));

    // client programs
    const allClientProgramIds = computed(() => store.getters.allClientProgramIds);

    // filters
    const selectedFilterGroup = ref('disciplines');
    const selectedOption = ref<any>(props.isAB3 ? 'all' : 'highlight');
    const nestedOption = ref('all'); // for nested filters for institutions
    const disciplineGroups = computed(() => store.state.allDisciplineGroups);
    const settings = computed(() => store.state.settings);

    const getAppState = () => ({
      selectedFilterGroup: selectedFilterGroup.value,
      selectedOption: selectedOption.value,
      searchKeyword: searchKeyword.value,
    });
    const confirmSelect = async (leaveWithoutSave = false) => {
      if (leaveWithoutSave) {
        if (takenActions.value) {
          await presentPrompt(`當前操作未儲存，確認要離開嗎?<br />Are you sure to leave without saving?`, async () => {
            await closeModal({});
          }, "注意 Attention");
        } else {
            await closeModal({});
        }
      } else {
        let newUserItems = processUserItems(chosenPrograms.value, userPrograms.value, tmpNewUserPrograms.value, 'programId', store.state.user.id);
        if (props.targetDiscipline?.id) {
          newUserItems = newUserItems.map(ui => ({ ...ui, disciplineId: props.targetDiscipline.id })); // link to related discipline
        }
        const loading = await loadingController.create({});
        await loading.present();
        await sleep(1);
        loading.dismiss();

        await closeModal({ "chosen": chosenPrograms.value, "userPrograms": newUserItems, }); // return selected program & order here
      }
    };

    // Helper functions for program reaction
    const setReaction = (programId: any, reaction: any, skipIfExists = false) => {
      setUserItemReaction(
        programId, reaction, 'programId', userPrograms, tmpNewUserPrograms, getAppState(), skipIfExists
      );
    }
    const isSelected = (program: any) => (isItemSelected(program, chosenPrograms));
    const isDisliked = (program: any) => (isItemDisliked(program, 'programId', userPrograms));

    const startIdx = ref(0); // for card slides

    const recordActiveSlideReaction = () => {
      setTimeout(() => {
        const slides: any = document.querySelector('#card-swiper-slides');
        if (slides) {
          const programId = slides.swiper.visibleSlides[0]?.dataset['programId'];
          console.log(programId);
        }
      }, 200);
    }
    const initSlideListener = (reset = false) => {
      setTimeout(() => {
        // Init card slides
        delayLoading.value = false;
        setTimeout(() => {
          const slides: any = document.querySelector('#card-swiper-slides');
          if (slides) {
            slides.swiper.off('slideChange', recordActiveSlideReaction);
            slides.swiper.on('slideChange', recordActiveSlideReaction);
            recordActiveSlideReaction(); // initial slide
          }
        }, 200);
      }, 200);
      
      if (reset) resetActiveSlide(startIdx, delayLoading, 'programId', userPrograms, tmpNewUserPrograms, getAppState());
    }

    // INIT
    onMounted(() => {
      const { targetDiscipline, showFirstProgramId, } = props;
      if (targetDiscipline) {
        // AB3
        relatedInstitutions.value = store.getters.getInstitutionsByDisciplineId(targetDiscipline.id, true);
        allPrograms.value = store.getters.getProgramsByDisciplineId(targetDiscipline.id, true);
      } else {
        // Mock JUPAS
        relatedInstitutions.value = store.getters.getInstitutionsByProgramType('jupas'); // only JUPAS intitutions
        allPrograms.value = store.getters.getProgramsByType('jupas'); // JUPAS programs
      }
      if (showFirstProgramId) {
        selectedOption.value = 'all';
        allPrograms.value = [
          ...allPrograms.value.filter(p => p.id == showFirstProgramId),
          ...allPrograms.value.filter(p => p.id != showFirstProgramId),
        ]
      }
      syncChosenItems('programId', chosenPrograms, userPrograms, allPrograms.value);
      initSlideListener();
    })
    watch(selectedOption, () => {
      initSlideListener();
      if (selectedFilterGroup.value == 'disciplines') {
        nestedOption.value = 'all'; // discipline groups changed
      }
    })
    watch(nestedOption, () => {
      initSlideListener(true);
    })
    watch(selectedFilterGroup, (currGroup) => {
      if (['like', 'dislike'].includes(currGroup)) {
        selectedOption.value = currGroup;
      }
      else if (currGroup == 'random') {
        resetFilters(selectedFilterGroup, selectedOption);
        allPrograms.value = store.getters.shuffledPrograms;
        animateToFirstCard();
      }
      else if (currGroup == 'search') {
        resetFilters(selectedFilterGroup, selectedOption);
        focusKeywordSearchbar(isSearching);
      }
      else {
        if (currGroup == 'institutions') { // Mock JUPAS: first show highlight programs
          selectedOption.value = relatedInstitutions.value[0];
        }
        else if (currGroup == 'disciplines') {
          selectedOption.value = 'highlight';
          //selectedOption.value = disciplines.value[0];
        }
        nestedOption.value = 'all'; // reset filter
      }
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, checkbox, trashOutline,
      thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline,
      chevronBack, chevronForward, repeat, search,

      // variables
      user, workServices, openServiceModal,
      selectedOption, nestedOption, disciplineGroups,
      allPrograms, disciplines,
      chosenPrograms, userPrograms, tmpNewUserPrograms,
      searchKeyword, isSearching,
      delayLoading, settings,
      takenActions,

      // methods
      t, isMobileWeb,
      getProxyImgLink, openImageModal,
      closeModal, confirmSelect,
      doReorder,
      isSelected, isDisliked,
      setReaction,
      onThumbsUp: (program: any) => {
        if (props.singleSelectMode) {
          return closeModal({ selectedProgram: program }); // choose & leave
        }
        onThumbsUpItem(program, 'programId', chosenPrograms, userPrograms, tmpNewUserPrograms, getAppState())
        takenActions.value = true;
      },
      onThumbsDown: (program: any) => {
        onThumbsDownItem(program, 'programId', chosenPrograms, userPrograms, tmpNewUserPrograms, getAppState())
        takenActions.value = true;
      },
      
      filteredPrograms: () => {
        if (props.isDemo) {
          //return allPrograms.value.filter(p => [161, 158].includes(Number(p.id)));
          return allPrograms.value.filter(p => [161, 285].includes(Number(p.id)));
        }
        if (searchKeyword.value) {
          return allPrograms.value.filter(p => {
            const targetText = `${p.name.toLowerCase()} ${(p.jupasCode || '').toLowerCase()}`;
            return targetText.includes(searchKeyword.value.toLowerCase().trim());
          });
        }
        if (['like', 'dislike'].includes(selectedOption.value)) {
          return userPrograms.value.filter(ue => ue.reaction == selectedOption.value).map(ue => {
            return allPrograms.value.find(e => e.id == ue.programId); 
          });
        }
        let res = allPrograms.value;
        if (selectedOption.value == 'highlight') {
          res = allPrograms.value.filter(p => p.sortOrder != 99999).sort((a,b) => (a.sortOrder-b.sortOrder)); // featured programs
        }
        else if (selectedFilterGroup.value == 'institutions' && selectedOption.value.id) {
          res = allPrograms.value.filter(p => p.institutionId == selectedOption.value.id); // filter by institutions
          if (nestedOption.value != 'all') {
            res = res.filter(p => p.disciplines.some(d => d.disciplineGroupId.toString() == nestedOption.value)); // further filter by discipline groups
          }
        }
        else if (selectedFilterGroup.value == 'disciplines' && selectedOption.value.id) {
          res = allPrograms.value.filter(p => p.disciplines.some(d => d.disciplineGroupId.toString() == selectedOption.value.id)); // filter by discipline groups
          if (nestedOption.value != 'all') {
            res = res.filter(p => p.disciplines.some(d => d.id == nestedOption.value)); // further filter by disciplines
          }
        }
        return [
          ...res.filter(d => !isDisliked(d)),
          ...res.filter(d => isDisliked(d)),
        ];
      },

      // Selected programs (for single select mode)
      getBandLabel, getBandClass,

      // swiper
      modules: [EffectCards, Navigation, Virtual, IonicSlides],
      startIdx,
      onClickMoreBtn: () => { onClickMoreBtn(startIdx) },
      onClickPrevBtn: () => { onClickPrevBtn(startIdx) },

      // Filter groups & filters
      selectedFilterGroup, navigateMaterialCategories,
      relatedInstitutions,
      //relatedDisciplineGroups: computed(() => getDisciplineGroupsByInstitutionId(state, getters)(selectedOption.value.id)),

      getCompetitivenessColor: (ratio: any) => {
        if (ratio <= 6) {  // Bottom 35-40% - Less competitive
          return 'success';
        } else if (ratio <= 20) {  // Middle 45-60% - Moderately competitive
          return 'warning';
        } else {  // Top 15-20% - Highly competitive
          return 'danger';
        }
      },

      // For 'Careers' button
      openAIProfessionDeckModal: async (programId) => {
        const clientId = store.getters.getClientIdByProgramId(programId);
        return await openModal(ABProfessionSelectModal, { clientId, isFromProgramDeck: true });
      },
      allClientProgramIds,
    }
  }
});
