
// Vue reactivity
import { ref, defineComponent, computed, watch, reactive, onMounted } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, trashOutline, sendOutline, helpCircleOutline,
        createOutline, openOutline, addCircleOutline, chevronForwardOutline, informationCircleOutline } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonBadge,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
        IonInfiniteScroll, IonInfiniteScrollContent, IonReorder, IonReorderGroup,
        IonFab, IonFabButton,
        loadingController, modalController, alertController, } from '@ionic/vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';
import RegistrationQRCodeModal from '@/components/modals/RegistrationQRCodeModal.vue'

// services
import TeacherService from '@/services/TeacherService';

// types
import { User } from '@/types';
import config from '@/config';

export default defineComponent({
  name: 'SchoolTeacherModal',
  props: ["service", "teacherEvent", "showPhones"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonBadge,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
                IonInfiniteScroll, IonInfiniteScrollContent, IonReorder, IonReorderGroup,
                IonFab, IonFabButton, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { openModal, presentPrompt, presentToast, getQRCodeUrl, sendLinkToMyWhatsApp, } = utils();
    const { t } = useI18n();

    const user = computed(() => store.state.user);
    const nominees = ref<User[]>([]);
    const schoolTeachers = computed<User[]>(() => store.state.schoolTeachers);
    const searchKeyword = ref("");
    const selectedFilter = ref("all");

    const closeModal = async () => (await modalController.dismiss({}));

    // Teacher Events
    const getTeacherSessionResp = (s: User, targetKey = 'response') => {
      if (props.teacherEvent) {
        return (s.sessionResponses?.find(r => r.sessionId == props.teacherEvent.id) || {})[targetKey];
      }
      return null;
    }

    const onCheckNominee = (checked: any, student: any) => {
      const idx = nominees.value.findIndex(n => n.id == student.id);
      if (checked && idx == -1) nominees.value.push(student);
      else if (!checked && idx != -1) nominees.value.splice(idx, 1);
    }

    // return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack,
      trashOutline, sendOutline, helpCircleOutline, createOutline, openOutline,addCircleOutline,
      chevronForwardOutline, informationCircleOutline,

      // variables
      user,
      schoolTeachers, nominees,
      searchKeyword,
      selectedFilter,
      targetTeacherGroups: config.targetTeacherGroups,

      // methods
      t, getQRCodeUrl, sendLinkToMyWhatsApp,
      closeModal, getTeacherSessionResp, onCheckNominee,

      openRegQRCodeModal: async (event) => {
        return await openModal(RegistrationQRCodeModal, { event, targetTo: 'teacher', serviceId: props.service?.id, })
      },

      // Verifying Students
      updateUserVerificationResult: (result: any, user: User) => {
        if (user.verificationResult == result) result = null; // toggle

        TeacherService.updateUserVerificationResult(result, user.id); // update DB

        user.verificationResult = result; // update store
      },

      // Show list of teachers based on search keyword & filters
      filteredSchoolTeachers: (groupTeachers = true) => {
        let filteredTeachers: User[] = schoolTeachers.value.filter(t => t.fullName);
        if (!user.value.isAdmin) filteredTeachers = filteredTeachers.filter((s: User) => (!s.roles?.includes('admin'))); // skip admin users
        if (searchKeyword.value) {
          filteredTeachers = filteredTeachers.filter((s: User) => {
            const classRolesStr = (s.teacher?.classRoles || []).filter(cr => cr.status != 'removed').map(cr => (`${cr.role} ${cr.classes}`));
            const searchInWords = `${s.fullName} ${s.chineseName} ${s.preferredName} ${s.phone} ${s.email} ${classRolesStr}`.replace(/[^a-z0-9]/gi, "").toLowerCase();
            const cleanedKeyword = searchKeyword.value.replace(/[^a-z0-9]/gi, "").toLowerCase();
            return searchInWords.includes(cleanedKeyword);
          });
        }
        filteredTeachers = [
          ...filteredTeachers.filter(t => t.teacher?.employmentStatus == "Active"),
          ...filteredTeachers.filter(t => t.teacher?.employmentStatus != "Active"),
        ];

        if (groupTeachers) {
          const groupedObj = {};
          for (const group of config.targetTeacherGroups) groupedObj[group] = []; // init
          for (const t of filteredTeachers) {
            let key: any = "Other teachers";
            if (t.teacher?.employmentStatus != "Active") key = "No longer in school";
            else if (t.teacher?.schoolRoles?.toLowerCase().split(" , ").includes("principal")) key = "Principal";
            else if (t.teacher?.schoolRoles?.toLowerCase().includes("career")) key = "Career Teacher";
            else {
              const targetRoles = [
                "Class teacher", "Ethics and Religious Studies teacher",
                ...(config.coreSubjects.map(s => `${s} teacher`)),
              ];
              for (const cl of ["5A", "5B", "5C", "5D", "5E", "6A", "6B", "6C", "6D", "6E"]) {
                if (t.teacher?.classRoles?.some(cr => (cr.classes.includes(cl) && targetRoles.includes(cr.role) && cr.status == "active"))) {
                  groupedObj[cl] = groupedObj[cl] || [];
                  groupedObj[cl].push(t); // e.g. ERE teacher may teach multiple classes
                  key = null;
                }
              }
            }
            if (key) {
              groupedObj[key] = groupedObj[key] || [];
              groupedObj[key].push(t);
            }
          }
          return groupedObj;
        }
        return filteredTeachers;
      },

      // Update teacher role for the service
      handleRoleChange: async (ev, targetUser) => {
        const { service } = props;
        const serviceId = service?.id;
        const newRole = ev.detail.value;

        if (serviceId) {
          let relatedTeacherResp = targetUser.teacherResponses.find(tr => tr.serviceId == serviceId);
          if (!relatedTeacherResp) {
            relatedTeacherResp = { serviceId, role: "", intakeYear: service?.currIntakeYear, };
            targetUser.teacherResponses.push(relatedTeacherResp);
          }
          const oldRole = relatedTeacherResp.role || "";
          relatedTeacherResp.role = null;
          relatedTeacherResp.role = oldRole; // prevent select UI changing value
        }

        //presentPrompt("Confirm update?", async () => {
        const loading = await loadingController.create({ });
        await loading.present();

        const newEmploymentStatus = ["Inactive", "Retired"].includes(newRole) ? newRole : "Active";
        await TeacherService.updateTeacher(targetUser.id, { employmentStatus: newEmploymentStatus });

        // Sync latest employment status of target teacher
        if (targetUser.teacher) targetUser.teacher.employmentStatus = ["Inactive", "Retired"].includes(newRole) ? newRole : "Active";
        store.commit('upsertSchoolTeachers', [targetUser]);

        // Upsert teacher response
        /*relatedTeacherResp.role = newRole;
        relatedTeacherResp.assignedTo = targetUser.id;
        relatedTeacherResp.eventName = service.name;
        const responseObj = await TeacherService.upsertTeacherResponse(null, serviceId, relatedTeacherResp, null, null);
        for (const key in responseObj) relatedTeacherResp[key] = responseObj[key];

        // update store teacher response for current user
        if (targetUser.id == user.value.id) store.commit('upsertTeacherResponse', responseObj);*/

        loading.dismiss();
      },
      getTeacherServiceRole: (user: any) => {
        /*
        if (user.teacher?.employmentStatus == "Active") {
          const serviceId = props.service.id;
          let relatedTeacherResp = user.teacherResponses.find(tr => tr.serviceId == serviceId);
          if (!relatedTeacherResp) {
            relatedTeacherResp = { serviceId, role: "" };
            user.teacherResponses.push(relatedTeacherResp);
          }
          return relatedTeacherResp ? relatedTeacherResp.role : ""; // default is nominator / not related?
        }
        */
        return user.teacher?.employmentStatus;
      },

      submitNomination: (schoolTeachers: any, nominees: User[]) => {
        const nomineeDescriptions = nominees.map(n => `${n.fullName} (${n.teacher?.schoolRoles})`);
        presentPrompt(`Confirm nomination?<br /><br />${nomineeDescriptions.sort().join("<br />")}`, async () => {
          const loading = await loadingController.create({ duration: 60000 });
          await loading.present();

          const { id : sessionId } = props.teacherEvent;

          // Insert nomination record
          const reqNominees = nominees.map(({ id, fullName, phone, teacher }) => {
            return { id, fullName, phone, schoolId: store.state.user.schoolId, schoolRoles: teacher?.schoolRoles }; // minimize the payload
          });
          await TeacherService.insertNominationRecord(reqNominees, [sessionId]);


          // Update corresponding session event response (nominatedBy)
          for (const n of nominees) {
            const sessionResp = n.sessionResponses?.find(resp => resp.sessionId == sessionId);
            if (sessionResp) {
              // update nominator of existing response
              sessionResp.nominatedBy = store.state.user.id;
            } else {
              // insert new session response
              (n.sessionResponses = n.sessionResponses || []).push({
                sessionId: sessionId,
                userId: n.id,
                response: "Yes",
                confirmed: "Yes",
                nominatedBy: store.state.user.id
              });
            }
          }

          // Upsert school teacher
          store.commit('upsertSchoolTeachers', nominees);

          // Present toast msg (ack)
          presentToast(`Thank you for your nomination!`);

          alertController.dismiss();
          loading.dismiss();
        })
      }

    }
  },
});
