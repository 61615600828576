import firebase from "firebase/compat/app";
import 'firebase/compat/functions';
import { uploadImage, unpackJSONHData, } from '@/services/utils';

export default {
  async getAppPublicData() {
    return (await firebase.app().functions('asia-east2').httpsCallable('getAppData')({ type: 'public' })).data;
  },
  async getPortalData(schoolId: any = null, programId: any = null) {
    return unpackJSONHData((await firebase.app().functions('asia-east2').httpsCallable('getAppData')({ type: 'portal', schoolId, programId })).data);
  },
  async getStep1Questions(schoolId: any = null, programId: any = null) {
    return (await firebase.app().functions('asia-east2').httpsCallable('getAppData')({ type: 'step1Questions', schoolId, programId })).data;
  },
  async getSlideImageUrl(presentationLink) {
    return (await firebase.app().functions('asia-east2').httpsCallable('getSlideImageUrl')({ presentationLink })).data;
  },

  async createNewFeedback(name: any, email: any, feedback: any, photoFile: any = null) {
    let photoLink = ""; // image URL
    if (photoFile) {
      photoLink = await uploadImage(photoFile, name);
    }
    return await firebase.app().functions('asia-east2').httpsCallable('createNewFeedback')({ name, email, feedback, photoLink });
  },
}