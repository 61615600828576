import firebase from "firebase/compat/app";
import 'firebase/compat/functions';

export default {
  async updateTeacher(user, payload = {}) {
    const { id: userId, phone: userPhone, waGroupId: userWAGrpId } = user;
    return (await firebase.app().functions('asia-east2').httpsCallable('teacher-updateTeacher')({ userId, userPhone, userWAGrpId, ...payload })).data;
  },

  async getUsersBySchoolId(schoolId: any, isAdmin: any = null) {
    return (await firebase.app().functions('asia-east2').httpsCallable('teacher-getUsersBySchoolId')({ schoolId, isAdmin })).data;
  },

  async getSchoolMockJUPASResults(schoolId: any, yearDSE: any = null, bandOrders: any = null) {
    return (await firebase.app().functions('asia-east2').httpsCallable('teacher-getSchoolMockJUPASResults')({ schoolId, yearDSE, bandOrders })).data;
  },

  async updateUserVerificationResult(verificationResult: any, userId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('teacher-updateUserVerificationResult')({ verificationResult, userId })).data;
  },

  async insertNominationRecord(targetUsers: any, targetSessionIds: any, targetResponse: any = 'Yes') {
    return (await firebase.app().functions('asia-east2').httpsCallable('user-insertNominationRecord')({ targetUsers, targetSessionIds, targetResponse })).data;
  },

  async upsertTeacherResponse(targetEvent: any, serviceId: any, teacherResponse: any, phone: any, schoolId: any = null, userId: any = null, questionAnswers: any = []) {
    return (await firebase.app().functions('asia-east2').httpsCallable('teacher-upsertTeacherResponse')({
      targetEvent, serviceId, teacherResponse, phone, schoolId, userId, questionAnswers,
    })).data;
  },

  async sendRegQRCodeToSelfWhatsAppGrp(url: any, userPhone: any, userWAGrpId: any, posterImgLink = "", videoLink = "", slideLink = "") {
    return (await firebase.app().functions('asia-east2').httpsCallable('teacher-sendRegQRCodeToSelfWhatsAppGrp')({ url, userPhone, userWAGrpId, posterImgLink, videoLink, slideLink, })).data;
  }
}