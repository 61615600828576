
// Vue reactivity
import { ref, defineComponent, computed, onMounted, watch, reactive, onBeforeUnmount, } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, checkbox, trashOutline,
         thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline,
         pencil, search, eyeOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonReorder, IonReorderGroup,
        IonList, IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonTextarea, IonBadge, IonCheckbox,
        IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonAccordionGroup, IonAccordion,
        modalController, } from '@ionic/vue';
import ABDisciplineSelectModal from '@/components/pss/ABDisciplineSelectModal.vue';
import ResultPageItemList from '@/components/shared/ResultPageItemList.vue';

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';
import { Discipline, Program, UserDiscipline, } from '@/types';

// services
import ABSService from '@/services/ABSService';

export default defineComponent({
  name: 'AB3DisciplineResultList',
  props: ["selectedPrograms", "readOnly"],
  emits: ['addProgramToChoices', 'unselectProgram', 'selectedDisciplinesUpdated'],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonReorder, IonReorderGroup,
                IonList, IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonTextarea, IonBadge, IonCheckbox,
                IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonAccordionGroup, IonAccordion,
                ResultPageItemList, },
  setup(props, { emit }) {
    // methods or filters
    const store = useStore();
    const { closeModal, doReorder, openModal, syncChosenItems, linkNestedChosenItems, processUserItems,
            getBandClass, getBandLabel, getDisciplineGroupColor, } = utils();
    const { t } = useI18n();

    const user = computed(() => store.state.user);
    const allPrograms = ref<Program[]>(store.state.allPrograms);

    const allDisciplines = ref<Discipline[]>(store.state.allDisciplines);
    const selectedDisciplines = ref<Discipline[]>([]);
    const userDisciplines = ref<UserDiscipline[]>([]);
    
    const syncUserDisciplinesToDB = async (commitUserDisciplines = false) => {
      // Save User Selected Programs & Electives
      userDisciplines.value = processUserItems(selectedDisciplines.value, userDisciplines.value, [], 'disciplineId', user.value.id);
      const data = {
        "selectedDisciplines": selectedDisciplines.value,
        "userDisciplines": userDisciplines.value,
      }
      try {
        const { userDisciplines, } = data;
        ABSService.upsertUserDisciplines(userDisciplines);
        if (commitUserDisciplines) store.commit('updateUser', { userDisciplines });
      } catch (e) {
        console.error(e);
      }
      return data;
    }
    const confirmSelect = async (noLoading = false) => {
      closeModal({
        "selectedDisciplines": selectedDisciplines.value, noLoading,
        "userDisciplines": userDisciplines.value,
      }); // return selected items & order here
      syncUserDisciplinesToDB(true);
    };

    onMounted(() => {
      // Load previous choices
      const oldUserDisciplines = JSON.stringify((user.value.userDisciplines || []).sort((a,b) => Number(a.order)-Number(b.order)));
      userDisciplines.value = JSON.parse(oldUserDisciplines || '[]');

      // Prefill with previously written reasons
      syncChosenItems('disciplineId', selectedDisciplines, userDisciplines, allDisciplines.value);
      emit('selectedDisciplinesUpdated', selectedDisciplines.value);
    })

    onBeforeUnmount(() => {
      syncUserDisciplinesToDB(true);
    })
    
    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, checkbox, trashOutline,
      thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline, pencil, search, eyeOutline,

      // variables
      selectedDisciplines, userDisciplines,

      // methods
      t,
      closeModal, confirmSelect,
      doReorder,
      doReorderDiscipline: (event: CustomEvent, targetArr: any) => {
        doReorder(event, targetArr);
        syncUserDisciplinesToDB(false);
      },
      openABDisciplineSelectModal: async () => {
        const modal = await modalController.create({
          cssClass: 'tall-modal',
          component: ABDisciplineSelectModal, 
          componentProps: {
            prefilledDisciplines: selectedDisciplines.value.slice(),
            oldUserDisciplines: userDisciplines.value.slice() || [],
            fromAB3Modal: true,
            readOnly: props.readOnly, // parent's view: read-only
          }
        });
        modal.onDidDismiss().then(({ data }) => {
          if (data && data.chosen) {
            selectedDisciplines.value = data.chosen;
            userDisciplines.value = data.userDisciplines;
            syncUserDisciplinesToDB(true);
          }
        });
        return modal.present();
      },
      onDeleteChosenDiscipline: (idx, discipline) => {
        selectedDisciplines.value.splice(idx, 1);
        const relatedUserItem = userDisciplines.value.find(us => us.disciplineId == discipline.id);
        if (relatedUserItem) relatedUserItem.reaction = '';
        syncUserDisciplinesToDB();
      },

      /**
       * Get programs related to specific disciplines
       */
      getDisciplineRelatedPrograms: (discipline, returnGroupedObj) => {
        const res = allPrograms.value.filter(p => p.status != 'draft' && p.programType == 'jupas' && p.disciplines.some(d => d.id == discipline.id))
                              .slice().sort((a: any, b: any) => (a.jupasCode < b.jupasCode ? -1 : 1)); // Sort by JUPAS code
        const groupedObj = {};
        for (const p of res) {
          const key: any = p.institutionNameShort || "Other";
          groupedObj[key] = groupedObj[key] || [];
          groupedObj[key].push(p);
        }
        return returnGroupedObj ? groupedObj : res;
      },
      onCheckProgram: (program) => {
        if (props.selectedPrograms.find(p => p.id == program.id)) {
          emit('unselectProgram', program); // Link back to AchieveJUPASResultPageModal
        } else {
          emit('addProgramToChoices', program); // Link back to AchieveJUPASResultPageModal
        }
      },
      selectedDisciplinesUpdated: () => {
        emit('selectedDisciplinesUpdated', selectedDisciplines.value.slice());
      },
      getProgramIdxInList: (p) => {
        return props.selectedPrograms.findIndex(program => program.id == p.id);
      },
      getNumSelectedPrograms: (programs) => {
        return programs.filter(p => (props.selectedPrograms.find(program => program.id == p.id))).length;
      },
      getBandClass, getBandLabel, getDisciplineGroupColor,
    }
  }
});
