
import { computed } from 'vue';
import config from '@/config';
import { useStore } from '@/store';

export default {
  props: [
    "withText",
    "useWhiteLogo",
  ],
  setup() {
    const store = useStore();
    const currDark = computed(() => store.state.user.darkTheme);

    const { logo, logoWithText, logoWithTextWhite, logoWhite } = config;
    return { logo, logoWithText, logoWithTextWhite, logoWhite, currDark }
  }
}
