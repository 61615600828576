
// Vue reactivity
import { computed, defineComponent, onMounted, ref } from 'vue';

// components
import { IonItem, IonLabel, IonList, IonListHeader, IonCheckbox, IonAccordion, IonAccordionGroup,
        IonCard, IonCardContent, IonSpinner, } from '@ionic/vue';
import SectorModal from '@/components/pss/SectorModal.vue';

// composables
import { utils } from '@/composables/utils';
import { useStore } from '@/store';
import PortalService from '@/services/PortalService';

// types
import { Profession } from '@/types';

export default defineComponent({
  props: {
    profession: Object as () => Profession,
    relatedSectors: null,
    overrideSegments: null,
    expandAlumniSection: null,
    gptExplanationHTML: null,
  },
  components: { IonItem, IonLabel, IonList, IonListHeader, IonCheckbox,
                IonAccordion, IonAccordionGroup, IonCard, IonCardContent, IonSpinner, },
  setup(props) {
    const store = useStore();
    const cvcl = ref(null);
    const professionExtraInfo = ref<any>(null);
    const loadingExtraInfo = ref(true);
    const currProgram = computed(() => store.state.currProgram); // for getting progam alumni contacts

    const { openModal } = utils();

    const encodeGoogleJobsURI = (profession: Profession) => {
      return `https://www.google.com/search?q=${encodeURIComponent(profession.name)}+fresh+graduate&ibp=htl;jobs`;
    }

    const openSectorModal = async (sectorId: any, segmentId = '') => {
      return await openModal(SectorModal, { sectorId, segmentId })
    };

    onMounted(() => {
      const { profession, } = props;
      if (profession) {
        const { id, extraInfo, fetchedExtraInfo, extraInfoGroupId } = profession;
        if (!fetchedExtraInfo) {
          PortalService.getExtraInfosByGroupId(extraInfoGroupId).then(extraInfo => {
            store.commit('setProfessionExtraInfo', { id, extraInfo });
            professionExtraInfo.value = extraInfo;
            loadingExtraInfo.value = false;
          });
        } else {
          professionExtraInfo.value = extraInfo;
          loadingExtraInfo.value = false;
        }
        PortalService.getCvCl(profession.id).then(res => {
          cvcl.value = res;
        });
      }
    })

    return {
      // variables
      cvcl, professionExtraInfo, loadingExtraInfo,
      currProgram,

      // methods
      encodeGoogleJobsURI, openSectorModal,
      moreProfessionExtraInfo: (profession: any) => {
        const searchName = profession?.name.toLowerCase().replace(/ /g, '-') || ""; 
        const glassdoorLink = `https://www.glassdoor.com.hk/Salaries/${searchName}-salary-SRCH_KO0,${searchName.length}.htm`;
        const payscaleLink = `https://www.payscale.com/rcsearch.aspx?category=Job&str=${encodeURIComponent(profession.name)}&CountryName=Hong+Kong`;
        return {
          'Salary': [
            { name: "fr Payscale", content: payscaleLink },
            { name: "fr Glassdoor", content: glassdoorLink },
          ].concat(professionExtraInfo.value['Salary'] || []),
          'Awards': professionExtraInfo.value['Awards'] || [],
        }
      },
      getEmbeddedYTLink: (link) => {
        const matches = link.match(/.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/i);
        return matches ? `https://www.youtube.com/embed/${matches[1]}` : link;
        // https://www.youtube.com/embed/2JyW4yAyTl0?autoplay=1&modestbranding=1
      },
      getFilteredAlumniContactRelations: () => {
        const { alumniContactRelations } = currProgram.value;
        return (alumniContactRelations || []).filter(cr => cr.entityType == 'profession' && cr.entityId == props.profession?.id);
      }
    }
  }
})
