export default {
  zh: {
    profile: '帳戶',
    notifications: '通知',
    settings: '設定',

    yes: '是',
    no: '否',
    back: '返回',
    confirm: '確定',
    cancel: '取消',
    all: '全部',
    searchOrAdd: '搜尋或新增',
    search: '搜尋',

    remark: '備註',
    submit: '提交',
    confirmLeave: '確認離開？',

    errorHeader: '錯誤！',

    save: '儲存',
    successSave: '已成功儲存',
    photo: '相片',

    myCredentials: '我的履歷',
    careerPlan: '職涯規劃',
    myDream: '我的夢想',
    training: '上課',

    sectionOne: '第一部分',
    sectionTwo: '第二部分',
    surveyResults: '結果',

    prev: '上一步',
    next: '下一步',
    startAgain: '重新開始',

    startExplore: '開始探索',

    schoolEmail: '你的學校內聯網電郵地址',
    fullName: '您的英文全名',
    preferredName: '暱稱',
    chineseName: '中文姓名',
    class: '班別',
    studentNumber: '你的學號',
    school: '就讀學校名稱',
    phone: '手機號碼',
    password: '登入密碼',
    confirmPassword: '確認登入密碼',
    confirmSubmit: '確認提交?',
    confirmSave: '確認儲存?',
    
    successUpdatePersonalInfo: '已成功更新個人資料。',
    clashEmailAddress: '此電子郵件地址已被使用。',
    editMobileNumber: '更改手機號碼',

    LoginPage: {
      login: '登入',
      password: '密碼',
      loginEmail: '手機號碼 / 電郵地址',
      loginWithVerificationCode: '手機驗證碼登入',
    },
    RegisterPage: {
      registerAccount: '註冊帳號',
      register: '註冊',
      inconsistentPasswordMsg: '兩次輸入的密碼不一致，請重新輸入。',
      enterEmailAndPassword: '請輸入電郵及密碼。',
      email: '電郵地址',
      enterPhoneAndPassword: '請輸入手機號碼。',
      enterValidHKMobileNumber: '請輸入有效的香港手機號碼 (8位數字)',
      verifyingMobileNumber: '驗證閣下的手機號碼',
      verifyMobileNumberInstruction: '請輸入短訊驗證碼，驗證碼已發送至您的手機',
      verificationCode: '短訊驗證碼',
      invalidVerificationCode: '無效短訊驗證碼！',
      verify: '驗證',
      registeringAccount: '設定帳戶中...',
      unregisteredPhone: '輸入的手機號碼尚未登記，請再試一次。',
      phoneRegistered: '此手機號碼已被註冊',
      notApplyJobEXSubmitted: '成功提交表格，你現在可以關閉這個頁面。',
    },
    ForgotPasswordPage: {
      loginEmail: '登入電郵',
      forgotPassword: '忘記密碼',
      sendResetPasswordEmail: '發送重設密碼電郵',
      resetPswEmailSentTo: '重設密碼電郵已發送至',
      checkEmailReset: '請檢查您的郵箱並透過電郵內的連結重設登入密碼。',
      backToLogin: '返回登入頁面',
      enterLoginEmail: '請輸入登入電郵。',
    },
    UserProfilePage: {
      changeProfilePhoto: '更換頭像',
      successChangeProfilePhoto: '個人頭像更換成功！',
      editPersonalInfo: '修改個人資料',
      successUpdatePersonalInfo: '已成功更新個人資料。',
      changePassword: '更改密碼',
      oldPassword: '現有密碼',
      newPassword: '新密碼',
      newPasswordConfirm: '確認新密碼',
      inconsistentNewPasswordMsg: '兩次輸入的新密碼不一致，請重新輸入。',
      wrongOldPassword: '現有密碼輸入錯誤，請重新輸入。',
      successUpdatePassword: '成功更改密碼！',
      enterAllFields: '請輸入所有欄位。',
      myOrders: '訂單紀錄',
      myPosts: '發帖紀錄',
      likedItems: '喜愛產品',
      language: '語言',
      darkTheme: '深色主題',
      termsAndConditions: '責任及條款',
      feedback: '意見專區',
      logout: '登出',
      confirmLogout: '確定登出？',
      
      fullName: '您的英文全名',
      preferredName: '暱稱',
      emailAddress: '電郵地址',
      phone: '手機號碼',
      save: '儲存',
    },
    FeedbackPage: {
      contactInfo: '聯絡資料',
      nickname: '您的名字',
      enterNickname: '請輸入您的名字',
      email: '您的電郵',
      enterEmail: '請輸入您的電郵',
      feedback: '意見反饋',
      enterFeedback: '在此輸入您的意見',
      feedbackSubmitted: '意見提交成功！',
    },
  },
  en: {
    profile: 'Profile',
    notifications: 'Notifications',
    settings:'Settings', 

    yes: 'Yes',
    no: 'No',
    back: 'Back',
    confirm: 'Confirm',
    cancel: 'Cancel',
    all: 'All',
    search: 'Search',
    searchOrAdd: 'Search / Add New',

    remark: 'Remark',
    submit: 'Submit',
    confirmLeave: 'Confirm leaving this page?',

    errorHeader: 'ERROR!',
    
    save: 'Save',
    successSave: 'Saved Successfully.',
    photo: 'Photo',

    myCredentials: 'My Profile',
    careerPlan: 'Educated Job Seeking',
    myDream: 'My dream',
    training: 'Training',

    sectionOne: 'Section 1',
    sectionTwo: 'Section 2',
    surveyResults: 'Results',

    prev: 'Prev',
    next: 'Next',
    startAgain: 'Start Again',

    fullName: 'Your full name in English',
    preferredName: 'Preferred Name',
    chineseName: 'Chinese Name',
    schoolEmail: 'Your Email Address in School Intranet',
    phone: 'Mobile Number',
    class: 'Class',
    studentNumber: 'Your student number in the class',
    school: 'School Name',
    password: 'Login Password',
    confirmPassword: 'Confirm Login Password',
    confirmSubmit: 'Confirm Submit?',
    confirmSave: 'Confirm Save?',

    successUpdatePersonalInfo: 'Personal information updated successfully.',
    clashEmailAddress: 'The email address is already in use by another account.',
    wrongPassword: 'Login information is incorrect.',
    editMobileNumber: 'Edit Mobile Number',
    
    startExplore: 'Start Explore',

    LoginPage: {
      login: 'Login',
      password: 'Password',
      loginEmail: 'Mobile Number / Email',
      loginWithVerificationCode: 'Log in with SMS Verification Code',
    },
    RegisterPage: {
      registerAccount: 'Register',
      register: 'Register',
      enterEmailAndPassword: 'Please enter both the email address and password.',
      inconsistentPasswordMsg: 'Passwords do not match. Please enter again.',
      email: 'Email',
      enterPhoneAndPassword: 'Please enter your HK mobile number.',
      enterValidHKMobileNumber: 'Please enter a valid Hong Kong mobile number (8 digits)',
      verifyingMobileNumber: 'Verify your mobile number',
      verifyMobileNumberInstruction: 'Please enter the verification code that was sent to your mobile device',
      verificationCode: 'Verification Code',
      invalidVerificationCode: 'Invalid verification code!',
      verify: 'Verify',
      registeringAccount: 'Setting up account...',
      unregisteredPhone: 'The number is not registered, please try again',
      phoneRegistered: 'This phone has already been registered',
      notApplyJobEXSubmitted: 'Form submitted sucessfully. You may close this page now.',
    },
    ForgotPasswordPage: {
      loginEmail: 'Login Email',
      forgotPassword: 'Forgot Password',
      sendResetPasswordEmail: 'Send Reset Password Email',
      resetPswEmailSentTo: 'Email has been sent to',
      checkEmailReset: 'Please reset the password by clicking the link in the email.',
      backToLogin: 'Back to Login',
      enterLoginEmail: 'Please enter the login email',
    },

    UserProfilePage: {
      changeProfilePhoto: 'Change Profile Photo',
      successChangeProfilePhoto: 'Profile photo changed successfully!',
      editPersonalInfo: 'Edit Personal Information',
      successUpdatePersonalInfo: 'Personal information updated successfully!',
      changePassword: 'Change Password',
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      newPasswordConfirm: 'Confirm New Password',
      inconsistentNewPasswordMsg: 'New passwords do not match. Please enter again.',
      wrongOldPassword: 'The old password is wrong. Please enter again.',
      successUpdatePassword: 'Password updated successfully!',
      enterAllFields: 'Please enter all fields.',
      myOrders: 'My Orders',
      myPosts: 'My Posts',
      likedItems: 'Liked Items',
      language: 'Language',
      darkTheme: 'Dark Theme',
      termsAndConditions: 'Terms and Conditions',
      feedback: 'Feedback',
      logout: 'Log Out',
      confirmLogout: 'Confirm log out?',
      
      fullName: 'Your full name in English',
      preferredName: 'Preferred Name',
      emailAddress: 'Email Address',
      phone: 'Phone',
      save: 'Save',
    },
    FeedbackPage: {
      contactInfo: 'Contact Information',
      nickname: 'Your name',
      enterNickname: 'Enter your name',
      email: 'Your email',
      enterEmail: 'Enter your email',
      feedback: 'Feedback',
      enterFeedback: 'Enter your feedback here',
      feedbackSubmitted: 'Feedback submitted successfully!',
    },
  }
}