
// Vue reactivity
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonBackButton, IonIcon,
        IonCard, IonCardHeader, IonCardContent, IonLabel, IonGrid, IonFooter, IonSegment, IonSegmentButton,
        IonList, IonListHeader, IonItem, IonRadioGroup, IonRadio, IonNote, IonInput, IonDatetime,
        IonModal, IonChip, IonSelect, IonSelectOption, IonTextarea, IonAccordionGroup, IonAccordion,
        modalController, loadingController, alertController, } from '@ionic/vue';
import TeacherResponseQuestions from '@/components/teacher/TeacherResponseQuestions.vue';
import SchoolStudentModal from '@/components/teacher/SchoolStudentModal.vue';
import SchoolTeacherModal from '@/components/teacher/SchoolTeacherModal.vue';
import SLPModal from '@/components/secondary/ABSLPModal.vue';
import Slides from '@/components/shared/Slides.vue';
import AchieveJUPASResultPageModal from '@/components/achievejupas/AchieveJUPASResultPageModal.vue';
import ABProfessionSelectModal from '@/components/pss/profession/ABProfessionSelectModal.vue';
import LoginPage from '@/pages/LoginPage.vue';

// composables
import { useStore } from '@/store';
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';

// icons
import { checkmark, arrowBack, alertCircleOutline, } from 'ionicons/icons';

// types
import { Client, Service, Session, TeacherResponseOption, User, } from '@/types';

// services
import EventService from '@/services/EventService';
import TeacherService from '@/services/TeacherService';

export default defineComponent({
  props: ["serviceId", "event", "userId", "showActionBtns",
          "showUnsubscribePrompt", "showApplyPrompt", "isSurvey",
          "showConfirmPrompt", "showWithdrawPrompt", "hideTeacherResponse",
          "prefilledOption", "isModal", "hideHeader", "isSecondaryStudentView",
          "isClientView"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonBackButton, IonIcon,
                IonCard, IonCardHeader, IonCardContent, IonLabel, IonGrid, IonFooter, IonSegment, IonSegmentButton,
                IonList, IonListHeader, IonItem, IonRadioGroup, IonRadio, IonNote, IonInput, IonDatetime, IonModal, IonChip,
                IonSelect, IonSelectOption, IonTextarea, IonAccordionGroup, IonAccordion,
                TeacherResponseQuestions, SchoolStudentModal, Slides, },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const { openImageModal, presentPrompt, promptWithdrawReason, formatDate, getProxyImgLink, getLinkifiedText,
            sendLinkToMyWhatsApp, openBrowser, openModal, sleep, closeModal, copyText, htmlToPlainText, presentAlert,
            getF4YearDSE, getF5YearDSE, presentToast, getSecStudentForm, uniqueId, } = utils();

    const relatedEvents = computed<Session[]>(() => store.getters.getRelatedEvents(props.event?.id));
    const selectedService = computed<Service>(() => store.getters.getServiceById(props.serviceId));
    const relatedClient = computed<Client>(() => store.getters.getClientById(selectedService.value.clientId || props.event?.clientId));
    const allSessions = computed<Session[]>(() => store.state.sessions);
    const serviceSessions = computed<Session[]>(() => store.getters.getApplicationSessionsByServiceId(props.serviceId));
    const workshopSessions = computed<Session[]>(() => store.getters.getApplicationSessionsByServiceId(props.serviceId, 'work-workshop-2'));
    const slpSession = computed<Session>(() => store.getters.getSLPSessionByServiceId(props.serviceId));
    const user = computed<User>(() => store.state.user);
    const userSchoolRoles = computed(() => (user.value.teacher?.schoolRoles || "").split(" , ").filter(r => r));
    const firstSchoolRole = computed(() => userSchoolRoles.value[0] || "");
    const relatedSubject = computed(() => {
      const schoolRole = store.state.schoolRoles.find(r => r.title === firstSchoolRole.value);
      return schoolRole?.relatedSubject ? `${schoolRole.alias}-related` : '';
    });
    const schoolTeachers = computed<User[]>(() => store.state.schoolTeachers); // for getting responses of other teachers
    const isAckModalOpened = ref(false);
    const circularAccordion = ref(null);

    // For class-based response options
    const teacherResponseOptions = computed<TeacherResponseOption[]>(() => store.state.teacherResponseOptions);
    const OPTION_IDS = {
      playedVideo: 'o-7f6e61d0',
      pubInOtherMeans: 'o-02bd98ac',
      bookTime: 'o-6858aa15',
      findingTime: 'o-08a9ae49',
      skip: 'o-e8602212',
    };
    const bookTimeData = reactive({
      isModalOpened: false,
      targetClass: "",
      prefilledOptionId: OPTION_IDS.bookTime,
    });

    // Event Response (for students)
    const getActionVerb = (response) => {
      switch (response) {
        case 'Yes':
          return 'Apply for';
        case 'Confirmed':
          return 'Confirm to attend';
        case 'No':
          return 'Cancel your application for';
        case '':
          return 'Subscribe';
        case 'Unsubscribed':
          return 'Stop receiving the WhatsApp messages from'
        default:
          return response;
      }
    }
    const updateEventResponse = async (targetEvent, response, withdrawReason = null, workshopTimePreference = null) => {
      const { phone, schoolId, currIntakeId } = user.value;
      const loading = await loadingController.create({ duration: 15000 });
      await loading.present();
      const responseObj = await EventService.updateEventResponse(targetEvent, response, phone, schoolId, currIntakeId, undefined, undefined, withdrawReason, workshopTimePreference);
      store.commit('injectUserEventResponse', responseObj);
      loading.dismiss();
    }
    const promptApplyWorkshopSessions = async (nominationSession) => {
      // Retrieve work-workshop-2 session for time preference indication
      const inputs: any = workshopSessions.value.map(s => ({
        name: 'targetSessionId',
        label: s.formattedDateTime,
        type: 'radio',
        value: s,
      }));
      const alert = await alertController.create({
        header: `Choose your preferred date & time`,
        inputs,
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: t('submit'),
            handler: (value) => {
              if (value) {
                updateEventResponse(nominationSession, "Yes", null, value.formattedDateTime);
                presentToast("Thank you for your application.");
              } else {
                return false;
              }
            }
          }
        ]
      });
      await alert.present();
    }
    const promptUpdateEventResponse = async (targetEvent: Session, response: any) => {
      let msg = `${getActionVerb(response)} <b>${targetEvent.name}</b>`;
      if (targetEvent.startTime) msg += ` on <b>${targetEvent.formattedDateTime}</b>?`;
      if (response == "No") {
        await promptWithdrawReason(msg, async (reason: any) => {
          await updateEventResponse(targetEvent, response, reason); // Ask for withdrawal reason
        });
      }
      else if (response == "Yes" && ['work-workshop-nomination'].includes(targetEvent.anchorEventId)) {
        await promptApplyWorkshopSessions(targetEvent); // Select time preference (workshops)
      }
      else {
        await presentPrompt(msg, () => {
          updateEventResponse(targetEvent, response); // SLP: direct apply (no interview required)
        });
      }
    }

    // INIT
    onMounted(() => {
      const { event, showUnsubscribePrompt, showApplyPrompt, showConfirmPrompt, showWithdrawPrompt, } = props;
      if (showUnsubscribePrompt) {
        setTimeout(() => {
          promptUpdateEventResponse(event, 'Unsubscribed');
        }, 200);
      }
      /* Not suitable to prompt on enter (users may want to read details first before applications)
      else if (showApplyPrompt) {
        if (user.value.isAdmin || !user.value.teacher) {
          setTimeout(() => {
            if (event.userResponse?.response != 'Confirmed') {
              promptUpdateEventResponse(event, 'Yes');
            }
          }, 200);
        }
      }
      */
      else if (showConfirmPrompt) {
        setTimeout(() => {
          promptUpdateEventResponse(event, 'Confirmed');
        }, 200);
      }
      else if (showWithdrawPrompt) {
        setTimeout(() => {
          promptUpdateEventResponse(event, 'No');
        }, 200);
      }
    });

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      checkmark, arrowBack, alertCircleOutline,

      // variables
      user,
      selectedService, serviceSessions,
      relatedClient,
      isAckModalOpened,
      relatedSubject,

      // methods
      getLinkifiedText, htmlToPlainText,
      getF4YearDSE, getF5YearDSE,
      closeModal, copyText,
      formatDate, getProxyImgLink, openImageModal,
      sendSlideToMyWhatsApp: async () => {
        const { posterLink, videoLink, slideLink, } = selectedService.value;
        const { phone, waGroupId } = user.value;
        const loading = await loadingController.create({});
        await loading.present();
        sendLinkToMyWhatsApp("", phone, waGroupId, posterLink, videoLink, slideLink, false);
        await sleep(2);
        loading.dismiss();
        isAckModalOpened.value = true;
      },
      openServiceSlideLink: () => {
        const { slideLink, posterLink } = selectedService.value;
        if (slideLink && slideLink.includes("fdmt.hk/")) {
          openBrowser(selectedService.value.slideLink);
        } else {
          openImageModal(getProxyImgLink(posterLink));
        }
      },
      openSchoolTeacherModal: () => {
        openModal(SchoolTeacherModal, { service: selectedService.value, });
      },

      // Event application
      relatedEvents,
      promptUpdateEventResponse,

      // Prompt apply service sessions (check if contains work-workshop-nomination)
      promptApplyServiceSessions: async () => {
        if (user.value.teacher && !user.value.isSecondaryStudent) {
          presentAlert("Only students can apply for this.");
          return false;
        }
        if (props.serviceId == 'lms-scholarship') {
          // Special handling: LMS scholarship
          return await presentPrompt("Confirm apply?", () => {
            updateEventResponse(serviceSessions.value[0], "Yes");
          });
        }
        const nominationSession = serviceSessions.value.find(s => s.anchorEventId == 'work-workshop-nomination');
        if (nominationSession) {
          // Workshop sessions (select time preference)
          await promptApplyWorkshopSessions(nominationSession);
        }
        else {
          const sessions = serviceSessions.value.filter(s => s.anchorEventId == selectedService.value.anchorEventId);
          //const sessions = workshopSessions.value.length > 0 ? workshopSessions.value : serviceSessions.value;
          // Normal work sessions (e.g. Work SLP): Show all upcoming sessions for selection
          const inputs: any = sessions.map(s => ({
            name: 'targetSessionId',
            label: s.formattedDateTime,
            type: 'radio',
            value: s,
          }));
          const alert = await alertController.create({
            header: `Choose your preferred date & time`,
            inputs,
            buttons: [
              {
                text: t('cancel'),
                role: 'cancel',
                cssClass: 'secondary',
              }, {
                text: t('submit'),
                handler: (value) => {
                  if (value) {
                    updateEventResponse(value, "Yes");
                    presentToast("Thank you for your application.")
                  } else {
                    return false;
                  }
                }
              }
            ]
          });
          await alert.present();
        }
      },

      // Get applied event (direct access / via service details)
      appliedEvent() {
        if (serviceSessions.value.find(s => s.anchorEventId == 'work-workshop-nomination')) {
          return workshopSessions.value.find(s => s.userResponse?.confirmed == 'Yes'); // confirmed workshop session
        }
        return props.event || serviceSessions.value.find(s => s.userResponse?.confirmed == 'Yes');
      },

      // Student event tool support (mainly for demo)
      slpSession, openSLPModal: async () => {
        const { serviceId } = props;
        const ev = slpSession.value;
        return await openModal(SLPModal, { ev, serviceId, relatedProgramId: ev?.relatedProgramId, relatedClientId: selectedService.value.clientId, }, "", false)
      },

      // Work Service (enable complicated steps)
      isWorkEvent: () => { // events for publicization / nomination only
        const { type, id, isAllowProposeDates, } = selectedService.value;
        return (id == 'ucircle' || type == 'Work') && (props.event || !isAllowProposeDates);
      },
      circularAccordion,
      isCompletedServiceAction: (actionTitle) => {
        // Check if teacher completed the action
        const { id: serviceId, currIntakeYear: intakeYear } = selectedService.value;
        return user.value.teacherResponses?.find(r => r.serviceId == serviceId && r.response == actionTitle && r.type == 'action' && (!intakeYear || r.intakeYear == intakeYear));
      },

      // Logs (responses / proposed sessions)
      recentResponses: () => {
        const { id: serviceId, currIntakeYear: intakeYear } = selectedService.value;
        return (user.value.teacherResponses || []).filter(r => r.serviceId == serviceId && (!intakeYear || r.intakeYear == intakeYear));
      },
      schoolProposedSessions: () => {
        const { id: serviceId } = selectedService.value; // get sessions by clientId, anchorEventId & user schoolId
        const sessions = allSessions.value.filter(s => s.serviceId == serviceId && s.clientSchoolId == user.value.schoolId);
        return [
          ...sessions.filter(s => new Date(s.startTimeStr) > new Date()).sort((a, b) => new Date(a.startTimeStr) < new Date(b.startTimeStr) ? -1 : 1), // future sessions
          ...sessions.filter(s => new Date(s.startTimeStr) < new Date()).sort((a, b) => new Date(a.startTimeStr) < new Date(b.startTimeStr) ? 1 : -1), // past sessions
        ];
      },
      getMockJUPASDeadline: (mainSession, targetKey = 'date') => {
        if (mainSession.serviceId != 'mock-jupas') return null;
        const deadlineSessions = store.state.sessions.filter(s => s.anchorEventId == 'c0b3a6362' && s.clientSchoolId == user.value.schoolId && new Date(s.date) > new Date(mainSession.date))
                                                      .sort((a, b) => (new Date(a.date) > new Date(b.date) ? -1 : 1));
        return deadlineSessions.length > 0 ? deadlineSessions[0][targetKey] : "";
      },

      // Check form eligibility
      isAllowApply: () => {
        const { targetForms, anchorEventId, clientId, } = selectedService.value;
        const attendedBefore = allSessions.value.find(s => s.anchorEventId == anchorEventId && s.clientId == clientId && s.userResponse?.attended == 'Yes') != null;
        if (attendedBefore) return false; // e.g. BBAWork workshop, not allow duplicate application / nomination
        if (user.value.isAdmin || !targetForms) return true;
        return targetForms.includes(getSecStudentForm(user.value.yearDSE));
      },

      // AchieveJUPAS
      openMockJUPASToolModal: async () => (await openModal(AchieveJUPASResultPageModal, {})),

      // Track responses by classess
      OPTION_IDS, bookTimeData,
      completeServiceAction: async (ev, targetClass = "") => {
        const optionId = ev.detail.value;
        const { id: serviceId, currIntakeYear: intakeYear, name: serviceName, } = selectedService.value;
        const { event } = props;
        const { id: sessionId, name: eventName, date: eventDate } = (event || {});
        const resp = user.value.teacherResponses?.find(r => {
          if (props.event && r.sessionId != sessionId) return false; // Match event response
          return r.serviceId == serviceId && r.targetClass == targetClass && (!intakeYear || r.intakeYear == intakeYear)
        }) || {};

        // If book time, open modal (teacherResponseQuestions) for filling info
        if (optionId == OPTION_IDS.bookTime) {
          ev.target.value = ""; // prevent select UI changing value
          bookTimeData.targetClass = targetClass;
          bookTimeData.isModalOpened = true;
          return true;
        }

        // Other cases: update teacher response for that class directly
        const loading = await loadingController.create({ });
        await loading.present();
        const teacherResponse: any = {
          id: `r${uniqueId()}`,
          ...(resp || {}),
          optionId: optionId,
          response: (teacherResponseOptions.value.find(opt => opt.id == optionId) || {}).title || "",
          type: props.event ? 'event' : 'service', // previous: type = 'action'
          intakeYear,
          sessionId, serviceId,
          eventName: props.event ? `${eventName} (${eventDate})` : serviceName,
          targetClass,
        }
        TeacherService.upsertTeacherResponse(props.event, props.serviceId, teacherResponse, user.value.phone, user.value.schoolId, user.value.id, []);
        store.commit('upsertTeacherResponse', { ...teacherResponse, updatedAt: new Date().toString() });
        loading.dismiss();
      },
      getServiceTeacherClassResponse: (targetClass, targetKey = 'optionId') => {
        // Get class-based responses (from the teacher)
        const { id: serviceId, currIntakeYear: intakeYear } = selectedService.value;
        for (const t of schoolTeachers.value) {
          const resp = t.teacherResponses?.find(r => {
            if (props.event && r.sessionId != props.event.id) return false; // Match event response
            return r.serviceId == serviceId && r.targetClass == targetClass && (!intakeYear || r.intakeYear == intakeYear)
          });
          if (t.id == user.value.id) return resp ? resp[targetKey] || "" : ""; // user response, just return
          if (resp?.optionId == OPTION_IDS.bookTime) return targetKey ? resp[targetKey] : resp; // not allow scheduling for more than 1 session
        }
        return "";
      },
      getServiceRelatedClasses: () => {
        const { targetForms } = selectedService.value;
        const classes: any = [];
        if (targetForms.includes('F.6')) classes.push('6A','6B','6C','6D','6E');
        if (targetForms.includes('F.5')) classes.push('5A','5B','5C','5D','5E');
        if (targetForms.includes('F.4')) classes.push('4A','4B','4C','4D','4E');
        if (classes.length == 0) classes.push('5A','5B','5C','5D','5E'); // default F.5 students only
        return classes;
      },

      publicizeResponseOptions: () => {
        return [OPTION_IDS.playedVideo, OPTION_IDS.pubInOtherMeans, OPTION_IDS.skip].map(id => teacherResponseOptions.value.find(opt => opt.id == id)).filter(opt => opt);
      },
      proposeDateResponseOptions: () => {
        return [OPTION_IDS.bookTime, OPTION_IDS.findingTime, OPTION_IDS.skip].map(id => teacherResponseOptions.value.find(opt => opt.id == id)).filter(opt => opt);
      },

      // Subject-related career prospects
      openCareerProspects: async () => {
        openModal(ABProfessionSelectModal, { client: relatedClient.value });
      },

      // (For guest users: login & apply if available)
      openLoginModal: () => {
        openModal(LoginPage, {}, "login-modal");
      },
    }
  }
})
