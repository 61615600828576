// components
import { modalController, alertController, loadingController, toastController, isPlatform } from '@ionic/vue';
import ImageModal from '@/components/modals/ImageModal.vue';
import ServiceModal from '@/components/modals/ServiceModal.vue';
import BookingPage from '@/pages/BookingPage.vue';
import TeacherService from '@/services/TeacherService';

// capacitor
import { SplashScreen } from '@capacitor/splash-screen';
import { Browser } from '@capacitor/browser';

// lib
import moment from 'moment';
import * as linkify from "linkifyjs";
import linkifyHtml from "linkify-html";

// other composables
import { useI18n } from 'vue-i18n';
import utilsDeck from './utilsDeck';
import utilsAchieveJUPAS from './utilsAchieveJUPAS';
import config from '@/config';

export function utils() {
  const { t } = useI18n();

  const getProxyImgLink = (imgLink: any) => (imgLink && imgLink.startsWith("http") ? `https://fdmt.hk/imgproxy.php?url=${encodeURIComponent(imgLink)}` : imgLink);
  const getIntakeYearOfDate = (date) => {
    const d = new Date(date), month = d.getMonth() + 1;
    let year = d.getFullYear();
    if (month >= 9) year += 1; // next intake (academic year changed)
    return year;
  }
  const getCurrentIntakeYear = () => (getIntakeYearOfDate(new Date()));
  const iPhoneNativeApp = () => (isPlatform('ios') && !isPlatform('mobileweb'));
  const formatDate = (date: any, format = 'YYYY/MM/DD HH:mm') => moment(new Date(date)).format(format);
  const openModal = async (component: any, componentProps: any, cssClass = "", backdropDismiss = false) => {
    const modal = await modalController.create({
      component,
      componentProps,
      cssClass,
      backdropDismiss
    });
    return modal.present();
  };
  const presentAlert = async (msg: string, hideHeader = false) => {
    const obj: any = {
      header: t('errorHeader'),
      message: msg,
      buttons: ['OK']
    }
    if (hideHeader) delete obj.header;
    const alert = await alertController.create(obj);
    return await alert.present();
  }
  const presentPrompt = async (message: any = "", callback: any, header = "") => {
    const alert = await alertController.create({
      header,
      message,
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: t('confirm'),
          handler: callback,
        }
      ]
    });
    return alert.present();
  };
  const promptRollCallCode = async (callback: any) => {
    const alert = await alertController.create({
      header: "Please enter the roll call code presented by the trainer to record your attendance.",
      inputs: [
        {
          name: 'rollCallCode',
          type: 'text',
          placeholder: "Roll Call Code",
          value: "",
          attributes: {
            autocapitalize: "characters"
          },
        },
      ],
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: t('submit'),
          handler: (value) => {
            if (value.rollCallCode) {
              callback(value.rollCallCode);
            }
            return false;
          }
        }
      ]
    });
    await alert.present();
  }
  const promptWithdrawReason = async (message: any, callback: any) => {
    // Special handling: require submitting reason
    const alert = await alertController.create({
      message,
      inputs: [
        {
          name: 'withdrawReason',
          type: 'textarea',
          placeholder: "Reason for withdrawl (required)",
          value: "",
          attributes: {
            required: true,
            rows: 1,
          }
        },
      ],
      buttons: [
        {
          text: t('back'),
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: t('submit'),
          handler: (value) => {
            if (value.withdrawReason) {
              callback(value.withdrawReason);
            } else {
              presentAlert("Please fill in the reason for withdrawl.")
              return false;
            }
          }
        }
      ]
    });
    await alert.present();
  }
  const presentToast = async(msg: string, duration = 3000, position: any = 'bottom', buttons = []) => {
    const toast = await toastController.create({
      message: msg,
      duration,
      position,
      buttons,
    });
    toast.present();
  }
  const sleep = (s: any) => {
    return new Promise((resolve) => {
      setTimeout(resolve, s * 1000);
    });
  };
  const sendLinkToMyWhatsApp = async (url: any, phone: any, waGroupId: any, posterImgLink = "", videoLink = "", slideLink = "", showPrompt = true) => {
    if (showPrompt) {
      presentPrompt("Confirm sending it to your WhatsApp group?", async () => {
        const loading = await loadingController.create({});
        await loading.present();
        TeacherService.sendRegQRCodeToSelfWhatsAppGrp(url, phone, waGroupId, posterImgLink, videoLink, slideLink);
        await sleep(2);
        loading.dismiss();
        presentToast("It will be sent to your WhatsApp group in minutes.");
      });
    } else {
      TeacherService.sendRegQRCodeToSelfWhatsAppGrp(url, phone, waGroupId, posterImgLink, videoLink, slideLink);
    }
  };

  const getBandLabel = (arrIdx) => {
    if (arrIdx == -1) return ''; // N/A
    const order = arrIdx+1; // array index starts from 0
    if (order <= 3) return `A${order}`; // Band A
    if (order <= 6) return `B${order-3}`; // Band B
    if (order <= 10) return `C${order-6}`; // Band C
    if (order <= 15) return `D${order-10}`; // Band D
    if (order <= 20) return `E${order-15}`; // Band E
    return order.toString();
  }
  const getBandClass = (arrIdx) => {
    const bandLabel = getBandLabel(arrIdx);
    if (bandLabel == '') return '';
    const band = bandLabel.match(/[A-Z]/);
    return band ? `band-${band.toString().toLowerCase()}` : 'band-other';
  }

  const toCamel = (str) => {
    return str.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
  };
  const convertKeysToCamelCase = (o) => {
    let newO, origKey, newKey, value;
    if (o instanceof Array) {
      return o.map(function(value) {
          if (typeof value === "object") {
            value = convertKeysToCamelCase(value)
          }
          return value
      })
    } else {
      newO = {};
      for (origKey in o) {
        if (Object.prototype.hasOwnProperty.call(o, origKey)) {
          if (origKey === "_RowNumber") { // skip AppSheet row number property
            newO[origKey] = o[origKey];
          } else {
            newKey = toCamel(origKey);
            value = o[origKey]
            if (value instanceof Array || (value && value.constructor === Object)) {
              value = convertKeysToCamelCase(value)
            }
            newO[newKey] = value
          }
        }
      }
    }
    return newO
  };

  return {
    ...utilsAchieveJUPAS,
    ...utilsDeck,

    randInt: (min, max) => Math.floor(Math.random() * (max - min + 1) + min),

    openBookingModal: async (bookingItemId) => {
      return await openModal(BookingPage, { isModal: true, bookingItemId });
    },

    toCamel, convertKeysToCamelCase,

    getBandLabel, getBandClass,
    getDisciplineGroupColor: (group, isDiscipline = false) => {
      const getColor = (color: string) => {
        if (isDiscipline) return color;
        // Convert hex to RGB then to HSL and increase lightness
        const r = parseInt(color.slice(1, 3), 16) / 255;
        const g = parseInt(color.slice(3, 5), 16) / 255;
        const b = parseInt(color.slice(5, 7), 16) / 255;
        
        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if (max === min) {
          h = s = 0;
        } else {
          const d = max - min;
          s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
          switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
            default: h = 0;
          }
          h /= 6;
        }

        // Increase lightness for disciplines
        l = Math.min(0.8, l + 0.2);
        
        // Convert back to RGB
        const hue2rgb = (p: number, q: number, t: number) => {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1/6) return p + (q - p) * 6 * t;
          if (t < 1/2) return q;
          if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
          return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        const tr = hue2rgb(p, q, h + 1/3);
        const tg = hue2rgb(p, q, h);
        const tb = hue2rgb(p, q, h - 1/3);

        const toHex = (x: number) => {
          const hex = Math.round(x * 255).toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        };

        return `#${toHex(tr)}${toHex(tg)}${toHex(tb)}`;
      };
      
      if (group == 'Arts') return getColor('#E91E63');
      if (group == 'Science') return getColor('#2196F3');
      if (group == 'Engineering') return getColor('#607D8B');
      if (group == 'Social Science') return getColor('#FF5722');
      if (group == 'Business') return getColor('#9C27B0');
      if (group == 'Professional') return getColor('#293040');
      return getColor('#000');
    },

    getAppSheetFileLink: (table, photo) => {
      if (!photo) return "";
      return photo.includes('https://') ? photo
            : `https://www.appsheet.com/template/gettablefileurl?appName=FDMTFront-517073488&tableName=${table}&fileName=${encodeURIComponent(photo)}`;
    },

    openServiceModal: async (serviceId: any, event: any = null, isSecondaryStudentView = false) => (await openModal(ServiceModal, { serviceId, event, isSecondaryStudentView, })),

    htmlToPlainText: (html) => (html.replace(/<[^>]*>/g, '')),
    getLinkifiedText: (str) => (linkifyHtml(str, { defaultProtocol: "https", target: "_blank" })),
    sendLinkToMyWhatsApp,
    copyText: (text, msg = "Text copied.") => {
      navigator.clipboard.writeText(text); // copy innerText of the span
      presentToast(msg, 1000);
    },
    getQRCodeUrl: (data: any) => (`https://qrcode.tec-it.com/API/QRCode?data=${data}&dpi=96&quietzone=3`),
    getProxyImgLink,
    formatStudentNumber: (studentNumber) => (studentNumber ? studentNumber.toString().padStart(2, '0').slice(0, 2) : ""),
    promptRollCallCode, promptWithdrawReason,
    getIntakeYearOfDate, getCurrentIntakeYear,
    getF6YearDSE: () => (getCurrentIntakeYear()),
    getF5YearDSE: () => (getCurrentIntakeYear() + 1),
    getF4YearDSE: () => (getCurrentIntakeYear() + 2),
    getF3YearDSE: () => (getCurrentIntakeYear() + 3),
    getSecStudentForm: (yearDSE) => {
      const currIntakeYear = getCurrentIntakeYear();
      if (yearDSE < currIntakeYear) return 'Graduated';
      if (yearDSE == currIntakeYear) return 'F.6';
      if (yearDSE == currIntakeYear+1) return 'F.5';
      if (yearDSE == currIntakeYear+2) return 'F.4';
      if (yearDSE == currIntakeYear+3) return 'F.3';
      if (yearDSE == currIntakeYear+4) return 'F.2';
      if (yearDSE == currIntakeYear+5) return 'F.1';
      return 'Unknown';
    },
    uniqueId: () => Math.random().toString(36).slice(-8),

    doReorder: (event: CustomEvent, targetArr: any) => {
      targetArr = event.detail.complete(targetArr);
    },

    formatDate,
    iPhoneNativeApp,
    openModal,
    openImageModal: async (imageLink: any, caption: any = "", onClickImgLink = "", noZoomInOut = false, cssClass = "") => {
      return await openModal(ImageModal, { imageLink, caption, onClickImgLink, noZoomInOut }, cssClass);
    },
    closeModal: async (data: any = {}, promptLeave = false) => {
      if (promptLeave) {
        await presentPrompt(t("confirmLeave"), async () => {
          await modalController.dismiss(data);
        });
      } else {
        await modalController.dismiss(data);
      }
    },
    openBrowser: async (url: any) => {
      await Browser.open({ url, toolbarColor: config.primaryColor });
    },
    focusInputField: (refEl: any) => {
      refEl.$el.setFocus();
    },
    numberWithCommas: (x: any) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getRelativeDate: (date: any) => {
      return moment(new Date(date)).fromNow();
    },
    addDaysToDate: (date: any, days: any) => {
      days = days == "" ? 1 : days; // default add 1 day
      return (moment(new Date(date))).add(days, 'days');
    },
    sleep,
    formatDateString: (d: any) => {
      return d ? moment(new Date(d)).format('YYYY/MM/DD') : "";
    },
    reloadApp: () => {
      SplashScreen.show();
      window.location.reload();
    },
    presentPrompt,
    presentAlert,
    presentToast,
    presentVerifyCodeInputAlert: async (phone: any, callback: any, showSkipBtn = false) => {
      loadingController.dismiss();
      let buttons: any = [
        { text: t('cancel'), role: 'cancel', cssClass: 'secondary' },
        {
          text: t('RegisterPage.verify'),
          handler: (value) => {
            if (value.verificationCode) {
              callback(value.verificationCode);
            }
            return false; // not closing the alert
          },
        },
      ];
      if (showSkipBtn) {
      //if (true) { // TMP: for LKKC iPad (TODO: remove & how to better handle this case?)
        buttons = [
          {
            text: t('RegisterPage.verify'),
            handler: (value) => {
              if (value.verificationCode) {
                callback(value.verificationCode);
              }
              return false; // not closing the alert
            },
          },
          {
            text: "Skip for now",
            handler: (value) => {
              presentPrompt(`Confirm skipping the verification?<br /><br />Please make sure the phone your entered is correct: <b>${phone}</b>`, () => {
                callback("skip");
              })
              return false;
            },
            cssClass: 'skip-verification-btn',
          },
          { text: t('cancel'), role: 'cancel', cssClass: 'secondary' },
        ]
      }
      const alert = await alertController.create({
        backdropDismiss: false,
        header: t('RegisterPage.verifyingMobileNumber'),
        message: `${t('RegisterPage.verifyMobileNumberInstruction')} (<b>${phone}</b>).`,
        inputs: [
          {
            name: 'verificationCode',
            type: 'text',
            placeholder: t('RegisterPage.verificationCode'),
            attributes: {
              inputmode: 'numeric',
              maxlength: '6',
            }
          },
        ],
        buttons,
      });
      await alert.present();
      
      // Enable skip for now button after 10 seconds
      if (showSkipBtn) {
        const el: any = document.querySelector('.skip-verification-btn');
        el.disabled = true;
        el.classList.add('disabled')
        setTimeout(() => { el.disabled = false; el.classList.remove('disabled') }, 10000);
      }
    },
    infiniteScrollLoadData: (ev: any, numOfVisibleItems: any, items: any, increment = 20) => {
      if (numOfVisibleItems.value > items.length) {
        ev.target.complete();
      } else {
        setTimeout(() => {
          numOfVisibleItems.value += increment;
          ev.target.complete();
        }, 500);
      }
    },
  };
}