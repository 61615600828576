
// Vue reactivity
import { ref, defineComponent, computed, watch, reactive, onMounted } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, openOutline, trashOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonGrid, IonRow, IonCol,
        IonList, IonItem, IonLabel, IonIcon, IonButtons, IonButton,
        IonSearchbar, IonSegment, IonSegmentButton, IonSpinner, } from '@ionic/vue';
import ProfessionModal from '@/components/pss/ProfessionModal.vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

export default defineComponent({
  name: 'ListProfessionModal',
  props: ["discipline"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonGrid, IonRow, IonCol,
                IonList, IonItem, IonLabel, IonIcon, IonButtons, IonButton,
                IonSearchbar, IonSegment, IonSegmentButton, IonSpinner, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { openModal, closeModal, infiniteScrollLoadData, } = utils();
    const { t } = useI18n();

    const loading = computed(() => store.state.loadingData);
    const relatedProfessions = computed(() => store.getters.getProfessionsByDisciplineId(props.discipline.id));
    const openProfessionModal = async (professionId: any) => await openModal(ProfessionModal, { professionId, useBackButton: true });

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, openOutline, trashOutline,

      // variables
      loading, relatedProfessions,

      // methods
      t,
      closeModal,
      openProfessionModal,
    }
  }
});
