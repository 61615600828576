
// Vue reactivity
import { reactive, defineComponent, computed, onMounted } from 'vue';

// icons
import { mail, close, arrowBack, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonSpinner, IonItem, IonLabel, IonChip, IonIcon,
        IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea, modalController, loadingController,
        IonGrid, IonCol, IonRow, IonSelect, IonSelectOption,
        toastController } from '@ionic/vue';

// API services
import UserService from '@/services/UserService';

// Utils
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';
import config from '@/config';

export default defineComponent({
  name: 'UserProfileFormModal',
  props: ["hideHeader", "isPage", "targetUser", "targetUserId"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
                IonSpinner, IonItem, IonLabel, IonChip, IonIcon,
                IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea,
                IonGrid, IonCol, IonRow, IonSelect, IonSelectOption, },
  setup(props) {
    // 1. declare state variables (ref to make them reactive)
    const store = useStore();
    const router = useRouter();
    const user = computed(() => store.state.user);
    const newUser = reactive<any>({
      id: user.value.id,
      fullName: user.value.fullName,
      preferredName: user.value.preferredName,
      email: user.value.email,
      chineseName: user.value.chineseName,
      class: user.value.class,
      studentNumber: user.value.studentNumber,
      studyingElectives: user.value.studyingElectives,
      yearDSE: user.value.yearDSE,
      rankInForm: user.value.rankInForm,
    });

    // methods or filters
    const { t } = useI18n();
    const { formatDate, presentToast, presentAlert } = utils();
    const closeModal = async (updatedUser: any = null) => {
      if (props.isPage) {
        router.replace('/home');
      } else {
        await modalController.dismiss({ updatedUser });
      }
    };
    const updateUser = async () => {
      const { targetUser } = props;
      const loading = await loadingController.create({});
      await loading.present();
      try {
        await UserService.updateUser(newUser, targetUser || user.value);
        if (targetUser) store.commit('upsertSchoolStudents', [newUser]);
        else store.commit('updateUser', newUser);
        presentToast(t('successUpdatePersonalInfo'), 2000);
        closeModal();
      } catch (e) {
        //presentAlert(t('clashEmailAddress'));
        presentAlert(e.message);
      } finally {
        loading.dismiss();
      }
    }

    onMounted(() => {
      if (props.targetUser) {
        const { targetUser } = props;
        newUser.id = targetUser.id;
        newUser.fullName = targetUser.fullName;
        newUser.preferredName = targetUser.preferredName;
        newUser.email = targetUser.email;
        newUser.chineseName = targetUser.chineseName;
        newUser.class = targetUser.class;
        newUser.studentNumber = targetUser.studentNumber;
        newUser.studyingElectives = targetUser.studyingElectives;
        newUser.yearDSE = targetUser.yearDSE;
        newUser.rankInForm = targetUser.rankInForm;
      }
      if (newUser.studyingElectives && !Array.isArray(newUser.studyingElectives)) {
        newUser.studyingElectives = newUser.studyingElectives.split(" , ");
      }
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      mail, close, arrowBack,

      // variables
      newUser, user,
      OPTIONS: config.formOptions,

      // methods
      t, formatDate, closeModal, updateUser
    }
  }
});
