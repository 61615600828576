
// Vue reactivity
import { ref, defineComponent, computed, onMounted, watch, reactive, } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, checkbox, trashOutline,
         thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline,
         pencil, statsChart, send, search, helpCircleOutline, shareSocial, addCircleOutline, caretDown,
         starOutline, star, ellipse, ellipseOutline, checkmarkCircle, alertCircle, closeCircle, } from 'ionicons/icons';

// components
import { IonPage, IonGrid, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonReorder, IonReorderGroup,
        IonList, IonItem, IonItemSliding, IonItemOptions, IonItemOption, IonLabel, IonIcon, IonButtons, IonButton, IonTextarea, IonText,
        IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonSegment, IonSegmentButton,
        IonBadge, IonAccordionGroup, IonAccordion, IonSpinner, IonSelect, IonSelectOption, IonChip, IonPopover, IonModal,
        IonInput, isPlatform, modalController, loadingController, } from '@ionic/vue';
import ABProgramDeckModal from '@/components/achievejupas/ABProgramDeckModal.vue';
import AchieveJUPASChartsModal from '@/components/achievejupas/AchieveJUPASChartsModal.vue';
import AB3DisciplineResultList from '@/components/secondary/ab3/AB3DisciplineResultList.vue';
import ProgramInfoStats from '@/components/achievejupas/ProgramInfoStats.vue';
import ProgramElectiveRequirements from '@/components/achievejupas/ProgramElectiveRequirements.vue';
import ProgramItemContent from '@/components/achievejupas/ProgramItemContent.vue';
import SubjectScoreInputSection from '@/components/achievejupas/SubjectScoreInputSection.vue';

// Swiper
import Slides from '@/components/shared/Slides.vue';
import { SwiperSlide } from 'swiper/vue/swiper-vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';
import { useRoute, useRouter } from 'vue-router';

// types
import { Elective, Program, School, Service, User, UserElective, UserProgram } from '@/types';

// services
import ABSService from '@/services/ABSService';
import SLPService from '@/services/SLPService';
import AchieveJUPASService from '@/services/AchieveJUPASService';

import ParentConsentSignatureModal from './ParentConsentSignatureModal.vue';

export default defineComponent({
  name: 'AchieveJUPASResultPageModal',
  props: ["isPage", "isAB3", "isAB3ParentView", "targetUser", "isDemo", "hideTitle"],
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon, IonList, IonItem,
    IonItemSliding, IonItemOptions, IonItemOption, IonLabel, IonRow, IonCol, IonTextarea, IonText, IonFooter, IonGrid, IonReorder, IonReorderGroup,
    IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonSegment, IonSegmentButton,
    IonBadge, IonAccordionGroup, IonAccordion, IonSpinner, IonSelect, IonSelectOption, IonChip, IonPopover, IonModal,
    IonInput, AB3DisciplineResultList, ProgramInfoStats, ProgramElectiveRequirements,
    SwiperSlide, Slides, ProgramItemContent, SubjectScoreInputSection,
  },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { closeModal, doReorder, openModal, syncChosenItems, presentPrompt, getBandLabel, getBandClass, getDisciplineGroupColor, presentToast, sleep,
            linkNestedChosenItems, processUserItems, getQRCodeUrl, getProxyImgLink, openImageModal, openBrowser, formatStudentNumber, uniqueId,
            getUserSubjects, formatDate, getScoreDiff, getF5YearDSE, } = utils();
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();

    const loading = computed(() => store.state.loadingPortalData || store.state.loadingUser);
    const user = computed<User>(() => props.targetUser || store.state.user);
    const userRelatedSchool = computed<School>(() => store.getters.userRelatedSchool);
    const allClientProgramIds = computed(() => store.getters.allClientProgramIds);
    const allPrograms = computed<Program[]>(() => store.state.allPrograms);
    const selectedPrograms = ref<Program[]>([]);
    const userPrograms = ref<UserProgram[]>([]);
    const oldUserPrograms = ref("");
    const expandedProgramIds = ref([]);

    // Fake Demo
    const selectedView = ref("JUPAS Choices");
    const selectedPlan = ref("Dec submission");

    // Consultation (for teachers)
    const nextConsultation = reactive({
      id: '',
      date: '',
      time: '',
      venue: '',
      remarks: '',
    });
    const previousConsultations = ref([]);
    const teacherComments = ref([]);

    // AB3
    const selectedDisciplines = ref([]);
    const currStep = ref(props.isAB3 ? 1 : 2);
    const allElectives = computed<Elective[]>(() => store.state.allElectives);
    const userElectives = ref<UserElective[]>([]);
    const getUserElective = (programId, targetOrder, targetReaction: any = null, targetKey = '') => {
      const res = userElectives.value.find(ue => ue.programId == programId && ue.order == targetOrder && (targetReaction == null || ue.reaction == targetReaction));
      return res ? (targetKey ? res[targetKey] : res) : null;
    }
    const getElectiveChoiceLabel = (programId) => {
      if (programId == 0) return `1st Choice`;
      if (programId == -1) return `2nd Choice`;
      if (programId == -2) return `3rd Choice`;
      return `Choice`;
    }

    const syncUserElectivesToDB = (targetUserElectives?: UserElective[]) => {
      ABSService.upsertUserElectives(targetUserElectives || userElectives.value); // For AB3: save user selected electives
      //store.commit('updateUser', { userElectives: userElectives.value });
    }
    const syncUserProgramsToDB = (commitUserPrograms = false) => {
      if (props.targetUser || props.isDemo) return false; // checking user choices: readonly

      // Sync User Selected Electives (under each program)
      for (const prog of selectedPrograms.value) {
        if (prog.selectedElectives) {
          userElectives.value = processUserItems(prog.selectedElectives, userElectives.value, [], 'electiveId', user.value.id)
        }
      }

      // Save User Selected Programs & Electives
      userPrograms.value = processUserItems(selectedPrograms.value, userPrograms.value, [], 'programId', user.value.id);
      const data = {
        "selectedPrograms": selectedPrograms.value,
        "userPrograms": userPrograms.value,
        "userElectives": userElectives.value,
      }
      try {
        const { userPrograms, userElectives, } = data;
        const updatedUserObj = {};
        let userProgramsStr = "Updated";
        try {
          userProgramsStr = JSON.stringify(userPrograms);
        } catch (e) {
          console.error(e);
        }
        if (oldUserPrograms.value != userProgramsStr) {
          ABSService.upsertUserPrograms(userPrograms);
          if (commitUserPrograms) updatedUserObj['userPrograms'] = userPrograms;
          user.value.userPrograms = userPrograms; // Bug fix: switch between teacher & student view
        }
        if (props.isAB3) {
          ABSService.upsertUserElectives(userElectives); // For AB3: save user selected electives
          if (commitUserPrograms) updatedUserObj['userElectives'] = userElectives;
        }
        if (Object.keys(updatedUserObj).length > 0) store.commit('updateUser', updatedUserObj);
      } catch (e) {
        console.error(e);
      }
      return data;
    }
    const confirmSelect = async () => {
      if (props.targetUser) {
        await closeModal({}); // not allow saving (readonly)
      } else {
        const data = syncUserProgramsToDB(true);
        if (props.isPage) {
          router.replace(user.value.teacher ? '/services/mock-jupas' : '/home');
        } else {
          await closeModal(data); // return selected items & order here
        }
      }
    };

    const syncUserPrograms = () => {
      if (props.isDemo) {
        // Show demo programs only
        userPrograms.value = [161, 285].map((programId, idx) => ({
          "programId": programId,
          "reaction": "like",
          "reason": "",
          "createdAt": "2025-01-12T00:00:00.000+00:00",
          "appState": {},
          "order": idx+1
        }));
        syncChosenItems('programId', selectedPrograms, userPrograms, allPrograms.value);
      } else {
        // Load previous choices
        // TODO: sync only current plan choices (or just disable the button first?)
        // score also need to be plan-dependant
        oldUserPrograms.value = JSON.stringify((user.value.userPrograms || []).sort((a,b) => Number(a.order)-Number(b.order)));
        userPrograms.value = JSON.parse(oldUserPrograms.value || '[]');

        // Prefill with previously written reasons
        syncChosenItems('programId', selectedPrograms, userPrograms, allPrograms.value);
      }

      // AB3
      if (props.isAB3) {
        // Load previous choices
        const oldUserElectives = JSON.stringify((user.value.userElectives || []).sort((a,b) => Number(a.order)-Number(b.order)));
        userElectives.value = JSON.parse(oldUserElectives || '[]');

        // link selected electives to programs
        linkNestedChosenItems(userElectives, allElectives, 'electiveId', allPrograms, 'programId', 'selectedElectives');
      }
    }

    onMounted(() => {
      syncUserPrograms();

      // AB3 parent's view: retrieve student's data
      if (props.isAB3ParentView) {
        const targetUserId = route.params.userId;
        ABSService.getStudentByUserId(targetUserId).then(res => {
          console.log(res);
          store.commit('receiveUser', res);
          syncUserPrograms();
        });
      }

      // Sync consultation data
      if (props.targetUser) {
        AchieveJUPASService.getJUPASConsultationRecords(props.targetUser.id).then(res => {
          const latestConsultation = res[0];
          // TODO: add to previousConsultations (when to define a new consultation?)
          if (latestConsultation) {
            nextConsultation.id = latestConsultation.id;
            nextConsultation.date = latestConsultation.date;
            nextConsultation.time = latestConsultation.time;
            nextConsultation.venue = latestConsultation.venue;
            nextConsultation.remarks = latestConsultation.remarks;
          }
        })
      } else {
        AchieveJUPASService.getUserComments('achievejupas').then(res => {
          teacherComments.value = res || [];
        })
      }
    })
    watch(user, () => {
      syncUserPrograms(); // direct access /mock-jupas
    })
    watch(loading, () => {
      syncUserPrograms(); // direct access /mock-jupas
    })

    // School customization
    const electiveCombinations: any = {
      'htc': {
        "4A": [
          [4,10,1,2], // CHist / Geog / Bio / BAFS (Accounting)
          [7,10,12,23,13,1], // Econ / Geog / History / VA / ICT / Bio
          [7,2,1,13], // Econ / BAFS (Accounting / Business Management) / Bio / ICT
          [],
        ],
        "4B": [
          [4,10,1,2], // CHist / Geog / Bio / BAFS (Accounting)
          [7,10,12,23,13,1], // Econ / Geog / History / VA / ICT / Bio
          [7,2,1,13], // Econ / BAFS (Accounting / Business Management) / Bio / ICT
          [],
        ],
        "4C": [
          [4,10,1,2], // CHist / Geog / Bio / BAFS (Accounting)
          [7,10,12,23,13,1], // Econ / Geog / History / VA / ICT / Bio
          [7,2,1,13], // Econ / BAFS (Accounting / Business Management) / Bio / ICT
          [],
        ],
        "4D": [
          [3],
          [10,3,1,13,7], // Geog / Chem / Bio / ICT / Econ
          [7,2,1,18,13], // Econ / BAFS (Accounting / Business Management) / Bio / Phy / ICT
          [14], // M1 / M2
        ],
        "4E": [
          [18],
          [10,3,1,13,7], // Geog / Chem / Bio / ICT / Econ
          [7,2,1,18,13], // Econ / BAFS (Accounting / Business Management) / Bio / Phy / ICT
          [15], // M1 / M2
        ]
      },
      'cfss': {
        "4A": [
          [18,7,1], // Phy / Econ / Bio
          [18,1,3,13,12,2,8,4,23], // Phy / Bio / Chem / ICT / Hist / BAFS / Lit in Eng / C Hist / VA
          [10,13,3], // Geog / ICT / Chem
          [14,15], // M1 / M2
        ],
        "4B": [
          [18,7,1],
          [18,1,3,13,12,2,8,4,23],
          [10,13,3],
          [],
        ],
        "4C": [
          [3],
          [18,1,3,13,12,2,8,4,23],
          [],
          [14,15],
        ],
        "4D": [
          [7,10,5],
          [18,1,3,13,12,2,8,4,23],
          [],
          [14],
        ],
        "4E": [
          [7,10,5],
          [18,1,3,13,12,2,8,4,23],
          [],
          [],
        ],
      }
    }
    const getElectiveShortNames = (userElectives) => {
      return userElectives.map(ue => allElectives.value.find(e => e.id == ue.electiveId)?.shortName);
    }
    const getSelectedUserElectives = (checkProgramId = 0, excludeOrder: any = null) => {
      return userElectives.value.filter(ue => {
        if (excludeOrder && ue.order == excludeOrder) return false;
        if (!ue.order || ue.order < 1 || ue.order > 4) return false;
        return ue.programId == checkProgramId && ue.reaction == 'like';
      }).sort((a: any, b: any) => a.order-b.order);
    }
    const getPossibleClasses = (checkProgramId = 0) => {
      const selectedUserElectives = getSelectedUserElectives(checkProgramId);
      const possibleClasses: any = [];
      const combinations = electiveCombinations[userRelatedSchool.value.id]; // school-based customization
      if (combinations) {
        for (const cl in combinations) {
          const idGroups = combinations[cl];
          if (selectedUserElectives.every((ue: any) => (idGroups[ue.order-1].includes(Number(ue.electiveId))))) {
            possibleClasses.push(cl);
          }
        }
        return possibleClasses;
      }
      return ['4A','4B','4C','4D','4E'];
    }

    // Student Tags Management
    const defaultTags = ['Don\'t know what I want', 'Elite', 'SNDAS'].map(t => ({ id: `t${uniqueId()}`, name: t }));
    const newTagName = ref('');
    const showAddTag = ref(false);

    const addUserToTag = async (tag) => {
      props.targetUser?.tagIds.push(tag.id);
      AchieveJUPASService.addStudentToTeacherDefinedTag(tag.id, tag.name, props.targetUser.id);
      if (!store.state.user.createdTags?.some(t => t.id == tag.id)) {
        const loading = await loadingController.create({});
        await loading.present();
        store.commit('upsertUserCreatedTags', [{ id: tag.id, name: tag.name }]);
        loading.dismiss();
      }
    }

    const toggleTag = async (tag) => {
      const index = props.targetUser?.tagIds?.indexOf(tag.id);
      if (index === -1) {
        addUserToTag(tag);
      } else {
        props.targetUser?.tagIds.splice(index, 1);
        AchieveJUPASService.removeStudentFromTeacherDefinedTag(tag.id, props.targetUser.id);
      }
    }
    const toggleAddTag = () => {
      showAddTag.value = !showAddTag.value;
      if (!showAddTag.value) newTagName.value = '';
    }
    const addNewTag = () => {
      const tagToAdd = newTagName.value.trim();
      if (tagToAdd) {
        addUserToTag({ id: `t${uniqueId()}`, name: tagToAdd });
        newTagName.value = '';
        showAddTag.value = false;
      }
    }
    
    // AchieveJUPAS (score calculation)
    const allSubjects = computed(() => store.state.allSubjects);
    const userSubjects = computed(() => {
      const userObj = props.targetUser || user.value;
      return getUserSubjects(allSubjects.value, userObj);
    });
    const getUserSubjectGrades = () => {
      const userObj = props.targetUser || user.value;
      return userObj.userSubjectGrades || [];
    };
    const getProgramScoreIconColor = (program, source) => {
      const scoreDiff = getScoreDiff(program, allSubjects.value, userSubjects.value, getUserSubjectGrades(), source);
      return scoreDiff >= 0 ? 'success' : 'danger';
    }

    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, checkbox, trashOutline,
      thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline, pencil,
      statsChart, send, search, helpCircleOutline, shareSocial, addCircleOutline, caretDown,
      starOutline, ellipseOutline, checkmarkCircle, alertCircle, closeCircle,
      star, ellipse, // meet median scores? (Red/Green light)

      // variables
      store,
      user, userRelatedSchool,
      loading, selectedPrograms, userPrograms,
      allElectives,
      currStep,
      expandedProgramIds,

      // Student tag management (for consultation etc.)
      getTagOptions: () => {
        const userCreatedTags = (store.state.user.createdTags || []); // tags defined by teacher
        if (userCreatedTags.length == 0) return defaultTags;
        return [...userCreatedTags, ...defaultTags.filter(dt => !userCreatedTags.some(uct => uct.name == dt.name))];
      },
      newTagName, showAddTag, addNewTag,
      toggleTag, toggleAddTag,

      // methods
      t, openBrowser, formatStudentNumber,
      closeModal, confirmSelect, getF5YearDSE,
      doReorder, getProxyImgLink, openImageModal,
      openAchieveJUPASChartsModal: async () => (await openModal(AchieveJUPASChartsModal, { userPrograms: userPrograms.value })),
      openABProgramDeckModal: async (showFirstProgramId = null, isGPT = false, singleSelectMode = false) => {
        const modal = await modalController.create({
          cssClass: 'tall-modal',
          component: ABProgramDeckModal, 
          componentProps: {
            prefilledPrograms: selectedPrograms.value.slice(),
            oldUserPrograms: userPrograms.value.slice() || [],
            showFirstProgramId, singleSelectMode,
            isAB3: props.isAB3,
            isGPT,
            targetUser: props.targetUser,
            isDemo: props.isDemo,
          }
        });
        modal.onDidDismiss().then(({ data }) => {
          if (data && !props.targetUser) {
            if (data.chosen) {
              selectedPrograms.value = data.chosen;
              userPrograms.value = data.userPrograms;

              syncUserProgramsToDB(); // auto-save
            }
            if (data.selectedProgram) {
              const targetIdx = selectedPrograms.value.findIndex(p => p.id == showFirstProgramId);
              if (targetIdx > -1) {
                selectedPrograms.value[targetIdx] = data.selectedProgram; // replace the choice
              } else {
                selectedPrograms.value.push(data.selectedProgram); // new choice
              }
              syncUserProgramsToDB(); // auto-save
            }
          }
        });
        return modal.present();
      },

      onDeleteChosenProgram: (idx, program) => {
        const deleteProgram = () => {
          selectedPrograms.value.splice(idx, 1);
          const relatedUserItem = userPrograms.value.find(up => up.programId == program.id);
          if (relatedUserItem) relatedUserItem.reaction = '';
          syncUserProgramsToDB(); // auto-save
        }
        if (user.value.isAdmin) deleteProgram();
        else presentPrompt("Confirm delete?", deleteProgram);
      },
      onReorderProgram: (event: CustomEvent, targetArr: any) => {
        doReorder(event, targetArr);
        syncUserProgramsToDB(); // auto-save
      },
      saveUserProgramReason: (idx, p) => { // mainly for auto-save after filling reasons
        userPrograms.value = processUserItems(selectedPrograms.value, userPrograms.value, [], 'programId', user.value.id);
        ABSService.upsertUserPrograms(userPrograms.value.filter(up => up.reaction == 'like'));

        const baseMsg = `reason for choosing\n*${getBandLabel(idx)}. ${p.displayName} ${p.nameChi || ""}*\nReason: _${p.reason}_`;
        const { phone, waGroupId, fullName, schoolId, } = user.value;
        const msgToStudent = `@852${phone} Thanks for filling out your ${baseMsg}`;
        const internalMsg = `${schoolId?.toUpperCase()} ${user.value.class} ${fullName} (${phone})\nsubmitted ${baseMsg}`;

        SLPService.sendWhatsAppMsg(msgToStudent, user.value.phone, user.value.waGroupId); // send to students
        SLPService.sendWhatsAppMsg(internalMsg, "", "120363094032861245@g.us"); // send to Internal Group
      },
      allClientProgramIds, // Ask reason when selected client programs

      // Mock JUPAS
      getBandLabel, getBandClass,
      getProgramIdxInList: (p) => {
        return selectedPrograms.value.findIndex(program => program.id == p.id);
      },
      addProgramToChoices: (program, afterIdx = -1) => {
        if (afterIdx >= 0) selectedPrograms.value.splice(afterIdx+1, 0, program);
        else selectedPrograms.value.push(program);
        syncUserProgramsToDB(); // auto-save
      },
      unselectProgram: (program) => {
        const idx = selectedPrograms.value.findIndex(p => p.id == program.id);
        if (idx !== -1) {
          selectedPrograms.value.splice(idx, 1);
          const relatedUserItem = userPrograms.value.find(up => up.programId == program.id);
          if (relatedUserItem) relatedUserItem.reaction = '';
          syncUserProgramsToDB(); // auto-save
        }
      },
      getDisciplineGroupColor,

      sendMockJUPASWhatsAppRecord: async () => {
        presentPrompt("Send the above choices to your WhatsApp group for records?", async () => {
          const loading = await loadingController.create({});
          await loading.present();
          let msg = `@852${user.value.phone} `, imgLink = "";
          if (props.isAB3) {
            const encryptedUserId = await ABSService.getEncryptedUserId(user.value.id);
            const parentLink = `https://ab.fdmt.hk/ab3-pv/${encryptedUserId}`;
            imgLink = getQRCodeUrl(parentLink); // attached QR code for students sharing with their parents

            msg += `Below are your latest choices for your records:\n\n`
            msg += `Share with parents: forward ${parentLink} or scan the QR code\n\n`;

            // Disciplines
            msg += `_*Discipline(s):*_\n`
            msg += selectedDisciplines.value.map((d: any, idx) => `*${idx+1}.* ${d.name} ${d.nameChi || ""}`).join("\n");

            // Programs
            msg += `\n\n_*Program(s):*_\n`;
            msg += selectedPrograms.value.map((p, idx) => `*${getBandLabel(idx)}.* ${p.displayName} ${p.nameChi || ""}`).join("\n");

            // Electives
            msg += `\n\n_*Elective(s):*_`;
            for (const programId of [0,-1,-2]) {
              const selectedUEs = getSelectedUserElectives(programId);
              if (selectedUEs.length > 0) {
                msg += `\n\n${getElectiveChoiceLabel(programId)}: *${getElectiveShortNames(getSelectedUserElectives(programId)).join(" , ")}*`;
                msg += `\nPossible class${getPossibleClasses().length > 1 ? 'es' : ''}: *${getPossibleClasses().join(" / ")}*`; // Show possible classes
              }
            }
          }
          else {
            // AchieveJUPAS
            msg += `Below are your latest program choices for your records:\n\n`;
            msg += selectedPrograms.value.map((p, idx) => `*${getBandLabel(idx)}.* ${p.displayName} ${p.nameChi || ""}`).join("\n");
          }
          SLPService.sendWhatsAppMsg(msg, user.value.phone, user.value.waGroupId, imgLink);
          await sleep(2);
          loading.dismiss();
          presentToast("Your choices will be sent to your WhatsApp group in minutes.");
        });
      },

      // AB3
      selectedDisciplinesUpdated: (latestDisciplines) => {
        // Event listener: child component -> selected disciplines
        selectedDisciplines.value = latestDisciplines; // Mainly for sending WhatsApp records if needed
      },
      getUserElective, getElectiveChoiceLabel, getElectiveShortNames,
      onUpdateElectiveForProgram: (ev, programId, targetOrder) => {
        const newElectiveId = ev.detail.value;

        let found = false;
        const relatedUserElectives = userElectives.value.filter(ue => ue.programId == programId && ue.order == targetOrder);
        for (const ue of relatedUserElectives) {
          if (ue.electiveId == newElectiveId) { // matched
            ue.reaction = 'like';
            found = true;
          } else { // no reaction for others
            ue.reaction = '';
          }
        }
        if (!found) {
          userElectives.value.push({
            programId,
            order: targetOrder,
            reaction: 'like',
            electiveId: newElectiveId,
            reason: "",
            createdAt: new Date(),
            appState: {},
          });
        }
        if (programId > 0) syncUserProgramsToDB(); // auto save
        else syncUserElectivesToDB(relatedUserElectives); // only sync related user electives
      },
      clearUserElectiveSelect: (programId, targetOrder) => {
        const relatedUserElectives = userElectives.value.filter(ue => ue.programId == programId && ue.order == targetOrder);
        for (const ue of relatedUserElectives) ue.reaction = '';
        if (programId > 0) syncUserProgramsToDB(); // auto save
        else syncUserElectivesToDB(relatedUserElectives); // only sync related user electives
      },
      getSelectedUserElectives, getPossibleClasses,
      electiveCombinations,
      getAvailableElectives: (targetOrder, checkProgramId, program?: Program) => {
        // Skip selected elective
        const selectedUserElectives = getSelectedUserElectives(checkProgramId).filter(ue => ue.order != targetOrder); // except the current list

        // Program-specific requirements (currently not in use)
        if (program) {
          const { requiredElective1, requiredElective2, requiredElective3, preferredSubjects, } = program;
          const res = allElectives.value.filter(e => {
            if (targetOrder == 1 && requiredElective1.length > 0) return requiredElective1.includes(e.name);
            if (targetOrder == 2 && requiredElective2.length > 0) return requiredElective2.includes(e.name);
            if (targetOrder == 3 && requiredElective3.length > 0) return requiredElective3.includes(e.name);
            return !selectedUserElectives.find(ue => ue.electiveId == e.id);
          }).map(e => ({
            ...e,
            isRequired: requiredElective1.includes(e.name) || requiredElective2.includes(e.name) || requiredElective3.includes(e.name),
            isPreferred: preferredSubjects.includes(e.name),
          }));

          // Must-take electives
          const requiredElectives: any = res.filter(e => e.isRequired);
          if (requiredElectives.length > 0) requiredElectives.unshift({ isGroup: true, name: "Must-take", id: "requiredGroup" })
          
          // Preferred electives
          const preferredElectives: any = res.filter(e => e.isPreferred);
          if (preferredElectives.length > 0) preferredElectives.unshift({ isGroup: true, name: "Upweighted", id: "preferredGroup" });
          let opts = preferredElectives;
          
          // Include remaining electives
          if (preferredElectives.length > 0) opts.push({ isGroup: true, name: "Others", id: "other" })
          opts = opts.concat(res.filter(e => !e.isRequired && !e.isPreferred));
          
          return opts;
        }

        // School-based requirements (elective combinations)
        let possibleClasses =  ['4A', '4B', '4C', '4D', '4E'];
        //if (targetOrder >= 3) possibleClasses = getPossibleClasses(checkProgramId);
        if (selectedUserElectives.length > 0) possibleClasses = getPossibleClasses(checkProgramId);

        let possibleElectiveIds = [];
        const combinations = electiveCombinations[userRelatedSchool.value.id]; // school-based customization
        if (combinations) {
          for (const cl in combinations) {
            const idGroups: any = combinations[cl], ids = idGroups[targetOrder-1];

            // 1. Class default elective choices for 'targetOrder'
            // 2. Check if other class electives also fit current combinations (exclude current order elective option)
            if (possibleClasses.includes(cl) || selectedUserElectives.every((ue: any) => (idGroups[ue.order-1].includes(Number(ue.electiveId))))) {
              possibleElectiveIds = possibleElectiveIds.concat(ids);
            }
          }
        }
        const possibleElectives: any = [...new Set(possibleElectiveIds)].map(id => allElectives.value.find(e => e.id == id)).filter(e => e)
                                                                        .sort((a: any, b: any) => (a.name < b.name ? -1 : 1));
        const resultElectives = possibleElectives.filter(e => (!selectedUserElectives.find(ue => ue.electiveId == e.id)))
                                                  .map(e => ({ ...e, isPreferred: false })); // reset
        //return resultElectives;

        // Take into account A1-3 programs & calculate suggested electives
        for (const program of selectedPrograms.value.slice(0, 3)) {
          const { requiredElective1, requiredElective2, requiredElective3, preferredSubjects, } = program;
          for (const e of resultElectives) {
            if (requiredElective1.includes(e.name) || requiredElective2.includes(e.name)
                || requiredElective3.includes(e.name) || preferredSubjects.includes(e.name)) {
              e.isPreferred = true;
            }
          }
        }
        // Preferred electives
        const preferredElectives: any = resultElectives.filter(e => e.isPreferred);
        if (preferredElectives.length > 0) preferredElectives.unshift({ isGroup: true, name: "Suggested from '2. Programs'", id: "preferredGroup" });
        let opts = preferredElectives;
        
        // Include remaining electives
        if (preferredElectives.length > 0) opts.push({ isGroup: true, name: "Others", id: "other" })
        opts = opts.concat(resultElectives.filter(e => !e.isPreferred));
        
        return opts;
      },
      isDisableElectiveSelect: (programId, targetOrder) => {
        if (props.isAB3ParentView) return true; // Parent's view
        if (getUserElective(programId, targetOrder, 'like', 'electiveId')) return false;
        const targetLength = targetOrder-1; // e.g. Elective 3 needs Elective 1 & 2 selected first
        const res = userElectives.value.filter(ue => {
          return ue.order && ue.order < targetOrder && ue.programId == programId && ue.reaction == 'like';
        });
        return targetOrder == 4 ? res.length == 0 : res.length < targetLength; // 4 is Math option
      },
      getElectiveNums: (programId) => {
        const res = userElectives.value.filter(ue => (ue.order && ue.programId == programId && ue.reaction == 'like'));
        const nums: any = [];
        for (let i = 0; i < Math.min(4, res.length+1); i++) nums.push(i+1);
        return nums; // [1,2,3,4]
      },

      openAB3QRCodeShareModal: async () => {
        const loading = await loadingController.create({ });
        await loading.present();
        const encryptedUserId = await ABSService.getEncryptedUserId(user.value.id);
        const parentLink = `https://ab.fdmt.hk/ab3-pv/${encryptedUserId}`;
        const imgLink = getQRCodeUrl(parentLink); // attached QR code for students sharing with their parents
        await openImageModal(imgLink, "Share with your parents/custodian", "", true, "small-modal");
        loading.dismiss();
      },

      // Get target scores
      selectedPlan, selectedView,
      nextConsultation, previousConsultations,
      
      // Consultation handler
      formatDate,
      handleConsultationChange: () => {
        nextConsultation.id = nextConsultation.id || `c${uniqueId()}`;
        AchieveJUPASService.upsertJUPASConsultationRecord({
          targetUserId: props.targetUser.id,
          id: nextConsultation.id,
          date: nextConsultation.date,
          time: nextConsultation.time,
          venue: nextConsultation.venue,
          remarks: nextConsultation.remarks,
        });
      },
      sendRemarksToTargetStudent: () => {
        presentPrompt("Send the above remarks to your student's WhatsApp group?", async () => {
          const loading = await loadingController.create({});
          await loading.present();
          const { id, phone, waGroupId } = props.targetUser;
          const msg = `@852${phone} Your teacher *${user.value.fullName}* sent you a message about your AchieveJUPAS choices: _${nextConsultation.remarks}_`;
          SLPService.sendWhatsAppMsg(msg, phone, waGroupId);
          AchieveJUPASService.insertUserComment(id, nextConsultation.remarks);
          loading.dismiss();
          presentToast("The message will be sent to your student's WhatsApp group in minutes.");
        });
      },
      teacherComments,

      // Target score + weighting
      getProgramScoreIconColor,
      openParentConsentModal: async () => (await openModal(ParentConsentSignatureModal, {})),

      // Completion Status (Fill A1-A3 / all 20 choices)
      isFilledA1ToA3: computed(() => (selectedPrograms.value.length >= 3)),
      isFilled20Choices: computed(() => (selectedPrograms.value.length >= 20)),
    }
  }
});
