
// Vue reactivity
import { defineComponent } from 'vue';

// Swiper
import 'swiper/swiper.min.css';
import '@ionic/vue/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { Zoom } from 'swiper';

// icons
import { close, arrowBack } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon,
         modalController } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';
        
export default defineComponent({
  name: 'ImageModal',
  props: ["imageLink", "caption", "onClickImgLink", "noZoomInOut"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon,
                Swiper, SwiperSlide, },
  setup(props) {
    const { openBrowser, } = utils();
    const closeModal = async () => { await modalController.dismiss() };
    
    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close, arrowBack,
      
      // variables
      modules: [Zoom],
      
      // methods
      closeModal,
      onClickImg: () => {
        if (props.onClickImgLink) {
          openBrowser(props.onClickImgLink);
        }
      }
    }
  }
});
