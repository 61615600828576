import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ef78933e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!$props.withText && $props.useWhiteLogo == true)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        width: "45",
        src: $setup.logoWhite
      }, null, 8, _hoisted_1))
    : (($props.useWhiteLogo || $setup.currDark == true) && $props.withText)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "logo-text-img",
          src: $setup.logoWithTextWhite
        }, null, 8, _hoisted_2))
      : ($setup.currDark != true && $props.withText)
        ? (_openBlock(), _createElementBlock("img", {
            key: 2,
            class: "logo-text-img",
            src: $setup.logoWithText
          }, null, 8, _hoisted_3))
        : ($setup.currDark == true && !$props.withText)
          ? (_openBlock(), _createElementBlock("img", {
              key: 3,
              class: "logo-img",
              src: $setup.logoWhite
            }, null, 8, _hoisted_4))
          : ($setup.currDark != true && !$props.withText)
            ? (_openBlock(), _createElementBlock("img", {
                key: 4,
                class: "logo-img",
                src: $setup.logo
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true)
}