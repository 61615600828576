
// vue
import { computed } from 'vue';

// icon
import { checkmarkCircle, alertCircle, createOutline, pencil,
        handRightOutline, peopleOutline, personOutline, calendarClearOutline, calendarOutline, } from 'ionicons/icons';

// components
import { IonItem, IonIcon, IonLabel, IonNote, IonSpinner,
        IonAccordion, IonAccordionGroup, } from '@ionic/vue';

export default {
  props: [
    "item",
  ],
  components: {
    IonItem, IonIcon, IonLabel, IonNote, IonSpinner,
    IonAccordion, IonAccordionGroup,
  },
  setup(props) {
    return {
      // icons
      checkmarkCircle, alertCircle, createOutline, pencil,
      handRightOutline, peopleOutline, personOutline, calendarClearOutline, calendarOutline,
    }
  }
}
