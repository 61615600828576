import { createApp } from 'vue'
import App from '@/App.vue'
import { routes } from '@/router';
import { createRouter, createWebHistory } from '@ionic/vue-router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
/*import '@ionic/vue/css/float-elements.css';*/
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import '@/theme/variables.css';
import '@/theme/global.css';
import '@/theme/custom.css';

/* Firebase */
import firebase from "firebase/compat/app";
import 'firebase/compat/functions';
import 'firebase/auth';
import 'firebase/analytics';

/* vue-i18n */
import { createI18n } from 'vue-i18n';
import translationMessages from '@/i18n';
import config from '@/config';

/* Vuex */
import { store, key } from '@/store'

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { SplashScreen } from '@capacitor/splash-screen';

import UserService from '@/services/UserService';
import CommonService from '@/services/CommonService';
import AuthService from '@/services/AuthService';
import TeacherService from './services/TeacherService';

/* Supabase (mainly for Realtime) */
import { createClient } from '@supabase/supabase-js'

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

document.documentElement.style.setProperty("--ion-color-primary", config.primaryColor);

const i18n = createI18n({
  locale: 'zh', // set locale
  messages: translationMessages
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const app = createApp(App)
  .use(store, key)
  .use(i18n)
  .use(IonicVue, { mode: 'md', innerHTMLTemplatesEnabled: true })
  .use(router)

const supabase = createClient(
  "https://istuyswzjtuletbqejcr.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlzdHV5c3d6anR1bGV0YnFlamNyIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzQ5MjA0MDUsImV4cCI6MTk5MDQ5NjQwNX0.uC_eYyozFYI0LVjZ6YZQHj0U3K92I48pGV054f4P33E"
);
app.provide('supabase', supabase)

// Global Component (for nested import)
import EventCard from "@/components/shared/EventCard.vue";
import LogoImg from '@/components/shared/LogoImg.vue';
import FDMTRiceCardSlide from '@/components/shared/FDMTRiceCardSlide.vue'; // for AB3
import HomeActionHeader from '@/components/shared/HomeActionHeader.vue';
import HomeActionItem from '@/components/shared/HomeActionItem.vue';
import PageHeader from "@/components/shared/PageHeader.vue";
import SectionHeader from '@/components/shared/SectionHeader.vue';
import ServiceDetails from '@/components/shared/ServiceDetails.vue';

app.component('EventCard', EventCard);
app.component('LogoImg', LogoImg);
app.component('FDMTRiceCardSlide', FDMTRiceCardSlide);
app.component('HomeActionHeader', HomeActionHeader);
app.component('HomeActionItem', HomeActionItem);
app.component('PageHeader', PageHeader);
app.component('SectionHeader', SectionHeader);
app.component('ServiceDetails', ServiceDetails);

/* Firebase */
import { auth } from '@/auth';
auth.languageCode = 'zh-HK'; // set SMS language

// One-off access token
let checkTokenExpiryInterval: any = null;
const sessionTokenField = "sessionAccessToken";

router.isReady().then(() => {
  const { path, query } = router.currentRoute.value;
  if (!(path == '/login' && query.token)) {
    const sessionAccessToken = localStorage.getItem(sessionTokenField);
    if (sessionAccessToken) {
      AuthService.checkOnetimeAccessToken(sessionAccessToken).then(res => {
        if (res) {
          store.dispatch('handleOnetimeAccessTokenInfo', res);
          router.replace('/home');
        } else {
          localStorage.setItem(sessionTokenField, "");
        }
      })
    }
  }

  /* Retrieve data from AppSheet and save it in Vuex store as state variables */
  CommonService.getAppPublicData().then(res => {
    store.commit('receiveAppPublicData', res);

    // Commit User Session Responses (loaded too fast)
    const { sessionResponses, formResponses } = store.state.user;
    if (sessionResponses) store.commit('receiveUserEventResponses', sessionResponses);
    //if (formResponses) store.commit('receiveUserFormResponses', formResponses);

    SplashScreen.hide();
  });

  auth.onAuthStateChanged((user: any) => {
    if (user) { // logged in
      localStorage.removeItem(sessionTokenField); // prevent stuck in one-time access link page

      if (window["plugins"]) {
        window["plugins"].OneSignal.setExternalUserId(user.uid); // Map OneSignal player ID to Firebase User ID
      }
      const { query, path: currPath } = router.currentRoute.value;
      const splitPath = currPath.split('/').filter(x => !!x);
      const basePath = `/${splitPath[0]}`;
      const redirectPath: any = query.redirectPath;
      if (redirectPath) {
        router.replace(redirectPath); // redirect to specific page after login
      }
      else if (['/regapply', '/regevent'].includes(basePath) && splitPath.length >= 2) {
        router.replace(`/events/${splitPath.pop()}`);
      }
      else if (basePath == '/r' && splitPath.length >= 3) {
        router.replace(`/events/${splitPath.pop()}`);
      }
      else if (config.authPages.includes(basePath)) {
        router.replace('/home'); // go to home page if the user in auth pages
      }
      store.commit('setLoadingUser', true);
      UserService.getLoggedInUser().then(res => {
        // update language & theme settings on login APP
        if (res) {
          if (res.accessDisabled == true) {
            alert("You are not allowed to access this platform.")
            AuthService.doLogout();
            router.replace('/login');
          } else {
            if (basePath == '/ab3-pv' && res.roles == 'secondary-school-student') {
              router.replace(`/ab3`); // redirect back to AB3 page if already logged in (students)
            }

            // Commit User Object
            store.commit('receiveUser', res);

            // Set Dark Theme & Locale
            document.body.classList.toggle('dark', res.darkTheme == true);
            i18n.global.locale = res.preferredLang.toLowerCase();

            // Teacher User
            let fetchedSchoolUsers = false;
            if (res.teacher && res.teacher.userId) {
              store.dispatch('getSchoolUsers', res.schoolId);
              fetchedSchoolUsers = true;
            }

            // Get portal data after login
            store.dispatch('getPortalData', res.programId);

            // Check last selected role
            if (store.state.user.isAdmin == true && !['/survey', '/services'].some(p => path.includes(p))) {
              const sessionAppRole = localStorage.getItem(config.sessionAppRoleField);
              const baseParams = { appRole: sessionAppRole, teacher: null, isUniversityStudent: false, isSecondaryStudent: false, isUniversityClient: false };
              if (sessionAppRole) {
                switch (sessionAppRole) {
                  case 'teacher':
                    store.commit('updateUser', { ...baseParams, teacher: res.teacher }); // Teacher view
                    if (!fetchedSchoolUsers) store.dispatch('getSchoolUsers', res.schoolId);
                    break;
                  case 'university-student':
                    store.commit('updateUser', { ...baseParams, isUniversityStudent: true }); // jobEX student view
                    store.dispatch('getProgramUsers', res.programId);
                    break;
                  case 'university-client':
                    //store.commit('updateUser', { ...baseParams, isUniversityClient: true }); // university staff view
                    store.commit('updateUser', { ...baseParams, isUniversityClient: true, teacher: res.teacher || { userId: res.id } }); // university staff view
                    store.dispatch('getProgramUsers', res.programId);
                    break;
                  case 'secondary-school-student':
                    store.commit('updateUser', { ...baseParams, isSecondaryStudent: true }); // secondary student view
                    break;
                }
              } else {
                if (['/apply', '/confirm', '/withdraw'].some(p => path.includes(p))) {
                  store.commit('updateUser', { ...baseParams, isSecondaryStudent: true }); // default: secondary student view (for event applications)
                } else {
                  store.commit('updateUser', { ...baseParams, teacher: res.teacher || {} }); // Teacher view
                  if (!fetchedSchoolUsers) store.dispatch('getSchoolUsers', res.schoolId);
                }
              }
            }
          }
        } else {
          store.commit('setLoadingUser', false);
        }
      });
    } else { // logged out
      if (!store.state.onetimeAccessInfo) {
        if (window["plugins"]) {
          window["plugins"].OneSignal.setExternalUserId("");
        }
        store.dispatch('resetUserData');
        i18n.global.locale = navigator.language.split('-')[0].toLowerCase();
      }
    }
  });
  app.mount('#app');
});

router.beforeEach((to, from) => {
  if (auth.currentUser == null && !store.state.onetimeAccessInfo) { // guests (not logged in)
    if (config.publicPages.some(p => to.path.includes(p))) {
      if (!to.path.includes('/booking')) {
        CommonService.getPortalData().then(portalData => {
          store.commit('receivePortalData', portalData);
        });
      }
    }
    else if (!config.authPages.includes(to.path.split('/').slice(0, 2).join('/'))) { // force go to login page
      //return { path: '/register' };
      const redirectPath = to.path;
      if (!to.params.userId) { // allow prefilled login e.g. for update-roles
        return { path: '/login', query: { redirectPath } };
      }
      //return { path: '/login' };
    }
  } else { // logged in users
    if (store.state.onetimeAccessInfo) {
      if (checkTokenExpiryInterval == null) {
        checkTokenExpiryInterval = setInterval(() => {
          const onetimeAccessInfo: any = store.state.onetimeAccessInfo;
          if (!onetimeAccessInfo) clearInterval(onetimeAccessInfo);
          const {loggedInAt, maxAccessMinutes } = onetimeAccessInfo;
          const expiryTime = new Date(new Date(loggedInAt).getTime() + maxAccessMinutes * 60000);
          if (new Date() > expiryTime) {
            localStorage.setItem(sessionTokenField, "");
            AuthService.setOnetimeAccessTokenExpired(onetimeAccessInfo.token);
            store.commit('receiveOnetimeAccessInfo', null);
            router.replace('/login');
            clearInterval(onetimeAccessInfo);
          }
        }, 5 * 1000)
      }
    }
  }
})