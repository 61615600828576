
import { defineComponent, ref, reactive, computed, watch } from 'vue'

// icons
import { checkmark, close, ellipseOutline, triangleOutline, } from 'ionicons/icons';

// components
import {
  IonCol,
  IonButton,
  IonBadge,
  IonCard,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonRow, IonText,
  IonPopover, IonContent, IonItem, IonLabel,
} from '@ionic/vue';
import ProgramItemContent from '@/components/achievejupas/ProgramItemContent.vue';
import SubjectScoreInputSection from '@/components/achievejupas/SubjectScoreInputSection.vue';

// composables
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

// types
import { Service } from '@/types';

export default defineComponent({
  name: 'ProgramInfoStats',
  components: {
    IonCol,
    IonButton,
    IonBadge,
    IonCard,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonRow, IonText,
    IonPopover, IonContent, IonItem, IonLabel,
    ProgramItemContent, SubjectScoreInputSection,
  },
  props: {
    program: {
      type: Object,
      required: true
    },
    showNames: {
      type: Boolean,
      default: true
    },
    showWebsiteButtons: {
      type: Boolean,
      default: true
    },
    transparent: {
      type: Boolean,
      default: false
    },
    showTargetScores: {
      type: Boolean,
      default: false
    },
    showProgramAdmissionData: {
      type: Boolean,
      default: true
    },
    targetUser: {
      type: Object,
      default: null,
    }
  },
  setup(props) {
    const { openImageModal, openServiceModal, } = utils();

    // Store
    const store = useStore();
    const user = computed(() => props.targetUser || store.state.user);

    // TODO: Work Events (implanted promotion)
    const workServices = computed<Service[]>(() => store.getters.getServicesByTypes(["Work"]));

    // Popover
    const popoverState = reactive({
      seats: false,
    });
    const popoverEvent = ref();
    const setPopoverOpen = (popoverKey: any, state: boolean, ev: any) => {
      popoverEvent.value = ev; 
      popoverState[popoverKey] = state;
    };

    const getCompetitivenessColor = (ratio: any) => {
      if (ratio <= 6.5) {  // Bottom 35-40% - Less competitive
        return 'success';
      } else if (ratio <= 20) {  // Middle 45-60% - Moderately competitive
        return 'warning';
      } else {  // Top 15-20% - Highly competitive
        return 'danger';
      }
    };

    return {
      // icons
      checkmark, close, ellipseOutline, triangleOutline,

      // variables
      user,

      // methods
      getCompetitivenessColor,
      openImageModal,

      // popover
      popoverState, popoverEvent,
      setPopoverOpen,
    }
  }
})
