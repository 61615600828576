
import { computed, ref, watch } from 'vue';

// icons
import { home, people, calendar, newspaper, compass, personCircle, construct, notifications, rocket,
        listOutline, telescopeOutline, eyeOutline, settingsOutline, readerOutline, pencil, } from 'ionicons/icons';

// components
import { IonPage, IonTabBar, IonTabButton, IonTabs, IonRouterOutlet, IonLabel, IonIcon, IonBadge } from '@ionic/vue';

// composables
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';

import config from '@/config';

export default {
  name: 'Tabs',
  components: { IonPage, IonTabBar, IonTabButton, IonTabs, IonRouterOutlet, IonLabel, IonIcon, IonBadge, },
  setup() {
    const store = useStore();
    const user = computed(() => store.state.user);
    const route = useRoute();
    const { t } = useI18n();

    return {
      // icons
      home, people, calendar, newspaper, compass, personCircle, construct, notifications, rocket,
      listOutline, telescopeOutline, eyeOutline, settingsOutline, readerOutline, pencil,

      // variables
      user,

      // methods
      t,
    }
  }
}
