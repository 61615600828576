
// vue
import { computed } from 'vue';

// icon
import { checkmarkCircle, alertCircle, createOutline, pencil, trashOutline,
        handRightOutline, peopleOutline, personOutline, calendarClearOutline, calendarOutline,
        globeOutline, star, ellipse, close, thumbsUpOutline, } from 'ionicons/icons';

// components
import { IonItem, IonIcon, IonLabel, IonNote, IonSpinner, IonBadge, IonButton, IonFab, IonFabButton, } from '@ionic/vue';
import { utils } from '@/composables/utils';

export default {
  props: [
    "item", "idx", "fromDeckView", "allowDelete", "hideDisciplineBadges",
    "showRecommendedEvents", "hideNames", "targetScoreIconColor", "schoolPredictScoreIconColor",
  ],
  emits: [
    "clickedPenBtn",
    "clickedTrashBtn"
  ],
  components: {
    IonItem, IonIcon, IonLabel, IonNote, IonSpinner, IonBadge, IonButton,
  },
  setup(props) {
    const { getBandLabel, getDisciplineGroupColor, openBrowser, openImageModal, } = utils();
    
    return {
      // icons
      checkmarkCircle, alertCircle, createOutline, pencil, trashOutline, close,
      handRightOutline, peopleOutline, personOutline, calendarClearOutline, calendarOutline,
      globeOutline, star, ellipse, thumbsUpOutline,

      // methods
      getBandLabel, getDisciplineGroupColor, openBrowser,
      openImageModal,
    }
  }
}
