/**
 * Mainly for the tab 'FDMT Staff'
 */
import firebase from "firebase/compat/app";
import 'firebase/compat/functions';
import { convertKeysToCamelCase, unpackJSONHData } from '@/services/utils';

export default {
  async queryUsers(schoolIds: any = null, roles = 'secondary-school-student') {
    const packedData = (await firebase.app().functions('asia-east2').httpsCallable('internal-queryUsers')({ schoolIds, roles })).data;
    return unpackJSONHData(packedData, false);
  },

  async sendWhatsAppMsgToUsers(users: any[], msg: string) {
    return (await firebase.app().functions('asia-east2').httpsCallable('internal-sendWhatsAppMsgToUsers')({ users, msg })).data;
  },
}