import firebase from "firebase/compat/app";
import 'firebase/compat/functions';

export default {
  // Parents
  async getEncryptedUserId(userId) {
    return (await firebase.app().functions('asia-east2').httpsCallable('abs-getEncryptedUserId')({ userId })).data;
  },
  async getStudentByUserId(userId) {
    return (await firebase.app().functions('asia-east2').httpsCallable('abs-getStudentByUserId')({ userId })).data;
  },

  // Students
  async insertStep1ChangeRecord(type, optionId, questionId, prevText, newText = null) {
    const records = [{ optionId, questionId, type, newText, prevText }];
    return (await firebase.app().functions('asia-east2').httpsCallable('abs-insertStep1ChangeRecords')({ records })).data;
  },

  // User Professions (reactions, reason, order)
  async upsertUserProfessions(userProfessions: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('abs-upsertUserProfessions')({ userProfessions })).data;
  },

  // User Segments
  async upsertUserSegments(userSegments: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('abs-upsertUserSegments')({ userSegments })).data;
  },

  // User Disciplines (reactions, reason, order)
  async upsertUserDisciplines(userDisciplines: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('abs-upsertUserDisciplines')({ userDisciplines })).data;
  },

  // User Programs (reactions, reason, order)
  async upsertUserPrograms(userPrograms: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('abs-upsertUserPrograms')({ userPrograms })).data;
  },

  // User Electives
  async upsertUserElectives(userElectives: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('abs-upsertUserElectives')({ userElectives })).data;
  },

  // Profession Survey Responses
  async upsertProfessionResponses(responses: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('abs-upsertProfessionResponses')({ responses })).data;
  },

  // Step 1 / 2 / 3
  async saveUserChoices(payload) {
    return (await firebase.app().functions('asia-east2').httpsCallable('abs-saveUserChoices')(payload)).data;
  },

  // Analytics
  async insertProfessionSearchKeywordRecord(keyword) {
    return (await firebase.app().functions('asia-east2').httpsCallable('abs-insertProfessionSearchKeywordRecord')({ keyword })).data;
  },
  async insertPageAccessRecord(pageKey, accessTime, leaveTime, uid: any = null) {
    return (await firebase.app().functions('asia-east2').httpsCallable('abs-insertPageAccessRecord')({ pageKey, accessTime, leaveTime, uid })).data;
  }
}