import firebase from "firebase/compat/app";
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import Compressor from 'compressorjs'; // for compressing image size before upload
import { getPlatforms } from '@ionic/vue';

export default {
  async createNewUser(newUser: any) {
    newUser.devicePlatforms = getPlatforms().join(", ");
    const res = await firebase.app().functions('asia-east2').httpsCallable('user-createNewUser')(newUser);
    return res.data[0]; // return the new user
  },

  async updateUser(obj: any = {}, oldUser: any = {}) {
    const res = await firebase.app().functions('asia-east2').httpsCallable('user-updateUser')({ ...obj, oldUser });
    return res.data[0]; // return the new user
  },

  async getNewUserWAGroup(userRowNumber) {
    const res = await firebase.app().functions('asia-east2').httpsCallable('user-getNewUserWAGroup')({ userRowNumber });
    return res.data;
  },

  // User Browsed History
  async addUserBrowsedProfession(professionId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('user-addUserBrowsedProfession')({ professionId });
  },
  async addUserBrowsedSector(sectorId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('user-addUserBrowsedSector')({ sectorId });
  },
  async addUserBrowsedSegment(segmentId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('user-addUserBrowsedSegment')({ segmentId });
  },

  // User Liked Professions
  async addUserLikedProfession(professionId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('user-addUserLikedProfession')({ professionId });
  },
  async removeUserLikedProfession(professionId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('user-removeUserLikedProfession')({ professionId });
  },

  // User Liked Sectors
  async addUserLikedSector(sectorId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('user-addUserLikedSector')({ sectorId });
  },
  async removeUserLikedSector(sectorId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('user-removeUserLikedSector')({ sectorId });
  },

  // User Liked Segments
  async addUserLikedSegment(segmentId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('user-addUserLikedSegment')({ segmentId });
  },
  async removeUserLikedSegment(segmentId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('user-removeUserLikedSegment')({ segmentId });
  },

  async getUserById(userId: any) {
    const res = await firebase.app().functions('asia-east2').httpsCallable('user-getUserById')({ userId });
    return res.data[0];
  },
  async getUserByPhone(phone: any) {
    const res = await firebase.app().functions('asia-east2').httpsCallable('user-getUserByPhone')({ phone });
    return res.data; 
  },

  async getLoggedInUser() {
    const res = await firebase.app().functions('asia-east2').httpsCallable('user-getLoggedInUser')({});
    return res.data[0];
  },

  async updateLoggedInUserProfilePic(dataUrl: any, fileName: string, originalImageLink: any = null) {
    const response = await fetch(dataUrl);
    const blob = await response.blob();
    const imageFile: any = await new Promise((resolve, reject) => {
      new Compressor(blob, {
        maxWidth: 1000,
        quality: 0.6,
        success: resolve,
        error: reject,
      });
    });
    const snapshot = await firebase.app().storage().ref(`user-${fileName}`).put(imageFile);
    const imageLink = await snapshot.ref.getDownloadURL();
    if (originalImageLink) {
      try {
        // prevent problem of invalid image URL
        firebase.app().storage().refFromURL(originalImageLink).delete(); // delete the old image from Firebase storage
      } catch (e) {
        console.log(e);
      }
    }
    const res = await firebase.app().functions('asia-east2').httpsCallable('user-updateLoggedInUserProfilePic')({ imageLink });
    return res.data[0]; // return the updated user
  },

  async updateUserDarkThemeSetting(enabledDarkTheme: boolean) {
    return (await firebase.app().functions('asia-east2').httpsCallable('user-updateUserDarkThemeSetting')({ enabledDarkTheme })).data[0];
  },

  async updateUserAppLanguage(locale: string) {
    return (await firebase.app().functions('asia-east2').httpsCallable('user-updateUserAppLanguage')({ locale })).data[0];
  },
}