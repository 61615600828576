
// Vue reactivity
import { ref, defineComponent, computed, onMounted, watch, onBeforeUnmount, reactive, } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, checkbox, trashOutline,
         thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline, star,
         logoWhatsapp, chevronBack, chevronForward, repeat, search, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder, IonProgressBar,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption, IonSpinner,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonRippleEffect,
        IonNote, IonTextarea, IonFab, IonFabButton, IonBadge, IonInfiniteScroll, IonInfiniteScrollContent, IonModal, } from '@ionic/vue';
import ProfessionModal from '@/components/pss/ProfessionModal.vue';
import AchieveJUPASChartsModal from '@/components/achievejupas/AchieveJUPASChartsModal.vue';
import AchieveJUPASResultPageModal from '@/components/achievejupas/AchieveJUPASResultPageModal.vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';
import { Profession, Program, School, UserUser } from '@/types';
import EventService from '@/services/EventService';

export default defineComponent({
  name: 'UserDetailsModal',
  props: ["user", "sessionId", "isStudentView"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder, IonProgressBar,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption, IonSpinner,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonRippleEffect,
                IonNote, IonTextarea, IonBadge, IonFab, IonFabButton, IonInfiniteScroll, IonInfiniteScrollContent, IonModal, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { closeModal, doReorder, openModal, getProxyImgLink, isMobileWeb,
            openImageModal, getAppSheetFileLink, formatStudentNumber,
            getBandLabel, getBandClass, } = utils();
    const { t } = useI18n();

    const currUser = computed(() => store.state.user);
    const settings = computed(() => store.state.settings);
    const allSchools = computed<School[]>(() => store.state.schools);
    const allPrograms = ref<Program[]>(store.state.allPrograms);
    const allProfessions = ref<Profession[]>(store.state.allProfessions);
    const oldUserUser = ref<UserUser>();

    // Comments & reaction to target user
    const userUser = reactive({
      sessionId: props.sessionId,
      targetUserId: props.user.id,
      reaction: "",
      reason: "",
      grade: "",
      createdAt: new Date(),
    })

    onMounted(() => {
      const { user, sessionId } = props;
      const relatedUserUser = (currUser.value.userUsers || []).find(uu => uu.targetUserId == user.id && uu.sessionId == sessionId);
      if (relatedUserUser) {
        oldUserUser.value = relatedUserUser;
        userUser.reaction = relatedUserUser.reaction || "";
        userUser.reason = relatedUserUser.reason || "";
        userUser.grade = relatedUserUser.grade || "";
        userUser.createdAt = relatedUserUser.createdAt;
      }
    })

    // Sync to DB if filled comments for users before leaving modal
    onBeforeUnmount(() => {
      const { reaction, reason, grade, } = userUser;
      const { reaction: oldReaction, reason: oldReason , grade: oldGrade,} = oldUserUser.value || {};

      if ((reaction || reason || grade) && (reaction != oldReaction || reason != oldReason || grade != oldGrade)) {
        const { id: userId, userUsers } = currUser.value;
        const newUserUser = { ...userUser, userId, };
        EventService.upsertUserUsers([newUserUser]); // update DB

        // Update store (for later retrieval)
        const { user, sessionId } = props;
        const relatedUserUserIdx = (userUsers || []).findIndex(uu => uu.targetUserId == user.id && uu.sessionId == sessionId);
        if (relatedUserUserIdx !== -1) userUsers?.splice(relatedUserUserIdx, 1, newUserUser);
        else userUsers?.push(newUserUser);
        store.commit('updateUser', { userUsers });
      }
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, checkbox, trashOutline,
      thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline, star,
      logoWhatsapp,

      // variables
      settings, userUser,
      currUser,

      // methods
      t, isMobileWeb, closeModal, doReorder,
      getProxyImgLink, openImageModal, getAppSheetFileLink,
      formatStudentNumber, getBandLabel, getBandClass, 
      
      getSchoolObj: (targetKey) => {
        const { schoolId } = props.user;
        const schoolObj = allSchools.value.find(sch => sch.id == schoolId);
        if (!schoolId || !schoolObj) return null;
        return targetKey ? schoolObj[targetKey] : schoolObj;
      },

      getProgramObj: (programId, targetKey) => {
        const p = allPrograms.value.find(p => p.id == programId) || {};
        return targetKey ? p[targetKey] : p;
      },
      getProfessionName: (professionId) => {
        const p = allProfessions.value.find(p => p.id == professionId);
        return `${p?.name} ${p?.nameChinese}`;
      },
      openProfessionModal: async (professionId) => (await openModal(ProfessionModal, { professionId })),

      // User comments (for professors)
      updateUserReaction: (reaction) => {
        userUser.reaction = reaction;
        EventService.upsertUserUsers([{ ...userUser, userId: currUser.value.id }]); // update DB
      },
      updateUserGrade: (grade) => {
        userUser.grade = grade;
        EventService.upsertUserUsers([{ ...userUser, userId: currUser.value.id }]); // update DB
      },

      // Mock JUPAS statistics
      openAchieveJUPASChartsModal: async (userPrograms) => (await openModal(AchieveJUPASChartsModal, { userPrograms })),
      openAchieveJUPASResultPageModal: async (user) => (await openModal(AchieveJUPASResultPageModal, { targetUser: user })),
    }
  }
});
