import firebase from "firebase/compat/app";
import 'firebase/compat/functions';
import { getPlatforms } from '@ionic/vue';

export default {
  // GPT request log
  async insertGPTRequestLog(prompt, botUrl, overrideBotName, tagId, tagText, userElaboration, responseStatusText, userId) {
    const devicePlatforms = getPlatforms().join(", ");
    const payload = { prompt, botUrl, overrideBotName, tagId, tagText, userElaboration, devicePlatforms, responseStatusText, userId };
    return (await firebase.app().functions('asia-east2').httpsCallable('slp-insertGPTRequestLog')(payload)).data;
  },

  // User Claims
  async upsertUserClaims(userClaims: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('slp-upsertUserClaims')({ userClaims })).data;
  },

  // Insert SLP records
  async upsertUserSLP(original: any, gpt: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('slp-upsertUserSLP')({ original, gpt, })).data;
  },

  // Enable SLP access
  async enableUserSLPAccess(existSLPRecord = false) {
    return (await firebase.app().functions('asia-east2').httpsCallable('slp-enableUserSLPAccess')({ existSLPRecord })).data;
  },

  // Send SLP WhatsApp Records
  async sendSLPWhatsAppRecord(selectedSLPType: any, original: any, gpt: any, userPhone: any, userWAGrpId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('slp-sendSLPWhatsAppRecord')({ selectedSLPType, original, gpt, userPhone, userWAGrpId })).data;
  },

  // Send SLP WhatsApp Records
  async sendWhatsAppMsg(msg: any, userPhone: any, userWAGrpId: any, imgLink: any = "") {
    return (await firebase.app().functions('asia-east2').httpsCallable('slp-sendSLPWhatsAppRecord')({ msg, userPhone, userWAGrpId, imgLink })).data;
  },

  // Call ChatGPT (translation & proofread)
  async chatGPTPolish(text: any) {
    //return (await firebase.app().functions('asia-east2').httpsCallable('slp-chatGPTPolish')({ text })).data;
    const result = await firebase.app().functions('asia-east2').httpsCallable('slp-chatGPTPolish')({ text });
    console.log(result);

    // create a new ReadableStream from the response
    const stream = result.data.body;
    const reader = stream.getReader();

    // read the stream and accumulate the data
    let data = '';
    return reader.read().then(function process(result) {
      if (result.done) {
        // the stream has ended, return the accumulated data
        return data;
      } else {
        // add the current chunk of data to the accumulated data
        data += new TextDecoder().decode(result.value);
        // continue reading the stream
        return reader.read().then(process);
      }
    });
  },
}