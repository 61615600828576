
// Vue reactivity
import { reactive, defineComponent, computed, ref } from 'vue';

// icons
import { mail, close, add, arrowBack, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonSpinner, IonItem, IonLabel, IonChip, IonIcon,
        IonButtons, IonButton, IonInput, IonTextarea, IonGrid, IonCol, IonRow, IonSelect, IonSelectOption, IonCard,
        modalController, loadingController, toastController } from '@ionic/vue';

// Utils
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';

// types
import { Nominee } from '@/types';

export default defineComponent({
  name: 'NomineeFormModal',
  props: [],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
                IonSpinner, IonItem, IonLabel, IonChip, IonIcon,
                IonButtons, IonButton, IonInput, IonTextarea, IonCard,
                IonGrid, IonCol, IonRow, IonSelect, IonSelectOption, },
  setup(props) {
    // methods or filters
    const { t } = useI18n();
    const { formatDate, presentToast, presentAlert, presentPrompt, uniqueId } = utils();

    // 1. declare state variables (ref to make them reactive)
    const nominees = ref<Nominee[]>([{
      id: uniqueId(),
      fullName: "",
      class: "",
      phone: "",
    }]);

    // 2. return variables & methods to be used in template HTML
    return {
      // icons
      mail, close, add,

      // variables
      nominees,

      // methods
      t, formatDate,
      incrementNominees: () => {
        const lastInputClass = nominees.value.slice().reverse().find(n => !!n.class)?.class;
        nominees.value.push({
          id: uniqueId(),
          fullName: "",
          class: lastInputClass || "",
          phone: "",
        })
      },
      removeNominee: (idx) => {
        nominees.value.splice(idx, 1);
      },
      closeModal: async (nominees: any = null) => {
        if (nominees) {
          //const nomineeDescriptions = nominees.map((n, i) => `${i+1}. ${n.class} ${n.fullName} (${n.phone})`);
          //presentPrompt(`Confirm save?<br /><br />${nomineeDescriptions.join("<br />")}`, async () => {
          await modalController.dismiss({ nominees });
          //});
        } else {
          await presentPrompt(t("confirmLeave"), async () => {
            await modalController.dismiss({});
          });
        }
      },
    }
  }
});
