import firebase from "firebase/compat/app";
import 'firebase/compat/functions';

export default {
  // Comments to students (for professor's view)
  async upsertUserUsers(userUsers: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('event-upsertUserUsers')({ userUsers })).data;
  },
  // Session participants (for professor's view)
  async getSessionParticipants(sessionId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('event-getSessionParticipants')({ sessionId })).data;
  },

  // Sessinos
  async getSessionsWithQuota() {
    return (await firebase.app().functions('asia-east2').httpsCallable('event-getSessionsWithQuota')({})).data;
  },
  async updateSession(session: any) {
    console.log(session)
    return (await firebase.app().functions('asia-east2').httpsCallable('event-updateSession')({session})).data;
  },

  // Form Responses
  async getUserFormResponses() {
    return (await firebase.app().functions('asia-east2').httpsCallable('event-getUserFormResponses')({})).data;
  },
  async upsertFormResponses(sessionId: any, taskId: any, responses: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('event-upsertFormResponses')({ sessionId, taskId, responses })).data;
  },

  // Work Events
  async bulkApplyEvents(targetEvents: any, phone: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('event-bulkApplyEvents')({ targetEvents, phone })).data;
  },
  async updateEventResponse(targetEvent: any, response: any, phone: any, schoolId: any = null, jobEXIntakeId: any = null, rollCallCode = '', userId: any = null, withdrawReason = null, workshopTimePreference = null) {
    return (await firebase.app().functions('asia-east2').httpsCallable('event-updateEventResponse')({ targetEvent, response, phone, rollCallCode, userId, withdrawReason, schoolId, jobEXIntakeId, workshopTimePreference, })).data;
  },
}