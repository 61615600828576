// For AchieveJUPAS

import firebase from "firebase/compat/app";
import 'firebase/compat/functions';

export default {
  // MOU / Parent's consents
  async insertUserConsent(payload: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('achievejupas-insertUserConsent')(payload)).data;
  },

  // Consultation records
  async upsertJUPASConsultationRecord(record: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('achievejupas-upsertJUPASConsultationRecord')(record)).data;
  },
  async getJUPASConsultationRecords(targetUserId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('achievejupas-getJUPASConsultationRecords')({ targetUserId })).data;
  },

  // Teacher's comments
  async insertUserComment(userId: any, comment: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('achievejupas-insertUserComment')({ userId, comment })).data;
  },
  async getUserComments(type: any = 'achievejupas') {
    return (await firebase.app().functions('asia-east2').httpsCallable('achievejupas-getUserComments')({ type })).data;
  },

  // User Subject Grades (target / predicted)
  async upsertUserSubjectGrades(userSubjectGrades: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('achievejupas-upsertUserSubjectGrades')({ userSubjectGrades })).data;
  },

  // Teacher-defined tags (for grouping students)
  async upsertTeacherDefinedTag(tagId: any, tagName: any, userIds: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('achievejupas-upsertTeacherDefinedTag')({ tagId, tagName, userIds })).data;
  },
  async deleteTeacherDefinedTag(tagId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('achievejupas-deleteTeacherDefinedTag')({ tagId })).data;
  },

  // Add student to teacher-defined tags
  async addStudentToTeacherDefinedTag(tagId: any, tagName: any, userId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('achievejupas-addStudentToTeacherDefinedTag')({ tagId, tagName, userId })).data;
  },

  // Remove student from teacher-defined tag
  async removeStudentFromTeacherDefinedTag(tagId: any, userId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('achievejupas-removeStudentFromTeacherDefinedTag')({ tagId, userId })).data;
  },
}