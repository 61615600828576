import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, withKeys as _withKeys, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eac6c4d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "spin"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { slot: "content" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ion_reorder = _resolveComponent("ion-reorder")!
  const _component_ion_reorder_group = _resolveComponent("ion-reorder-group")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  slot: "icon-only",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.arrowBack }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _withDirectives(_createVNode(_component_ion_title, {
              class: "ion-no-padding",
              style: {"height":"52px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h2", null, [
                      _createTextVNode("Most voted: "),
                      _createElementVNode("b", null, _toDisplayString(_ctx.sortedStudentsWithVotes(1).map(s => s.fullName).join(" , ") || 'N/A'), 1),
                      _createTextVNode(),
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.thumbsUpOutline,
                        style: {"vertical-align":"text-bottom"}
                      }, null, 8, ["icon"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.selectedSection == 'leaderboard']
            ]),
            _withDirectives(_createVNode(_component_ion_searchbar, {
              mode: "ios",
              id: "keyword-searchbar",
              style: _normalizeStyle([{"padding":"0"}, { 'visibility': ['all', 'groups'].includes(_ctx.selectedSection) ? 'visible' : 'hidden' }]),
              modelValue: _ctx.searchKeyword,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchKeyword) = $event)),
              placeholder: _ctx.t('search'),
              onIonFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isSearching = true)),
              onIonCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isSearching = false)),
              onKeyup: _cache[4] || (_cache[4] = _withKeys((e) => e.target.blur(), ["enter"]))
            }, null, 8, ["modelValue", "placeholder", "style"]), [
              [_vShow, _ctx.selectedSection != 'leaderboard']
            ])
          ]),
          _: 1
        }),
        (!_ctx.isShowUniStudents)
          ? (_openBlock(), _createBlock(_component_ion_toolbar, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_segment, {
                  mode: "ios",
                  modelValue: _ctx.selectedSection,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedSection) = $event)),
                  onIonChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.searchKeyword = '')),
                  scrollable: ""
                }, {
                  default: _withCtx(() => [
                    (!_ctx.isStudentView)
                      ? (_openBlock(), _createBlock(_component_ion_segment_button, {
                          key: 0,
                          value: "all"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Participants ")
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (['work-workshop-2'].includes(_ctx.session.anchorEventId))
                      ? (_openBlock(), _createBlock(_component_ion_segment_button, {
                          key: 1,
                          value: "groups"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.isStudentView ? 'Participants' : 'By groups'), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ion_segment_button, { value: "shortlisted" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.isStudentView ? 'Voted' : 'Shortlisted') + " (" + _toDisplayString(_ctx.shortlistedStudents().length) + ") ", 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_segment_button, { value: "leaderboard" }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Best presenters ")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _withDirectives(_createVNode(_component_ion_item, { lines: "none" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", null, [
                          _createElementVNode("i", null, [
                            _createTextVNode("Click on students' names to vote for them. You may also vote for yourself. (Your current vote" + _toDisplayString(_ctx.getMyCurrentVotes() > 1 ? 's' : '') + ": ", 1),
                            _createElementVNode("b", null, _toDisplayString(_ctx.getMyCurrentVotes()), 1),
                            _createTextVNode(")")
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 512), [
                  [_vShow, _ctx.isStudentView && _ctx.selectedSection == 'groups']
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { fullscreen: true }, {
      default: _withCtx(() => [
        (_ctx.loadingData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_ion_spinner)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _withDirectives(_createElementVNode("div", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedStudentsWithVotes(), (user, idx) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    lines: "full",
                    key: idx,
                    button: "",
                    onClick: ($event: any) => (_ctx.openUserDetailsModal(user))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                        default: _withCtx(() => [
                          _createElementVNode("h3", null, [
                            _createElementVNode("b", null, _toDisplayString(user.rank) + ". " + _toDisplayString(user.fullName) + _toDisplayString(user.preferredName ? ` (${user.preferredName})` : ''), 1),
                            _createTextVNode(),
                            _createElementVNode("small", null, _toDisplayString(user.schoolId?.toUpperCase()), 1)
                          ])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_chip, { class: "small-chip" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(user.numOfVotes) + " vote" + _toDisplayString(user.numOfVotes > 1 ? 's' : ''), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_icon, {
                            color: "warning",
                            icon: _ctx.star
                          }, null, 8, ["icon"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                }), 128))
              ], 512), [
                [_vShow, _ctx.selectedSection == 'leaderboard']
              ]),
              _withDirectives(_createElementVNode("div", null, [
                _createVNode(_component_ion_accordion_group, {
                  multiple: true,
                  value: (_ctx.searchKeyword || _ctx.isStudentView) ? Object.keys(_ctx.filteredStudents()) : _ctx.isShowUniStudents ? ['Participants'] : []
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredStudents(), (users, group) => {
                      return (_openBlock(), _createBlock(_component_ion_accordion, {
                        style: {"--min-height":"24px"},
                        key: group,
                        value: group
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            color: "primary",
                            lines: "full",
                            slot: "header"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", null, [
                                    _createElementVNode("b", null, [
                                      _createTextVNode(_toDisplayString(group), 1),
                                      (_ctx.selectedSection == 'all' && _ctx.getSchoolName(group))
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " (" + _toDisplayString(_ctx.getSchoolName(group)) + ")", 1))
                                        : _createCommentVNode("", true)
                                    ])
                                  ])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_badge, { color: "fdmtred" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(users.length), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024),
                          _createElementVNode("div", _hoisted_4, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(users, (user) => {
                              return (_openBlock(), _createBlock(_component_ion_item, {
                                lines: "full",
                                key: user.phone,
                                button: "",
                                detail: "",
                                onClick: ($event: any) => (_ctx.openUserDetailsModal(user))
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.selectedSection == 'all')
                                    ? (_openBlock(), _createBlock(_component_ion_label, {
                                        key: 0,
                                        class: "ion-text-wrap"
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("h3", null, _toDisplayString(user.class) + _toDisplayString(_ctx.formatStudentNumber(user.studentNumber)) + " " + _toDisplayString(user.fullName) + _toDisplayString(user.preferredName ? ` (${user.preferredName})` : ''), 1),
                                          (_ctx.getUserUserObj(user.id, 'reason'))
                                            ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                                                _createElementVNode("small", null, "Remarks: " + _toDisplayString(_ctx.getUserUserObj(user.id, 'reason')), 1)
                                              ]))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    : (_openBlock(), _createBlock(_component_ion_label, {
                                        key: 1,
                                        class: "ion-text-wrap"
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("h3", null, [
                                            _createElementVNode("b", null, _toDisplayString(user.fullName) + _toDisplayString(user.preferredName ? ` (${user.preferredName})` : ''), 1),
                                            _createTextVNode(),
                                            _createElementVNode("small", null, _toDisplayString(user.schoolId?.toUpperCase()), 1)
                                          ]),
                                          (_ctx.getUserUserObj(user.id, 'reason'))
                                            ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                                                _createElementVNode("small", null, "Remarks: " + _toDisplayString(_ctx.getUserUserObj(user.id, 'reason')), 1)
                                              ]))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1024)),
                                  (_ctx.getUserUserObj(user.id, 'grade'))
                                    ? (_openBlock(), _createBlock(_component_ion_badge, {
                                        key: 2,
                                        color: "fdmtred"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.getUserUserObj(user.id, 'grade')), 1)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    : _createCommentVNode("", true),
                                  _createTextVNode("  "),
                                  (_ctx.getUserUserObj(user.id, 'reaction') == 'like')
                                    ? (_openBlock(), _createBlock(_component_ion_badge, { key: 3 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.isStudentView ? 'Voted' : 'Shortlisted'), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            }), 128))
                          ])
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ], 512), [
                [_vShow, ['all', 'groups'].includes(_ctx.selectedSection)]
              ]),
              _withDirectives(_createElementVNode("div", null, [
                _createVNode(_component_ion_reorder_group, {
                  onIonItemReorder: _cache[7] || (_cache[7] = ($event: any) => (_ctx.reorderShortlistedStudents($event, _ctx.shortlistedStudents()))),
                  disabled: false
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shortlistedStudents(), (user, idx) => {
                      return (_openBlock(), _createBlock(_component_ion_item, {
                        lines: "full",
                        key: idx,
                        button: "",
                        detail: "",
                        onClick: ($event: any) => (_ctx.openUserDetailsModal(user))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_reorder, {
                            mode: "ios",
                            slot: "start",
                            style: {"margin-inline-end":"8px"}
                          }),
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createElementVNode("h3", null, [
                                _createElementVNode("b", null, _toDisplayString(idx+1) + ". " + _toDisplayString(user.fullName) + _toDisplayString(user.preferredName ? ` (${user.preferredName})` : ''), 1),
                                _createTextVNode(),
                                _createElementVNode("small", null, _toDisplayString(user.schoolId?.toUpperCase()), 1)
                              ]),
                              (_ctx.getUserUserObj(user.id, 'reason'))
                                ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                                    _createElementVNode("small", null, "Remarks: " + _toDisplayString(_ctx.getUserUserObj(user.id, 'reason')), 1)
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1024),
                          (_ctx.getUserUserObj(user.id, 'grade'))
                            ? (_openBlock(), _createBlock(_component_ion_badge, {
                                key: 0,
                                color: "fdmtred"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.getUserUserObj(user.id, 'grade')), 1)
                                ]),
                                _: 2
                              }, 1024))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ], 512), [
                [_vShow, _ctx.selectedSection == 'shortlisted']
              ])
            ]))
      ]),
      _: 1
    })
  ], 64))
}