import { InjectionKey } from 'vue';
import { createStore, createLogger, useStore as baseUseStore, Store } from 'vuex';
//import { createPinia } from 'pinia'

import state from './state';
import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

import { Discipline, DisciplineGroup, Institution, Profession, ProfessionTab, Program, Sector, Segment,
        Step1Question, School, User, Service, Session, ABSIntake,
        AnchorEventTask, FormQuestion, FormResponse, TeacherResponseOption, TeacherResponseQuestion,
        DisciplineClaim, Lesson, Card, Client, SchoolRole,
        Elective, Subject, } from '@/types';

export interface State {
  user: User;
  teacher: any;
  loadingUser: boolean;
  onetimeAccessInfo: any;
  settings: any;
  schoolRoles: SchoolRole[];

  loadingPortalData: boolean;
  loadingData: boolean;
  loadingSchoolUserData: boolean;
  loadingUserFormResponses: boolean;

  // Work (Seminars & Visits)
  services: Service[];
  sessions: Session[];
  ackAppliedEvent?: Session; // mainly for Band A workshop
  ackInterestedService?: Service; // mainly for HealthcareWork

  // AchieveBot (JUPAS & E-App)
  schools: School[];
  absIntakes: ABSIntake[];
  step1Questions: Step1Question[];
  submittedProfessionResponses: boolean;
  allElectives: Elective[];
  allSubjects: Subject[];
  allCards: Card[];

  // Anchor Events
  lessons: Lesson[];
  anchorEventTasks: AnchorEventTask[];
  formQuestions: FormQuestion[];
  userFormResponses: FormResponse[];

  // Portal Data
  allProfessions: Profession[];
  allSectors: Sector[];
  allDisciplineGroups: DisciplineGroup[];
  allDisciplines: Discipline[];
  allPrograms: Program[];
  draftPrograms: Program[]; // for Mock JUPAS
  allInstitutions: Institution[];
  allSegments: Segment[];
  professionTabs: ProfessionTab[];

  // Teacher Data
  schoolStudents: User[];
  schoolTeachers: User[];
  teacherResponseOptions: TeacherResponseOption[];
  teacherResponseQuestions: TeacherResponseQuestion[];

  // SLP
  disciplineClaims: DisciplineClaim[];

  // University Student
  allJobEXes: any[];
  userCareerPlan: any;
  userCredentials: any[];
  myCredentialsSections: any[];
  userSections: any[];
  loadingCredentials: boolean;
  currProgram: any;
  loadingProgram: boolean;

  // Professor view (for workshops)
  allClients: Client[];

  // FDMT Staff view (mega student list)
  allStudents: User[];
  fetchedAllStudents: boolean;
  allTeachers: User[];
  fetchedAllTeachers: boolean;
}

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state,
  getters,
  actions,
  mutations,
  strict: false,
  plugins: process.env.NODE_ENV !== 'production'
    ? [createLogger()]
    : []
})

// define your own `useStore` composition function
export function useStore () {
  return baseUseStore(key)
}