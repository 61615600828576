
// Vue reactivity
import { computed, ref } from 'vue';

// icons
import { checkmarkCircle, alertCircle, logInOutline, eyeOutline, peopleOutline, pencil, } from 'ionicons/icons';

// components
import { IonCard, IonList, IonItem, IonLabel, IonIcon, IonSpinner, IonChip, IonButton,
        IonAccordionGroup, IonAccordion,
        loadingController, alertController, modalController } from '@ionic/vue';
import ServiceModal from '@/components/modals/ServiceModal.vue';
import StudentModal from '@/components/teacher/SchoolStudentModal.vue';

import ABProfessionSurveyModal from '@/components/pss/profession/ABProfessionSurveyModal.vue'; // ABS only
import FormQuestionModal from '@/components/modals/FormQuestionModal.vue'; // Event Tasks
import SLPModal from '@/components/secondary/ABSLPModal.vue';
import SchoolTeacherModal from '@/components/teacher/SchoolTeacherModal.vue';
import SessionStudentListModal from '@/components/modals/SessionStudentListModal.vue';

// composables
import { useStore } from '@/store';
import { utils } from '@/composables/utils';

// services
import EventService from '@/services/EventService';

// types
import { AnchorEventTask, Session } from '@/types';
import SLPService from '@/services/SLPService';
import PortalService from '@/services/PortalService';
import CommonService from '@/services/CommonService';

export default {
  props: [
    "ev",
    "user",
    "noStrip",
    "hideApplyButton",
    "hideEventDetails",
    "hideEventName",
    "showTeacherActions",
    "showStudentFormsOnly",
    "useAccordionView",
    "disableCardClick"
  ],
  components: { IonCard, IonList, IonItem, IonLabel, IonIcon, IonSpinner, IonChip, IonButton,
                IonAccordionGroup, IonAccordion, },
  setup(props) {
    const { presentAlert, openModal, promptRollCallCode, presentPrompt } = utils();
    
    // store data
    const store = useStore();
    const relatedTeacherResponse = computed(() => store.getters.getTeacherResponseByEventId(props.ev?.id));
    const anchorEventTasks = computed<AnchorEventTask[]>(() => store.state.anchorEventTasks);
    const loadingUserFormResponses = computed(() => store.state.loadingUserFormResponses);
    const userFormResponses = computed(() => store.state.userFormResponses);
    const submittedProfessionResponses = computed(() => store.state.submittedProfessionResponses);
    const user = computed(() => store.state.user);

    // For SLP support / JUPAS choice support
    const loadingPortalData = computed(() => store.state.loadingPortalData); 
    const fetchedJUPASChoiceSupportData = ref(false);

    // ABS related methods
    const getFilteredFormQuestions = (task: AnchorEventTask) => (task.formQuestions.filter(q => (q.workServiceIds.length == 0 || q.workServiceIds.includes(props.ev.serviceId))));
    const isFormQuestionTask = (task: AnchorEventTask) => (task.formQuestions.length > 0);
    const isFeedbackForm = (task: AnchorEventTask) => (isFormQuestionTask(task) && task.title.toLowerCase().includes("feedback"));
    const isProfessionSurveyTask = (task: AnchorEventTask) => (task.id == "abs-profession-survey");
    const getTaskResponses = (tid: string, eid: string) => (userFormResponses.value.filter(r => r.sessionId == eid && r.taskId == tid));
    const isSubmittedTaskForm = (task: AnchorEventTask, ev: Session) => (getTaskResponses(task.id, ev.id).length > 0);

    return {
      // icons
      checkmarkCircle, alertCircle, logInOutline, eyeOutline, peopleOutline, pencil,

      // methods
      openServiceModal: async (serviceId: any, event: any) => {
        if (serviceId || event.posterLink) {
          await openModal(ServiceModal, { serviceId, event, hideTeacherResponse: true });
        }
      },

      isPastEvent: (ev: any) => {
        return ev.userResponse?.attended != 'Yes' && new Date().getTime() > new Date(ev.endTimeStr).getTime()+60*60000;
      },

      openRollCallCodePrompt: async (targetEvent: Session) => {
        if (targetEvent.userResponse?.attended == 'Yes') {
          return; // users already taken roll call
        }
        await promptRollCallCode(async (rollCallCode: any) => {
          const loading = await loadingController.create({ duration: 30000 });
          await loading.present();
          if (rollCallCode.toLowerCase() == (targetEvent.rollCallCode || '').toLowerCase()) {
            if (['work-seminar', 'work-campus-visit', 'work-workshop-2'].includes(targetEvent.anchorEventId)) {
              // Enable SLP Access for Work Seminars & Campus workshop
              const existSLPRecord = store.state.user.slp != null;
              SLPService.enableUserSLPAccess(existSLPRecord);
              store.commit('upsertUserSLP', { accessType: 'full' });
            }
            const { phone, schoolId, currIntakeId } = props.user;
            const responseObj = await EventService.updateEventResponse(targetEvent, "Attended", phone, schoolId, currIntakeId, rollCallCode);
            targetEvent.userResponse = responseObj;
            //if (targetEvent.userResponse) targetEvent.userResponse.attended = 'Yes';
            //else targetEvent.userResponse = { attended: 'Yes' };
            store.commit('injectUserEventResponse', { ...responseObj, lessonId: targetEvent.anchorEventId });
          } else {
            presentAlert("Incorrect roll call code. Please try again.");
          }
          alertController.dismiss();
          loading.dismiss();
        });
      },
      updateEventResponse: async (targetEvent: Session, response: any) => {
        if (props.user.teacher && !["Teacher Event"].includes(targetEvent.group)) {
          presentAlert("This function is for students only");
        } else {
          const action = response == 'Yes' ? 'Apply for' : (response == 'No' ? 'Withdraw your application for' : 'Unsubscribe');
          const msg = `${action} event <b>${targetEvent.name}</b> on <b>${targetEvent.formattedDateTime}</b>?`;
          await presentPrompt(msg, async () => {
            const loading = await loadingController.create({ });
            await loading.present();
            const { phone, schoolId, currIntakeId } = props.user;
            const responseObj = await EventService.updateEventResponse(targetEvent, response, phone, schoolId, currIntakeId);
            store.commit('injectUserEventResponse', { ...responseObj, lessonId: targetEvent.anchorEventId });
            loading.dismiss();
          });
        }
      },
      isSessionStarted: (ev: any) => {
        //return new Date(new Date(ev.startTimeStr).getTime() - 60*60*1000).getTime() < new Date().getTime();
        return new Date(ev.date).getTime() < new Date().getTime();
      },

      // Event Tasks
      anchorEventTasks,
      loadingUserFormResponses, submittedProfessionResponses,
      isProfessionSurveyTask,
      isFeedbackForm, isFormQuestionTask, isSubmittedTaskForm,
      getFilteredFormQuestions,
      checkOpenTaskModal: async (task: AnchorEventTask, ev: Session) => {
        if (isProfessionSurveyTask(task)) return await openModal(ABProfessionSurveyModal, {}); // ABS Only
        if (isFormQuestionTask(task)) {
          const { id: taskId, title, } = task;
          const formTitle = `${ev.anchorEventName} ${title}`; // modal title
          const oldResponses = getTaskResponses(taskId, ev.id);
          const formQuestionIds = getFilteredFormQuestions(task).filter(q => (q.workServiceIds.length == 0 || q.workServiceIds.includes(ev.serviceId))).map(q => q.id);
          return await openModal(FormQuestionModal, { formTitle, formQuestionIds, taskId, sessionId: ev.id, oldResponses });
        }
      },
      checkShowTaskDetailIcon: (task: AnchorEventTask, ev: Session) => {
        if (isProfessionSurveyTask(task)) return !submittedProfessionResponses.value;
        if (isFormQuestionTask(task)) return !isSubmittedTaskForm(task, ev);
        return false;
      },

      // For Teacher ONLY
      relatedTeacherResponse,
      openStudentModal: async (nominatingEvent: Session) => (await openModal(StudentModal, { nominatingEvent })),
      openTeacherModal: async (teacherEvent: Session) => {
          await openModal(SchoolTeacherModal, { teacherEvent });
      },

      // SLP / JUPAS choice support modals
      openSLPModal: async () => {
        const { ev } = props;
        return await openModal(SLPModal, { ev, serviceId: ev?.serviceId, relatedProgramId: ev?.relatedProgramId }, "", false)
      },

      // Voting for best presenters / outstanding participants
      openSessionStudentListModal: async (sessionId) => (await openModal(SessionStudentListModal, { sessionId, isStudentView: true })),
    }
  }
}
