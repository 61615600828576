import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "ion-margin-vertical ion-text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode("Add new nominees")
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  slot: "icon-only",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { fullscreen: true }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_grid, null, {
          default: _withCtx(() => [
            _createElementVNode("form", {
              onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.closeModal(_ctx.nominees)), ["prevent"]))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nominees, (n, i) => {
                return (_openBlock(), _createBlock(_component_ion_card, {
                  style: {"border-left":"8px solid #EF6C00"},
                  key: i
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { lines: "none" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createElementVNode("h1", null, "Nominee " + _toDisplayString(i+1), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_buttons, { slot: "end" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              onClick: ($event: any) => (_ctx.removeNominee(i))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  slot: "icon-only",
                                  icon: _ctx.close
                                }, null, 8, ["icon"])
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_col, { size: "4" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_input, {
                                  label: _ctx.t('class'),
                                  labelPlacement: "floating",
                                  type: "text",
                                  autocapitalize: "characters",
                                  modelValue: n.class,
                                  "onUpdate:modelValue": ($event: any) => ((n.class) = $event),
                                  required: ""
                                }, null, 8, ["label", "modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_col, { size: "8" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_input, {
                                  label: _ctx.t('fullName'),
                                  labelPlacement: "floating",
                                  type: "text",
                                  modelValue: n.fullName,
                                  "onUpdate:modelValue": ($event: any) => ((n.fullName) = $event),
                                  required: ""
                                }, null, 8, ["label", "modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_ion_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_col, { size: "12" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_input, {
                                  label: _ctx.t('UserProfilePage.phone'),
                                  labelPlacement: "floating",
                                  inputmode: "numeric",
                                  modelValue: n.phone,
                                  "onUpdate:modelValue": ($event: any) => ((n.phone) = $event),
                                  maxlength: "8",
                                  required: ""
                                }, null, 8, ["label", "modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_ion_button, {
                  color: "secondary",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.incrementNominees())),
                  shape: "round"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "start",
                      icon: _ctx.add
                    }, null, 8, ["icon"]),
                    _createTextVNode(" Add More ")
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_component_ion_button, {
                class: "ion-margin-vertical",
                type: "submit",
                expand: "block"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('UserProfilePage.save')), 1)
                ]),
                _: 1
              })
            ], 32)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}