export default {
  upsertUserSubjectGrades(state, data) {
    data.forEach(obj => {
      const { subjectId, grade, source } = obj;
      const usg = state.user.userSubjectGrades.find(usg => usg.subjectId == subjectId && usg.source == source);
      if (usg) {
        usg.grade = grade;
      } else {
        state.user.userSubjectGrades.push(obj);
      }
    });
  },
  upsertUserCreatedTags(state, data) {
    data.forEach(uct => {
      const idx = state.user.createdTags.findIndex(t => t.id == uct.id);
      if (idx !== -1) {
        state.user.createdTags.splice(idx, 1, uct);
      } else {
        state.user.createdTags.push(uct);
      }
    });
  },
  deleteUserCreatedTag(state, tagId) {
    const idx = state.user.createdTags.findIndex(t => t.id == tagId);
    if (idx !== -1) {
      state.user.createdTags.splice(idx, 1);
    }
  }
}