import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_profession_details = _resolveComponent("profession-details")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            (_ctx.useBackButton)
              ? (_openBlock(), _createBlock(_component_ion_buttons, {
                  key: 0,
                  slot: "start"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          slot: "icon-only",
                          icon: _ctx.arrowBack
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.profession)
              ? (_openBlock(), _createBlock(_component_ion_title, {
                  key: 1,
                  style: {"text-align":"center","padding":"0"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                      default: _withCtx(() => [
                        _createElementVNode("h2", null, [
                          _createElementVNode("b", null, _toDisplayString(_ctx.profession.name), 1)
                        ]),
                        _createElementVNode("p", null, _toDisplayString(_ctx.profession.nameChinese), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.profession)
              ? (_openBlock(), _createBlock(_component_ion_buttons, {
                  key: 2,
                  slot: "end"
                }, {
                  default: _withCtx(() => [
                    (_ctx.profession.likedByUser)
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 0,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateUserLikedProfession(_ctx.profession, 'remove')))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "icon-only",
                              icon: _ctx.heart,
                              color: "danger"
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (!_ctx.profession.likedByUser)
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 1,
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateUserLikedProfession(_ctx.profession, 'add')))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "icon-only",
                              icon: _ctx.heartOutline,
                              color: "danger"
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (!_ctx.useBackButton)
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 2,
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeModal()))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "icon-only",
                              icon: _ctx.close
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { fullscreen: true }, {
      default: _withCtx(() => [
        (_ctx.profession == null)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_ion_spinner)
            ]))
          : (_openBlock(), _createBlock(_component_profession_details, {
              key: 1,
              profession: _ctx.profession,
              relatedSectors: _ctx.relatedSectors,
              overrideSegments: _ctx.overrideSegments,
              expandAlumniSection: _ctx.expandAlumniSection,
              gptExplanationHTML: _ctx.gptExplanationHTML
            }, null, 8, ["profession", "relatedSectors", "overrideSegments", "expandAlumniSection", "gptExplanationHTML"]))
      ]),
      _: 1
    })
  ], 64))
}