

import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon,
        IonCard, IonCardTitle, IonCardHeader, IonCardContent, IonCardSubtitle,
        IonItem, IonBackButton, IonText, IonLabel, IonInput,
        loadingController, alertController, onIonViewDidEnter, onIonViewWillLeave, modalController, } from '@ionic/vue';

// icons
import { logIn, call, arrowForward, arrowBack, } from 'ionicons/icons';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

// firebase
import firebase from 'firebase/compat/app';
import { customTokenSignin, phoneSignIn, } from '@/auth';

// services
import AuthService from '@/services/AuthService';
import UserService from '@/services/UserService';

// utils
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

export default {
  name: 'LoginPage',
  props: ['isPage', 'prefilledRole'],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
                IonGrid, IonCol, IonRow, IonButtons, IonButton, IonIcon,
                IonCard, IonCardTitle, IonCardHeader, IonCardContent, IonCardSubtitle,
                IonItem, IonBackButton, IonText, IonLabel, IonInput, },
  setup(props) {
    const { presentAlert, presentVerifyCodeInputAlert, sleep } = utils();
    const router = useRouter();
    const route = useRoute();
    const { userId: paramUserId } = route.params;
    const { role: prefilledRole, token: queryToken, userId: queryUserId, phone: queryPhone, redirectPath, } = route.query;

    const store = useStore();
    const settings = computed(() => store.state.settings);

    const phone = ref("");
    const isInvalidPhone = ref(false);

    const recaptchaDiv = ref<any>(null);

    const window: any = {
      recaptchaVerifier: undefined,
      confirmationResult: undefined,
    };
    const resetRecaptchaVerifier = () => {
      if (window.recaptchaVerifier) window.recaptchaVerifier.clear();
      if (recaptchaDiv.value) recaptchaDiv.value.innerHTML = '<div id="grecaptcha-div"></div>';
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('grecaptcha-div', {
        'size': 'invisible',
        'callback': (res: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        }
      });
    }
    onMounted(() => {
      setTimeout(resetRecaptchaVerifier, 200);
    })
    onBeforeUnmount(() => {
      if (window.recaptchaVerifier) window.recaptchaVerifier.clear();
      if (recaptchaDiv.value) recaptchaDiv.value.innerHTML = '<div id="grecaptcha-div"></div>';
    })
    onIonViewDidEnter(() => {
      setTimeout(resetRecaptchaVerifier, 200);
    });
    onIonViewWillLeave(() => {
      if (window.recaptchaVerifier) window.recaptchaVerifier.clear();
      if (recaptchaDiv.value) recaptchaDiv.value.innerHTML = '<div id="grecaptcha-div"></div>';
    })

    // methods
    const { t } = useI18n();
    const verifyCode = async (verificationCode: any) => {
      const loading = await loadingController.create({});
      await loading.present();
      try {
        if (verificationCode == 'skip') { // if users log in with iPad
          alertController.dismiss();
          const existingFirebaseUser = await AuthService.checkPhoneExists(phone.value, null, true);
          await customTokenSignin(existingFirebaseUser.t);
        } else {
          await window.confirmationResult.confirm(verificationCode);
          UserService.updateUser({ isPhoneVerified: true });
        }
        loading.dismiss();
        alertController.dismiss();
        modalController.dismiss();
      } catch (e: any) {
        loading.dismiss();
        if (e.code == "auth/invalid-verification-code") { // wrong verification code
          presentAlert(t('RegisterPage.invalidVerificationCode'))
        } else {
          presentAlert(e.message);
        }
      }
    }
    const doSMSLogin = async (phone: any) => {
      const loading = await loadingController.create({ duration: 30000 });
      await loading.present();
      //const timeoutHandler = setTimeout(resetRecaptchaVerifier, 30000);
      const existingFirebaseUser = await AuthService.checkPhoneExists(phone);
      if (existingFirebaseUser) {
        try {
          // verify the phone
          const appVerifier = window.recaptchaVerifier;
          const verifyingPhone = `+852${phone}`;

          if (settings.value.smsVerification == "FALSE") { // https://docs.google.com/spreadsheets/d/1MRnZtfwR6THzVaRB38Es0RGWhYloPdYJB-n7iC6qQSg/edit?gid=483238421#gid=483238421
            verifyCode('skip'); // For mass registration
          } else {
            window.confirmationResult = await phoneSignIn(verifyingPhone, appVerifier);
            //clearTimeout(timeoutHandler);
            await presentVerifyCodeInputAlert(phone, (verificationCode: any) => {
              verifyCode(verificationCode);
            });
          }
        } catch (e: any) {
          //clearTimeout(timeoutHandler);
          resetRecaptchaVerifier();
          if (e.code == "auth/invalid-phone-number") {
            presentAlert(t('RegisterPage.enterValidHKMobileNumber')); // invalid phone number
          } else {
            presentAlert(e.message);
          }
        }
        loading.dismiss();
      } else {
        //clearTimeout(timeoutHandler);
        loading.dismiss();
        modalController.dismiss();
        let regPath = `/register?phone=${phone}`;
        if (prefilledRole || props.prefilledRole) regPath += `&role=${prefilledRole || props.prefilledRole}`;
        if (redirectPath) regPath += `&redirectPath=${redirectPath}`;
        router.push(regPath);
        //isInvalidPhone.value = true;
      }
    }

    const goToForgotPasswordPage = () => {
      router.push('/forgot-password'); // go to forgot password page
    }
    const goToRegisterPage = () => {
      router.push({ path: '/register' });
    }
    const checkOnetimeAccessToken = async (token: any, redirectPath: any) => {
      const loading = await loadingController.create({ message: "Logging in..." });
      await loading.present();
      try {
        const res = await AuthService.checkOnetimeAccessToken(token);
        if (!res) presentAlert("Invalid Login Link. Please log in by phone.");
        else {
          store.dispatch('handleOnetimeAccessTokenInfo', res);
          router.replace(redirectPath || '/home');
        }
      } catch (e) {
        console.log(e);
      } finally {
        loading.dismiss();
      }
    }
    const checkPrefilledLogin = async (userId: any, phone: any) => {
      const loading = await loadingController.create({ message: "Logging in..." });
      await loading.present();
      try {
        // Check if phone already exists in Firebase Auth
        const existingFirebaseUser = await AuthService.checkPhoneExists(phone, userId, true);

        // Check if user IDs match
        if (existingFirebaseUser && existingFirebaseUser.s == 200 && existingFirebaseUser.t) {
          // log in without verification code
          await customTokenSignin(existingFirebaseUser.t);
        } else {
          presentAlert("Invalid Login Link. Please log in by phone.");
        }
      } catch (e) {
        console.log(e);
      } finally {
        loading.dismiss();
      }
    }

    if (queryToken) {
      checkOnetimeAccessToken(queryToken, redirectPath);
    }
    else if (paramUserId || (queryUserId && queryPhone)) {
      checkPrefilledLogin(paramUserId || queryUserId, queryPhone);
    }
    
    const closeModal = async () => {
      if (props.isPage) {
        router.replace('/home');
      } else {
        await modalController.dismiss({});
      }
    };
    
    return {
      // icons
      logIn, call, arrowForward, arrowBack,
      
      // variables
      phone, isInvalidPhone,
      recaptchaDiv,

      // methods
      t, doSMSLogin, closeModal,
      goToRegisterPage, goToForgotPasswordPage
    }
  },
}
