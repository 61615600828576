
// Vue reactivity
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, watchEffect } from 'vue';

// icons
import { informationCircleOutline, close, heart, heartOutline, arrowBack, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon, IonLabel, IonSpinner,
        loadingController, modalController, } from '@ionic/vue';
import ProfessionDetails from '@/components/pss/ProfessionDetails.vue';
        
// services / composables
import UserService from '@/services/UserService';

import { useStore } from '@/store';
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';

// types
import { Profession } from '@/types';
import ABSService from '@/services/ABSService';

export default defineComponent({
  name: "ProfessionModal",
  props: {
    gptExplanationHTML: null,
    professionId: null,
    useBackButton: null,
    relatedSectors: null,
    overrideSegments: null,
    expandAlumniSection: null,
  },
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonIcon, IonLabel, IonSpinner,
                ProfessionDetails, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { t } = useI18n();
    const { closeModal, openBrowser, sleep, } = utils();

    const profession = ref<Profession>();

    const updateUserLikedProfession = async (profession: Profession, action = 'add') => {
      const loading = await loadingController.create({});
      await loading.present();
      if (action == 'add') UserService.addUserLikedProfession(profession.id);
      else UserService.removeUserLikedProfession(profession.id);
      await sleep(1.5); // 1.5 seconds
      loading.dismiss();
      profession.likedByUser = (action == 'add');
    }

    let accessTime, duration = 0, counterInterval;
    onMounted(() => {
      const { professionId } = props;
      profession.value = store.getters.getProfessionById(professionId);

      setTimeout(() => {
        if (professionId) {
          UserService.addUserBrowsedProfession(professionId); // add to browse history
          store.commit('addUserBrowsedProfession', professionId);
        }
      }, 500);

      // Record Access & Leave Time
      accessTime = new Date();
      counterInterval = setInterval(() => (duration++), 1000);
    });
    onBeforeUnmount(() => {
      if (accessTime && duration >= 3) {
        const { id, name } = profession.value || {};
        ABSService.insertPageAccessRecord(`ProfessionModal | ${name} | ${id}`, accessTime, new Date(accessTime.getTime() + duration*1000));
        accessTime = undefined;
        duration = 0; // reset;
        clearInterval(counterInterval);
      }
    });
    
    // 3. return variables & methods to be used in template HTML
    return {
      // methods
      t, closeModal, openBrowser,
      updateUserLikedProfession,

      // variables
      arrowBack, profession,

      // icons
      heart, heartOutline,
      informationCircleOutline, close,
    }
  }
});
