
import { computed } from 'vue';

// icons
import { logoWhatsapp, gridOutline, menu, star, helpCircleOutline, logoInstagram, logoLinkedin, logoYoutube, callOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonGrid, IonToolbar, IonTitle, IonChip, IonAvatar, IonLabel, IonButtons, IonButton, IonIcon,
          IonBackButton, IonFab, IonFabButton, } from '@ionic/vue';

// composables
import { utils } from '@/composables/utils';

// store
import { useStore } from '@/store';

export default {
  props: ["showBackButton", "hideSchoolLogo", "hideUserProfileToolbar", "isAB3ParentView", "selectedView"],
  components: { IonHeader, IonGrid, IonToolbar, IonTitle, IonChip, IonAvatar, IonLabel, IonButtons, IonButton, IonIcon,
                IonBackButton, IonFab, IonFabButton, },
  setup() {
    const { openModal, formatStudentNumber, getProxyImgLink, getAppSheetFileLink, openImageModal, } = utils();

    const store = useStore();
    const user = computed(() => store.state.user);
    const userRelatedSchool = computed(() => store.getters.userRelatedSchool);
    const userRelatedJobEX = computed(() => store.getters.userRelatedJobEX);
    const userRelatedClient = computed(() => store.getters.userRelatedClient);
    const currDark = computed(() => store.state.user.darkTheme);

    return {
      // icon
      logoWhatsapp, gridOutline, menu, star, helpCircleOutline, logoInstagram, logoLinkedin, logoYoutube, callOutline,
      
      // variables
      user,
      userRelatedSchool,
      userRelatedJobEX,
      userRelatedClient,
      currDark,

      // methods
      openImageModal,
      getAppSheetFileLink,
      getProxyImgLink,
      formatStudentNumber,
      onClickWhatsAppButton: () => {
        store.dispatch('setUserJoinedWAGroup');
      }
    }
  } 
}
