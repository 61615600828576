
// Vue reactivity
import { ref, defineComponent, computed, watch, reactive, onMounted } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, openOutline, trashOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid,
        IonSpinner, IonCheckbox, IonInfiniteScroll, IonInfiniteScrollContent, } from '@ionic/vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

// types
import { Program } from '@/types';
export default defineComponent({
  name: 'ABProgramSelectModal',
  props: ["targetDisciplines", "prefilledPrograms", "specificDiscipline", "readOnly"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonReorderGroup, IonReorder,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid,
                IonSpinner, IonCheckbox, IonInfiniteScroll, IonInfiniteScrollContent, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { closeModal, infiniteScrollLoadData, } = utils();
    const { t } = useI18n();

    const allPrograms = computed(() => store.getters.programsSortedByJUPASCodes);
    const loading = computed(() => store.state.loadingData);
    const masterCurrVisiblePrograms = ref<Program[]>([]);
    const chosenPrograms = ref<Program[]>(props.prefilledPrograms?.slice() || []);
    const selectedGroup = ref('jupas');
    const selectedDisciplineId = ref('all');
    const programSearchKeyword = ref('');
    
    const currVisiblePrograms = computed(() => {
      return masterCurrVisiblePrograms.value.filter((p: Program) => p.displayName.toLowerCase().includes(programSearchKeyword.value.toLowerCase().trim()));
    })

    const numOfVisibleItems = ref(20);
    const loadData = (ev: any) => {
      infiniteScrollLoadData(ev, numOfVisibleItems, masterCurrVisiblePrograms.value);
    }

    const refreshCurrProgramList = () => {
      const targetProgramType = (selectedGroup.value == 'eapp' ? 'ipass' : 'jupas');
      const relatedPrograms = allPrograms.value.filter(p => p.programType == targetProgramType);
      masterCurrVisiblePrograms.value = selectedDisciplineId.value == 'all' ? relatedPrograms.slice(0, 500)
                                        : relatedPrograms.filter((p: Program) => p.disciplines.some(d => d.id == selectedDisciplineId.value)).slice(0, 500);
      numOfVisibleItems.value = 20;
      programSearchKeyword.value = '';
    }
    
    const confirmSelect = async () => {
      await closeModal({ "chosen": chosenPrograms.value });
    }

    watch(selectedDisciplineId, (curr) => {
      refreshCurrProgramList();
    });
    watch(allPrograms, (curr) => {
      refreshCurrProgramList();
    });

    onMounted(() => {
      refreshCurrProgramList();
      const { targetDisciplines, specificDiscipline } = props;
      if (specificDiscipline) selectedDisciplineId.value = specificDiscipline.id;
      if (targetDisciplines && targetDisciplines.length > 0) selectedDisciplineId.value = targetDisciplines[0].id;
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, openOutline, trashOutline,

      // variables
      loading,
      selectedGroup, selectedDisciplineId, programSearchKeyword,
      currVisiblePrograms, masterCurrVisiblePrograms, chosenPrograms,
      numOfVisibleItems,

      // methods
      t, loadData,
      closeModal,
      refreshCurrProgramList, confirmSelect,

      onCheckProgram: (checked: any, program: Program) => {
        if (checked) chosenPrograms.value.unshift(program);
        else {
          const idx = chosenPrograms.value.findIndex(p => p.id == program.id);
          if (idx !== -1) chosenPrograms.value.splice(idx, 1);
        }
      },
    }
  }
});
