
// vue
import { computed } from 'vue';

// icon
import { checkmarkCircle, alertCircle, } from 'ionicons/icons';

// components
import { IonCard, IonCardContent, IonItem, IonIcon, IonLabel, IonNote, } from '@ionic/vue';

export default {
  props: [
    "title",
    "isDisabled",
  ],
  components: {
    IonCard, IonCardContent, IonItem, IonIcon, IonLabel, IonNote,
  },
  setup() {
    return { checkmarkCircle, alertCircle, }
  }
}
