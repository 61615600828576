import { useStore } from "@/store";

// services
import SLPService from "@/services/SLPService";
import ABSService from "@/services/ABSService";

// lib
import markdownit from 'markdown-it';
import { jsonrepair } from 'jsonrepair'; // for repairing JSON from GPT responses

export function utilsGPT() {
  const store = useStore();
  const md = markdownit({ breaks: true });
  const htmlToPlainText = (html) => (html.replace(/<[^>]*>/g, ''));
  const tagObjOther: any = { id: 'other', text: 'Other'};

  const getOptionIds = (groupedQuestions: any) => {
    const optionIds: any = [];
    for (const key in groupedQuestions) {
      const options: any = groupedQuestions[key];
      optionIds.push(...options.map(opt => opt.id));
    }
    return optionIds;
  }

  const getSelectedUserClaims = (userClaims, step1OptionIds: any = []) => {
    const optionIds: any = [...step1OptionIds, 'other'];
    const res = userClaims.value.filter(uc => {
      return (!uc.claimId || optionIds.includes(uc.claimId)) && uc.type == 'strength' && uc.selected == true;
    }).sort((a,b) => (a.order-b.order)).slice(0, 1); // only the first selected tag

    if (res.length > 0) return res; // return previously selected claim
    const newUC = { claimId: "", text: "", elaboration: "", selected: true, order: 999, type: 'strength' };
    userClaims.value.push(newUC);
    return [newUC]; // default claim (for input text without selecting tags)
  };
                                          //.sort((a,b) => (b.suitabilityScore-a.suitabilityScore));

  /**
   * GPT API calls
   */
  const fetchGPTResponse = async (gpt, url, prompt, userId, overrideBotName = "", readOnly = false) => {
    const ACCESS_TOKEN = "617ba408824b9f2c2e9e1b61e2c04761";

    // Set up cooldown (prevent frequent requests)
    gpt.isInCooldownTime = true;
    gpt.cooldownSecLeft = 30;
    const interval = setInterval(() => {
      if (gpt.cooldownSecLeft > 0) gpt.cooldownSecLeft--;
      else clearInterval(interval);
    }, 1000)
    setTimeout(() => { gpt.isInCooldownTime = false }, 30000) // enable back button

    // API call
    gpt.waitingGPTResp = true;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${ACCESS_TOKEN}`,
      },
      body: JSON.stringify({
        "version":"1.0","type":"query","query":[{
          "role":"user","content": prompt,"timestamp": new Date().valueOf()
        }],"user_id": store.state.user.isAdmin ? 'admin' : userId,"conversation_id": `ab-request-${userId}`,"message_id":overrideBotName,
      })
    });

    // Insert request log
    SLPService.insertGPTRequestLog(prompt, url, overrideBotName, gpt.tagId, gpt.tagText, gpt.userInputText, response.statusText, `${userId}${readOnly ? '-readonly' : ''}`);

    // Update user claim (skip for parents)
    if (!readOnly) {
      const newUC = { claimId: gpt.tagId, text: gpt.tagText || "", elaboration: gpt.userInputText, selected: true, order: 1, type: 'strength', updatedAt: new Date().toISOString() };
      SLPService.upsertUserClaims([newUC]);
      store.commit('upsertUserClaims', [newUC]);
    }

    // Return data
    if (!response.ok) throw new Error(response.statusText);
    return response.body; // This data is a ReadableStream
  }

  const parseGPTResponse = async (gpt, data, currViewingItem, botSuggestedItems, allItems, swiperSelector = "#card-swiper-slides", idField = "professionId") => {
    const reader = data.getReader(), decoder = new TextDecoder();
    let done = false, resultJSONStr = "";
    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunkValue = decoder.decode(value);
      let isReplaceResponse = false;
      for (const message of chunkValue.split("\n")) {
        try {
          if (message.includes("event:")) { // event: replace_response
            isReplaceResponse = message.includes("replace_response");
          }
          const jsonStr = message.split("data: ").pop();
          const parsed = JSON.parse((jsonStr || "").trim());
          if (parsed.text) {
            if (!resultJSONStr) {
              setTimeout(() => {
                const slides: any = document.querySelector(swiperSelector);
                if (slides) {
                  // Card slide change event handler (show explanation) - init once
                  slides.swiper.on('slideChange', () => {
                    const itemId = slides.swiper.slides[slides.swiper.activeIndex]?.dataset[idField];
                    currViewingItem.value = undefined;
                    setTimeout(() => {
                      currViewingItem.value = botSuggestedItems.value.find(p => p.id == itemId);
                    }, 50);
                  });
                }
              }, 500)
            }

            // Progressively show the results
            if (isReplaceResponse) resultJSONStr = parsed.text;
            else resultJSONStr += parsed.text; // event: text
            let res = JSON.parse(jsonrepair(resultJSONStr));
            if (!Array.isArray(res)) res = Object.values(res)[0]; // array is inside the object
            botSuggestedItems.value = res.map(obj => ({ ...obj, ...allItems.value.find(p => p.id == obj.id) }));
            if (!currViewingItem.value) currViewingItem.value = botSuggestedItems.value[0];
            else currViewingItem.value = botSuggestedItems.value.find(p => p.id == currViewingItem.value?.id);
            gpt.waitingGPTResp = false;
          }
        } catch (e) {
          continue;
        }
      }
    }
    console.log(resultJSONStr);
    let res = JSON.parse(jsonrepair(resultJSONStr));
    if (!Array.isArray(res)) res = Object.values(res)[0]; // array is inside the object

    botSuggestedItems.value = res.map(obj => ({ ...obj, ...allItems.value.find(p => p.id == obj.id) }));
                                  //.sort((a,b) => (b.suitabilityScore-a.suitabilityScore));
    if (!currViewingItem.value) currViewingItem.value = botSuggestedItems.value[0];

    return res;
  }

  return {
    fetchGPTResponse, parseGPTResponse,

    initGPTObj: (gpt, userClaims) => {
      const sortedUserClaims = (userClaims || []).slice().sort((a: any, b: any) => new Date(a.updatedAt) > new Date(b.updatedAt) ? -1 : 1);
      const latestUC = sortedUserClaims.find(uc => uc.selected && uc.type == 'strength');
      gpt.userInputText = latestUC?.elaboration || "";
      gpt.tagId = latestUC?.claimId || "";
      gpt.tagText = latestUC?.text || ""
    },
    getStrengthDescription: (gpt) => (`${gpt.tagText ? `${gpt.tagText} - ` : ``}${gpt.userInputText || ""}`),
    parseMsg: (msg) => (msg ? md.render(msg) : msg),
    whatsAppSendGPTResults: (userPhone, userGroupId, strengthDescriptions, botSuggestedItems, entityName = 'profession') => {
      // Send out the results to student's WhatsApp group for records
      let msg = `@852${userPhone} Thanks for using our tool. Please find below the ${entityName}(s) related to your strength, for your records and references.`;
      msg += `\n\nYour key strength: ${strengthDescriptions}`;
      msg += '\n\n' + botSuggestedItems.value.map((d, idx) => `*${idx+1}. ${d.name} ${d.nameChi || d.nameChinese || ""}*\n${htmlToPlainText(d.explanation)}`).join("\n\n--\n");
      SLPService.sendWhatsAppMsg(msg, userPhone, userGroupId);
    },
    upsertUserItems: (userId, botSuggestedItems, userItems, keyField = 'disciplineId') => {
      const updatedUserItems = botSuggestedItems.value.map(item => {
        const existingUserItem = userItems.value.find(up => up[keyField] == item.id);
        if (existingUserItem) {
          existingUserItem.botExplanation = item.explanation;
          return existingUserItem;
        }
        const newUserItem: any = { [keyField]: item.id, userId, reaction: null, reason: null, action: null, order: null, appState: {}, createdAt: new Date(), botExplanation: item.explanation, }
        userItems.value.push(newUserItem);
        return newUserItem;
      });
      switch (keyField) {
        case 'disciplineId':
          ABSService.upsertUserDisciplines(updatedUserItems);
          break;
        case 'professionId':
          ABSService.upsertUserProfessions(updatedUserItems);
          store.commit('upsertUserProfessions', updatedUserItems);
      }
      return updatedUserItems;
    },

    tagObjOther, getOptionIds, getSelectedUserClaims,

    toggleSelectedTag: (userClaims, tagId, tagText) => {
      userClaims.value.forEach(uc => uc.selected = false); // single select
      const existingUC = userClaims.value.find(c => c.claimId == tagId);
      if (existingUC) {
        existingUC.type = 'strength';
        existingUC.selected = !existingUC.selected;
        //SLPService.upsertUserClaims([existingUC]); // update DB
      } else {
        const newUC = {
          claimId: tagId,
          text: tagText || "",
          elaboration: "",
          selected: true,
          order: 999,
          type: 'strength',
        };
        userClaims.value.push(newUC);
        //SLPService.upsertUserClaims([newUC]); // update DB
      }
    },

    isCompletedStep0: (userClaims, step1OptionIds = []) => {
      return getSelectedUserClaims(userClaims, step1OptionIds).filter(uc => (uc.claimId || uc.elaboration)).length > 0;
    },
  }
}