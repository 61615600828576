
// Vue reactivity
import { ref, defineComponent, computed, watch, reactive, onMounted } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, trashOutline, sendOutline, helpCircleOutline,
        createOutline, openOutline, chevronForwardOutline, informationCircleOutline, personAddOutline, pencil, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonBadge,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
        IonInfiniteScroll, IonInfiniteScrollContent, IonReorder, IonReorderGroup,
        IonFab, IonFabButton,
        loadingController, modalController, alertController, } from '@ionic/vue';
import NomineeFormModal from '@/components/teacher/NomineeFormModal.vue';
import RegistrationQRCodeModal from '@/components/modals/RegistrationQRCodeModal.vue';
import UserProfileFormModal from '@/components/modals/UserProfileFormModal.vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

// services
import TeacherService from '@/services/TeacherService';

// types
import { Session, User } from '@/types';

export default defineComponent({
  name: 'StudentModal',
  props: ["nominatingEvent", "schoolId", "service", "initialSection", "hideHeader", "maxYearDSE"],
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonBadge,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
                IonInfiniteScroll, IonInfiniteScrollContent, IonReorder, IonReorderGroup,
                IonFab, IonFabButton, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { openModal, presentPrompt, presentToast, infiniteScrollLoadData, formatStudentNumber,
            getQRCodeUrl, sendLinkToMyWhatsApp, getCurrentIntakeYear, formatDate, } = utils();
    const { t } = useI18n();

    const user = computed(() => store.state.user);
    //const maxWorkshopNominees = 5; // Only for interactive Work workshop (anchor event ID: "work-workshop-2") 5 for each session (so total is 10)
    //const maxWorkshopNominees = 30; // Only for interactive Work workshop (anchor event ID: "work-workshop-2") 5 for each session (so total is 10)
    //const maxWorkshopNominees = 4; // 20241015: tenative for BBAWork workshop
    const maxWorkshopNominees = 999; // 20241015: tenative for BBAWork workshop
    const nominees = ref<User[]>([]);
    const schoolStudents = computed<User[]>(() => store.state.schoolStudents);
    const allSessions = computed<Session[]>(() => store.state.sessions);
    const searchKeyword = ref("");
    const selectedFilter = ref("all");
    const section = ref(props.initialSection || 2);

    const numOfVisibleItems = ref(20);
    const loadData = (ev: any) => {
      infiniteScrollLoadData(ev, numOfVisibleItems, schoolStudents.value, 100);
    }

    const closeModal = async () => (await modalController.dismiss({}));

    const getStudentSessionResp = (s: User, targetKey = 'response') => {
      if (props.nominatingEvent) {
        return (s.sessionResponses?.find(r => r.sessionId == props.nominatingEvent.id) || {})[targetKey];
      }
      return null;
    }
    const onCheckNominee = (checked: any, student: any) => {
      const idx = nominees.value.findIndex(n => n.id == student.id);
      if (checked && idx == -1) {
        //student.timePreference = (props.service?.nature == "Workshop" ? "No preference" : "");
        student.timePreference = "";
        nominees.value.push(student);
      }
      else if (!checked && idx != -1) nominees.value.splice(idx, 1);
    }
    const getTotalNumNominees = () => {
      const nominatedStudents = schoolStudents.value.filter(student => (['Yes', 'Confirmed', 'Attended'].includes(getStudentSessionResp(student)) && getStudentSessionResp(student, 'nominatedBy')));
      return nominatedStudents.length + nominees.value.length;
    }
    const isStudentAttendedBefore = (student) => {
    const { nominatingEvent } = props;
    return allSessions.value.find(s => {
      return s.id != nominatingEvent.id && s.anchorEventId == nominatingEvent.anchorEventId && s.clientId == nominatingEvent.clientId
            && student.sessionResponses.find(sr => sr.sessionId == s.id && sr.attended == 'Yes');
    }) != null;
    }

    onMounted(() => {
      if (props.nominatingEvent) section.value = 2;
    })

    // return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack,
      trashOutline, sendOutline, helpCircleOutline, createOutline, openOutline,
      chevronForwardOutline, informationCircleOutline, personAddOutline, pencil,

      // variables
      user,
      numOfVisibleItems,
      schoolStudents, nominees,
      searchKeyword,
      selectedFilter,
      section,

      // methods
      t, getQRCodeUrl, sendLinkToMyWhatsApp,
      loadData, closeModal,
      formatStudentNumber,
      allClasses: (schoolStudents: any) => {
        return [...new Set(schoolStudents.map(s => s.class?.replace(/[^a-z0-9]/gi, "").toLowerCase()))]
                .filter(c => !!c).map((c: any) => c?.toUpperCase()).sort();
      },

      openRegQRCodeModal: async (event) => {
        return await openModal(RegistrationQRCodeModal, { event, targetTo: 'student', serviceId: props.service?.id })
      },
      
      // Verifying Students
      updateUserVerificationResult: (result: any, user: User) => {
        if (user.verificationResult == result) result = null; // toggle
        
        TeacherService.updateUserVerificationResult(result, user.id); // update DB

        user.verificationResult = result; // update store
      },

      // Show list of students based on search keyword & filters
      filteredSchoolStudents: () => {
        let filteredStudents: User[] = schoolStudents.value.filter(s => s.yearDSE >= getCurrentIntakeYear());
        if (props.maxYearDSE) {
          filteredStudents = filteredStudents.filter((s: User) => (s.yearDSE && s.yearDSE <= props.maxYearDSE));
        }
        if (searchKeyword.value) {
          filteredStudents = filteredStudents.filter((s: User) => {
            const searchInWords = `${s.fullName} ${s.chineseName} ${s.preferredName} ${s.phone} ${s.email} ${s.class}${s.studentNumber}`.replace(/[^a-z0-9]/gi, "").toLowerCase();
            const cleanedKeyword = searchKeyword.value.replace(/[^a-z0-9]/gi, "").toLowerCase();
            return searchInWords.includes(cleanedKeyword);
          });
        } else if (selectedFilter.value != 'all') {
          filteredStudents = filteredStudents.filter((s: User) => {
            if (selectedFilter.value == 'unverified') return !s.verificationResult;
            if (selectedFilter.value == 'applied') return ['Attended', 'Yes'].includes(getStudentSessionResp(s));
            if (selectedFilter.value == 'attended') return ['Attended'].includes(getStudentSessionResp(s));
            if (selectedFilter.value == 'nominated') return getStudentSessionResp(s, 'nominatedBy');
            if (selectedFilter.value == 'selected') return nominees.value.find(n => n.id == s.id);
            return s.class == selectedFilter.value;
          });
        }
        const sortedStudents = filteredStudents.slice().sort((a: any, b: any) => {
          const textA = a.class?.toUpperCase(), textB = b.class?.toUpperCase();
          //if (getStudentSessionResp(a, 'nominatedBy') || ['Attended', 'Yes'].includes(getStudentSessionResp(a))) return -1; // show applied students first
          if (textA == "") return 1;
          if (textB == "") return -1;
          return textA < textB ? -1 : (textA > textB ? 1 : (a.studentNumber - b.studentNumber)); // put to back if empty
        });
        const isStudentApplied = (s) => (getStudentSessionResp(s, 'nominatedBy') || ['Attended', 'Yes'].includes(getStudentSessionResp(s)));
        
        if (props.service.status == 'Inactive') {
          return sortedStudents.filter(s => isStudentApplied(s)); // only show applied students
        }
        return [
          ...sortedStudents.filter(s => isStudentApplied(s)), // show applied students first
          ...sortedStudents.filter(s => !isStudentApplied(s)),
          //...sortedStudents.filter(s => s.isNewStudent), // show new students first
          //...sortedStudents.filter(s => !s.isNewStudent)
        ];
      },

      // Nomination
      getStudentSessionResp,
      onCheckNominee,
      deleteNewStudent: (schoolStudents, studentId) => {
        const studentIdx = schoolStudents.findIndex(s => s.id == studentId);
        if (studentIdx != -1) schoolStudents.splice(studentIdx, 1);
        const nomineeIdx = nominees.value.findIndex(n => n.id == studentId);
        if (nomineeIdx != -1) nominees.value.splice(nomineeIdx, 1);
      },
      openNomineeFormModal: async (schoolStudents) => {
        const modal = await modalController.create({
          component: NomineeFormModal,
          componentProps: {}
        });
        modal.onDidDismiss().then(({ data }) => {
          if (data && data.nominees) {
            for (const n of data.nominees) {
              if (n.phone) {
                const existingStud = schoolStudents.find(s => s.phone == n.phone);
                if (existingStud) {
                  // Existing student: already in DB
                  onCheckNominee(true, existingStud);
                } else {
                  // New student: not yet registered
                  n.isNewStudent = true;
                  schoolStudents.push(n);
                  nominees.value.push(n);
                }
              }
            }
          }
        });
        return modal.present();
      },
      submitNomination: (targetResponse: any = 'Yes') => {
        const { id: targetSessionId } = props.nominatingEvent;
        console.log(targetSessionId);
        const nomineeDescriptions = nominees.value.map(n => `${n.class}${formatStudentNumber(n.studentNumber)} ${n.fullName} (${n.phone})`);
        let msgHeader = `Confirm nomination?`;
        if (targetResponse == 'No') msgHeader = `Confirm rejection of student applications?`;
        presentPrompt(`${msgHeader}<br /><br />${nomineeDescriptions.sort().join("<br />")}`, async () => {
          const loading = await loadingController.create({ duration: 60000 });
          await loading.present();

          // Insert nomination record
          const reqNominees = nominees.value.map(({ id, fullName, phone, "class": studentClass, isNewStudent, timePreference, }) => {
            return { id, fullName, phone, "class": studentClass, isNewStudent, schoolId: store.state.user.schoolId, timePreference, }; // minimize the payload
          });
          await TeacherService.insertNominationRecord(reqNominees, [targetSessionId], targetResponse);

          // Update corresponding schoolStudent event response (nominatedBy)
          for (const n of nominees.value) {
            const sessionResp = n.sessionResponses?.find(resp => resp.sessionId == targetSessionId);
            if (sessionResp) {
              // update nominator of existing response
              sessionResp.nominatedBy = store.state.user.id;
              sessionResp.response = targetResponse;
              sessionResp.confirmed = targetResponse;
            } else {
              // insert new session response
              (n.sessionResponses = n.sessionResponses || []).push({
                sessionId: targetSessionId,
                userId: n.id,
                response: targetResponse,
                confirmed: targetResponse,
                nominatedBy: store.state.user.id
              });
            }
          }

          // Upsert school students
          store.commit('upsertSchoolStudents', nominees.value);
          nominees.value = []; // clear handled nominees

          // Present toast msg (ack)
          presentToast(`Thank you for your ${targetResponse == 'Yes' ? 'nomination' : 'response'}.`);

          alertController.dismiss();
          loading.dismiss();
        })
      },

      // Checking for Work workshop (nominee number)
      maxWorkshopNominees, getTotalNumNominees,

      // Edit student info
      openUserProfileFormModal: async (targetUser) => {
        await openModal(UserProfileFormModal, { targetUser });
      },

      // Interview
      getInterviewSessionResp: (student: User, targetKey: any = null) => {
        const { clientId } = props.nominatingEvent; // Interview session is by client
        for (const resp of student.sessionResponses || []) {
          const session: Session = store.getters.getSessionById(resp.sessionId);
          if (resp.response != 'No' && session.anchorEventId == "c98cc077d" && session.clientId == clientId) {
            if (targetKey == 'statusText') {
              if (resp.response == 'Confirmed') return `Confirmed to attend ${formatDate(session.date, 'MMM D')} interview`;
              if (resp.response == 'Attended') return `Attended ${formatDate(session.date, 'MMM D')} interview`;
              return `Invited to ${formatDate(session.date, 'MMM D')} interview`;
            }
            return targetKey ? resp[targetKey] || "" : resp;
          }
        }
        return null;
      },

      // Check whether the student can be Nominated
      isStudentAttendedBefore,
      isDisableNomination: (student) => {
        const { nominatingEvent } = props;
        if (isStudentAttendedBefore(student)) return true; // e.g. BBAWork workshop, not allow duplicate application / nomination
        return new Date() > new Date(nominatingEvent.startTimeStr) || nominees.value.find(n => n.id == student.id) == null && getTotalNumNominees() >= maxWorkshopNominees;
      }
    }
  },
});
