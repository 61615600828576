
import { defineComponent, ref, reactive, computed, watch } from 'vue'

// icons
import { checkmark, close, ellipseOutline, triangleOutline, } from 'ionicons/icons';

// components
import {
  IonCol,
  IonButton,
  IonBadge,
  IonCard,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonRow, IonText,
  IonPopover, IonContent, IonItem, IonLabel,
} from '@ionic/vue';
import ProgramItemContent from '@/components/achievejupas/ProgramItemContent.vue';

// composables
import { utils } from '@/composables/utils';
import { useStore } from '@/store';
import AchieveJUPASService from '@/services/AchieveJUPASService';

export default defineComponent({
  name: 'SubjectScoreInputSection',
  components: {
    IonCol,
    IonButton,
    IonBadge,
    IonCard,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonRow, IonText,
    IonPopover, IonContent, IonItem, IonLabel,
    ProgramItemContent,
  },
  emits: ['targetScoresChanged'],
  props: ["targetUser", "program", "fromDeckView", "title", "passIcon", "hideSubjectNames",
          "readonly", "noBottomBorderRadius", "noTopBorderRadius", "gradeSelectLargeFont",
          "source"],
  setup(props, { emit }) {
    const { openImageModal, openServiceModal, getWeightedScore, getUserSubjects,
            calculateSumWeightedScores, getAvailableScoreType, getScoreDiff, } = utils();

    // Store
    const store = useStore();
    const user = computed(() => props.targetUser || store.state.user);

    // Constants (subjects & grades)
    const allSubjects = computed(() => store.state.allSubjects);
    const grades = ['5**', '5*', '5', '4', '3', '2', '1', 'U'];

    // Get user's studying subjects with short names
    const userSubjects = computed(() => {
      const userObj = props.targetUser || user.value;
      return getUserSubjects(allSubjects.value, userObj);
    });

    // Subject Grade (target / school prediction)
    const getUserSubjectGrades = () => {
      const userObj = props.targetUser || user.value;
      return userObj.userSubjectGrades || [];
    };
    const getSubjectGrade = (subjectId: any) => {
      const res = getUserSubjectGrades().find(usg => usg.subjectId == subjectId && usg.source == props.source);
      return res?.grade;
    };

    // Target scores
    const targetScores = reactive({
      chi: '',
      eng: '',
      math: '',
      csd: 'Attained',
    });
    const currPopoverSubjectId = ref('');
    const setTargetScore = (grade) => {
      const subjectId = currPopoverSubjectId.value;
      const userSubjectGrades = getUserSubjectGrades();
      const updatedObj = userSubjectGrades.find(usg => usg.subjectId == subjectId && usg.source == props.source) ||
                        { subjectId, grade, source: props.source };
      updatedObj.userId = props.targetUser?.id || user.value.id; // may be for specific user
      updatedObj.grade = grade;
      updatedObj.updatedBy = user.value.id;
      AchieveJUPASService.upsertUserSubjectGrades([updatedObj]);

      // Update store props
      const targetUserObj = props.targetUser || user.value;
      const usg = targetUserObj.userSubjectGrades.find(usg => usg.subjectId == subjectId && usg.source == props.source);
      if (usg) usg.grade = grade;
      else {
        if (!Array.isArray(targetUserObj.userSubjectGrades)) targetUserObj.userSubjectGrades = [];
        targetUserObj?.userSubjectGrades.push(updatedObj);
      }
      //store.commit('upsertUserSubjectGrades', [updatedObj]); // Update user's own target scores
      emit('targetScoresChanged', targetScores);
    };

    // Popover
    const popoverState = reactive({
      seats: false,
      grades: false,
    });
    const popoverEvent = ref();
    const setPopoverOpen = (popoverKey: any, state: boolean, ev: any, currSelectedSubjectId?: any) => {
      popoverEvent.value = ev; 
      popoverState[popoverKey] = state;
      currPopoverSubjectId.value = currSelectedSubjectId
    };

    // Display weighted score for any subject (default latest year)
    const calculateBest5Scores = (targetRuleYear: any = null) => {
      const totalScores = calculateSumWeightedScores(allSubjects.value, userSubjects.value, getUserSubjectGrades(), props.program, props.source, targetRuleYear);
      return totalScores.toFixed(1);
    };
    const getColorForScore = () => {
      const scoreDiff = getScoreDiff(props.program, allSubjects.value, userSubjects.value, getUserSubjectGrades(), props.source);
      if (scoreDiff >= 0) return 'darkgreen';
      //else if (scoreDiff >= -2) return 'warning'; // TBC (PTCC VPr. Chan prefers this)
      return 'danger';
    };

    return {
      // icons
      checkmark, close, ellipseOutline, triangleOutline,

      // Subject grade (target / school prediction)
      userSubjects,
      getSubjectGrade,
      getWeightedScore,

      // methods
      openImageModal,

      // DSE data
      grades,
      targetScores, setTargetScore,
      calculateBest5Scores,
      getColorForScore,
      getAvailableScoreType,

      // popover
      popoverState, popoverEvent,
      setPopoverOpen,
    }
  }
})
