import firebase from "firebase/compat/app";
import 'firebase/compat/functions';

export default {
  async getBookingData(staffName) {
    return (await firebase.app().functions('asia-east2').httpsCallable('booking-getBookingData')({ staffName })).data;
  },
  async createNewBooking(booking, bookingItem, bookingSlot) {
    return (await firebase.app().functions('asia-east2').httpsCallable('booking-createNewBooking')({ booking, bookingItem, bookingSlot })).data;
  },
}