import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    color: $props.color || undefined,
    lines: "full",
    button: "",
    detail: !$props.isLoading,
    disabled: $props.isDisabled || $props.isLoading
  }, {
    default: _withCtx(() => [
      (!$props.hideIcon)
        ? (_openBlock(), _createBlock(_component_ion_icon, {
            key: 0,
            slot: "start",
            icon: $setup.getItemIcon(),
            color: $setup.getItemIconColor(),
            class: _normalizeClass({ 'extra-indent': !$props.isLesson && !$props.noIndent })
          }, null, 8, ["icon", "color", "class"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_label, {
        class: _normalizeClass(["ion-text-wrap", { 'extra-indent': $props.hideIcon == true && !$props.noIndent }]),
        color: $props.textColor || undefined
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, [
            _createElementVNode("b", null, _toDisplayString($props.title), 1)
          ])
        ]),
        _: 1
      }, 8, ["class", "color"]),
      ($props.isLoading)
        ? (_openBlock(), _createBlock(_component_ion_spinner, {
            key: 1,
            slot: "end"
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["color", "detail", "disabled"]))
}