import firebase from "firebase/compat/app";
import 'firebase/compat/functions';

export default {
  async getExtraInfosByGroupId(extraInfoGroupId: any, includeAllKeys = false) {
    return (await firebase.app().functions('asia-east2').httpsCallable('getExtraInfosByGroupId')({ extraInfoGroupId, includeAllKeys })).data;
  },
  async queryPortalData(query: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('queryPortalData')({ query })).data;
  },
  async getProgram(programId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('getPortalProgramData')({ programId })).data;
  },
  async getProfession(professionId: any) {
    //const endpoint = `professions/${professionId}`;
    const endpoint = `admin/professions/${professionId}`;
    return (await firebase.app().functions('asia-east2').httpsCallable('callPortalApi')({ endpoint })).data;
  },
  async getProfessions() {
    const endpoint = `admin/professions`;
    return (await firebase.app().functions('asia-east2').httpsCallable('callPortalApi')({ endpoint })).data;
  },
  async getCvCl(professionId: any) {
    const endpoint = `professions/${professionId}/cv-cl`;
    return (await firebase.app().functions('asia-east2').httpsCallable('callPortalApi')({ endpoint })).data;
  },
  async getSegmentExtraInfos(segmentId: any, locationId: any = null) {
    const endpoint = `segments/${segmentId}/extra-infos?location_id=${locationId}`;
    return (await firebase.app().functions('asia-east2').httpsCallable('callPortalApi')({ endpoint })).data;
  },
  async getSegmentEmployers(segmentId: any, locationId = 1) {
    const endpoint = `segments/${segmentId}/employers?location_id=${locationId}`;
    return (await firebase.app().functions('asia-east2').httpsCallable('callPortalApi')({ endpoint })).data;
  },
  async getEmployersBySegmentIds(segmentIds: any = []) {
    const endpoint = `segments/employers?segment_ids=${segmentIds.join(",")}`;
    return (await firebase.app().functions('asia-east2').httpsCallable('callPortalApi')({ endpoint })).data;
  },
  async getSector(sectorId: any) {
    const endpoint = `sectors/${sectorId}`;
    return (await firebase.app().functions('asia-east2').httpsCallable('callPortalApi')({ endpoint })).data; 
  },
}