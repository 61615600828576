
// Vue reactivity
import { ref, defineComponent, computed, watch, reactive } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, trashOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton,
        IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
        IonRadioGroup, IonRadio, IonInfiniteScroll, IonInfiniteScrollContent, IonReorder, IonReorderGroup,
        loadingController, modalController, } from '@ionic/vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

// types
import { User, Session, Discipline } from '@/types';

export default defineComponent({
name: 'RegistrationQRCodeModal',
props: ["event", "targetTo", "serviceId"],
components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonAccordion, IonAccordionGroup,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton,
                IonSearchbar, IonSegment, IonSegmentButton, IonList, IonSelect, IonSelectOption,
                IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonChip, IonText, IonCardTitle, IonGrid, IonCheckbox,
                IonRadioGroup, IonRadio, IonInfiniteScroll, IonInfiniteScrollContent, IonReorder, IonReorderGroup, },
setup(props) {
    // methods or filters
    const store = useStore();
    const { t } = useI18n();

    const user = computed(() => store.state.user);
    const service = computed(() => store.getters.getServiceById(props.serviceId));

    const { getQRCodeUrl, sendLinkToMyWhatsApp, getProxyImgLink, } = utils();

    const closeModal = async () => (await modalController.dismiss({}));


    // return variables & methods to be used in template HTML
    return {
        // icons
        add, close, checkmark, arrowUp, arrowForward, arrowBack,
        trashOutline,

        // variables
        user, service,

        // methods
        t, getProxyImgLink,
        closeModal,
        getQRCodeUrl, 
        sendLinkToMyWhatsApp,
        getUrl: (user: User, event: Session) => {
          const { targetTo } = props;
          if (targetTo == 'teacher'){
            return `ab.fdmt.hk/t/${user.schoolId}${event ? `/${event.id}` : ""}`;
          }
          else if (targetTo == 'student'){
            return `ab.fdmt.hk/s/${user.schoolId}${event ? `/${event.id}` : ""}`;
          }
          // Default register link
          return "ab.fdmt.hk/r";
        },
        getTitle: () => {
          const { targetTo } = props;
          const toText = targetTo == 'teacher' ? "to teacher(s)" : targetTo == 'student' ? "to student(s)" : "";
          return `Forward Registration QR Code/Link ${toText}`;
        }
    }
},
});
