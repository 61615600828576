import firebase from "firebase/compat/app";
import 'firebase/compat/functions';
import 'firebase/compat/auth';
import { auth, emailSignIn, sendPswResetEmail, customTokenSignin, reauthUserWithCredential, updateUserPassword } from '@/auth';

export default {
  async createFirebaseAuthUser(phone: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('user-createFirebaseAuthUser')({ phone })).data;
  },
  async checkPhoneExists(phone: any, userId: any = null, forceReturnToken = false) {
    return (await firebase.app().functions('asia-east2').httpsCallable('user-checkPhoneExists')({ phone, userId, forceReturnToken })).data;
  },
  async checkOnetimeAccessToken(token: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('checkOnetimeAccessToken')({ token })).data;
  },
  async setOnetimeAccessTokenExpired(token: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('setOnetimeAccessTokenExpired')({ token })).data;
  },
  
  async doLogout() {
    return await auth.signOut();
  },

  async resetPassword(email: string) {
    return await sendPswResetEmail(email);
  },

  async updatePassword(oldPassword: any, newPassword: any) {
    try {
      const currentUser: any = auth.currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, oldPassword);
      await reauthUserWithCredential(currentUser, credential);
      return await updateUserPassword(currentUser, newPassword);
    } catch (err) {
      console.error(err);
      return "wrong-password";
    }
  },
}